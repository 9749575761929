import React from "react";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import InputLabel from "@mui/material/InputLabel";
const UserDetails = ({ fields, setFields }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <div>
        <div className=" my-6">
          <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
            <div>
              <TextField
                fullWidth
                label="First Name"
                id="fullWidth"
                name="name"
                value={fields.name}
                onChange={handleChange}
              />
            </div>
            <div>
              <TextField
                fullWidth
                label="Last Name"
                id="fullWidth"
                name="lastName"
                value={fields.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-status">Status</InputLabel>
              <Select
                labelId="status"
                id="status-select"
                label="Status"
                name="status"
                value={fields.status}
                onChange={handleChange}
              >
                <MenuItem value={"active"}>Active</MenuItem>
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"approved"}>Approved</MenuItem>
              </Select>
            </FormControl>
            <div>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="User Type"
                  name="userType"
                  value={fields.userType}
                  onChange={handleChange}
                >
                  <MenuItem value={"Customer"}>Customer</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
            <div>
              <TextField
                fullWidth
                type="email"
                label="Email"
                id="fullWidth"
                name="email"
                value={fields.email}
                onChange={handleChange}
                disabled
              />
            </div>
            <div>
              <TextField
                fullWidth
                type="number"
                label="phone number"
                id="fullWidth"
                name="phone"
                value={fields.phone}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-10">
            <TextField
              fullWidth
              label="954 Walglen Ct, San Jose, California(CA), 95136"
              id="fullWidth"
              name="address"
              value={fields.address}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
