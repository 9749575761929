import React from "react";
import Sidebar from "./Sidebar";
import Navbar from "../../../components/Layout/Navbar";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="flex w-full h-[90.7vh]">
        <div className="min-w-[285px] bg-white border-[1px] border-[#D9D9D9] h-full">
          <Sidebar />
        </div>
        <div className="w-full">
          <div className="max-w-[1100px] mx-auto px-4 py-10">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Layout;
