import React from 'react'
import Hero from "./Hero";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";

const TermsandServicesPage = () => {
  return (
    <div>
<Navbar/>
<Hero/>
<Footer/>
    </div>
  )
}

export default TermsandServicesPage