import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { register, reset } from "./adminAuthSlice";
import { toast } from "react-toastify";
import Logo from "../../assets/logoandslogan.png";
import { TextField, Button } from "@mui/material";

const AdminSingup = () => {
  const [fields, setFields] = useState({
    fullName: "",
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    e.preventDefault();
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.adminAuth
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (user) {
      navigate("/dashboard");
    } else if (isSuccess) {
      navigate("/admin/login");
    }
    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);
  if (isLoading) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  }

  const resgiterHandler = (event) => {
    event.preventDefault();

    const userData = {
      name: fields.fullName,
      email: fields.email,
      password: fields.password,
      role: "admin",
    };

    dispatch(register(userData));
  };
  return (
    <div className="flex items-center justify-center w-full h-screen">
      <div className="flex">
        <div className="flex items-center justify-center w-full px-6 bg-white rounded-lg md:px-10">
          <div className="max-w-[442px] mx-auto">
            <div className="flex justify-center mt-20 mb-20 ">
             <img src={Logo} alt="" className="w-40" />
            </div>

            <div>
              <h1 className="mb-2 text-2xl font-bold text-primary">
                Apply for Fullsetters Admin Account
              </h1>
              <p className="mb-10 text-xl font-normal text-secondary">
                Please enter the following details.
              </p>
              <TextField
                fullWidth
                label="Full Name"
                id="fullWidth"
                name="fullName"
                value={fields.fullName || ""}
                onChange={handleChange}
                sx={{
                  mb: "20px",
                }}
              />
              <TextField
                fullWidth
                label="Email Address"
                id="fullWidth"
                name="email"
                value={fields.email || ""}
                onChange={handleChange}
                sx={{
                  mb: "20px",
                }}
              />

              <TextField
                fullWidth
                label="Password"
                id="fullWidth"
                name="password"
                value={fields.password || ""}
                onChange={handleChange}
                sx={{
                  mb: "20px",
                }}
              />

              <Button
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: "#3867D6",
                  width: "100%",
                  height: "55px",
                }}
                onClick={resgiterHandler}
              >
                SIGN UP
              </Button>
            </div>
            <div className="my-6">
              <p className="text-lg text-center text-primary">
                {`Already have an Account?`}
                <Link to="/admin/login">
                  <span className="text-[#3867D6]"> Log In</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSingup;
