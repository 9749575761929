import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe('pk_test_51IhYZxD1of7ibGo0faR53KAlKMn3MSMlKxRwy3K2CWYKaw8IjWwohww29WefdC6MxDY7S047xKKi3bipOtrkNsgu00gmW97PAk');
  }
  return stripePromise;
};

export default getStripe;
