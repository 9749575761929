import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { withTranslation, useTranslation, Trans } from "react-i18next";



const Faq = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(0);
  const handleCollapse = (id) => {
    if (open === id) {
      setOpen(!id);
    } else {
      setOpen(id);
    }
  };
  const data = [
    {
      title: t("faq-title-1"),
      description: t("faq-desc-1"),
    },
    {
      title: t("faq-title-2"),
      description: t("faq-desc-2"),
    },
    {
      title: t("faq-title-3"),
      description: <Trans i18nKey="faq-desc-3" components={[<br />]} />,
    },
    {
      title: t("faq-title-4"),
      description: t("faq-desc-4"),
    },
    {
      title: t("faq-title-5"),
      description: <Trans i18nKey="faq-desc-5" components={[<br />]} />,
    },
    {
      title: t("faq-title-6"),
      description: <Trans i18nKey="faq-desc-6" components={[<br />]} />,
    },
    {
      title: t("faq-title-7"),
      description: (
        <Trans 
        i18nKey="faq-desc-7" 
        components={[
          <br />, 
          <ul className="list-disc list-item list-outside pl-5" />, 
          <li className="pl-4"/>
        ]}
      />
      ),
    },
  ];
  return (
    <div>
      <div className="w-full pt-12 pb-16">
        <div className="main-container">
          <div>
            <h2 className="text-primary text-center text-[40px] font-bold mb-10">
              {t("faqs")}?
            </h2>

            <div className="mb-8">
              {data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="faq-border border-[0.5px] shadow-sm border-borderc mb-[10px] px-4 md:px-6"
                  >
                    <div
                      className={`py-5  w-full   cursor-pointer flex justify-between  `}
                      onClick={() => handleCollapse(index + 1)}
                    >
                      <h3
                        className={`${
                          open === index + 1
                            ? "text-[#3867D6] transition-all duration-700 ease-in-out "
                            : "text-primary transition-all duration-700 ease-in-out"
                        } text-base md:text-lg font-bold `}
                      >
                        {item.title}
                      </h3>

                      <div
                        className={`${
                          open === index + 1 ? "text-[#3867D6]" : "text-primary"
                        } text-2xl md:mr-4 flex self-center`}
                      >
                        {open === index + 1 ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )}
                      </div>
                    </div>
                    <div
                      className={` text-lg font-normal text-secondary ${
                        open === index + 1 ? "open" : "collapse"
                      } `}
                    >
                      {item.description}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Faq);
