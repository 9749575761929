import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import cross from "../../../assets/cross.svg";
import Layout from "./Layout";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const API_URL = "https://fullsetters.com/api/admin/";

const IdVerification = () => {
  const [data, setData] = useState([]);
  const [emailStatus, setEmailStatus] = useState({});

  useEffect(() => {
    axios
      .get("https://fullsetters.com/api/users/getusers")
      .then((res) => {
        const userData = res.data;

        setData(userData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const storedStatus = localStorage.getItem("emailStatus");
    if (storedStatus) {
      setEmailStatus(JSON.parse(storedStatus));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("emailStatus", JSON.stringify(emailStatus));
  }, [emailStatus]);

  const deleteHandler = async (index, userId) => {
    try {
      let deletedRecord = data.filter((_, i) => i !== index);
      const user = JSON.parse(localStorage.getItem("admin"));

      const headers = {
        Authorization: `Bearer ${user?.token}`,
      };

      await axios.delete(API_URL + userId, { headers });
      setData(deletedRecord);
      toast.success("User deleted successfully.");
    } catch (error) {
      console.error("Error while deleting user:", error);
    }
  };

  return (
    <Layout>
      <ToastContainer />
      <div className="w-full">
        <div className="max-w-6xl mx-auto px-4 my-12">
          <div className="bg-white rounded-t-lg">
            <h1 className="text-xl font-bold text-primary py-4 px-5 border-b border-b-[#D9D9D9]">
              Verification Requests
            </h1>
          </div>

          <div className=" py-8 bg-white rounded-b-lg">
            <TableContainer>
              <Table
                className=""
                sx={{ minWidth: 750 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Request Time</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          marginBottom: "8px",
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item.name}
                        </TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item?.status}</TableCell>
                        <TableCell>{item.requestTime}</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <div className="flex gap-4">
                            <div
                              className="flex justify-center gap-2 md:gap-2 py-3 px-3 min-w-[100px] items-center border border-black rounded  md:max-w-[110px] cursor-pointer mx-auto "
                              onClick={() => deleteHandler(index, item._id)}
                            >
                              <img src={cross} alt="" />
                              <span>Delete</span>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IdVerification;
