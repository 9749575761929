import React from "react";
import Layout from "../../Layout";
import PaymentHistoryWrapper from "../PaymentHistoryWrapper";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const RefundedHistory = () => {
  return (
    <Layout>
      <h2 className="text-2xl font-bold text-[#142D3C] font-Roboto py-8 px-2">
        Payment History
      </h2>
      <PaymentHistoryWrapper>
        <div className="overflow-x-auto relative">
          <table className="min-w-full table-auto">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left">Refund ID</th>
                <th className="px-6 py-3 text-left">Invoice ID</th>
                <th className="px-6 py-3 text-left">Service</th>
                <th className="px-6 py-3 text-left">Refunded at</th>
                <th className="px-6 py-3 text-left">Refund Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-6 py-3">R_123456</td>
                <td className="px-6 py-3">INV-7890</td>
                <td className="px-6 py-3">
                  Business Web Hosting (billed every 2 years)
                </td>
                <td className="px-6 py-3">2023-08-15</td>
                <td className="px-6 py-3">$25.50</td>
                <td className="px-6 py-3">
                  <Link to="/billing/payment-history/refunded/H_2717815">
                    <span className="transition-all text-blue-500 hover:text-white hover:bg-blue-300 block w-8 h-8 p-1.5">
                      <AiOutlineRight />
                    </span>
                  </Link>
                </td>
              </tr>
              <tr>
                <td className="px-6 py-3">R_987654</td>
                <td className="px-6 py-3">INV-5678</td>
                <td className="px-6 py-3">E-commerce Platform Subscription</td>
                <td className="px-6 py-3">2023-09-02</td>
                <td className="px-6 py-3">$50.00</td>
                <td className="px-6 py-3">
                  <Link to="/billing/payment-history/refunded/H_2717815">
                    <span className="transition-all text-blue-500 hover:text-white hover:bg-blue-300 block w-8 h-8 p-1.5">
                      <AiOutlineRight />
                    </span>
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </PaymentHistoryWrapper>
    </Layout>
  );
};

export default RefundedHistory;
