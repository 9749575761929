import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./components/member-auth/authSlice";
import adminAuthReducer from "./pages/Admin/adminAuthSlice";
import MainReducer from "./components/MainSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    adminAuth: adminAuthReducer,
    main: MainReducer,
  },
});
