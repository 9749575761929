import { createSlice, createSelector } from "@reduxjs/toolkit";

export const MainSlice = createSlice({
  name: "main",
  initialState: {
    selectedChatUser: null,
    notifications: [],
  },
  reducers: {
    setSelectedChatUser: (state, action) => {
      state.selectedChatUser = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

export const { setSelectedChatUser, setNotifications } = MainSlice.actions;

const selectMain = (state) => state.main;

// Memoized selectors

export const selectChatUser = createSelector(
  [selectMain],
  (main) => main.selectedChatUser
);
export const selectNotifications = createSelector(
  [selectMain],
  (main) => main.notifications
);

export default MainSlice.reducer;
