import React from 'react'
import Navbar from '../../components/Layout/Navbar'
import Footer from '../../components/Layout/Footer'
import Plans from "./Plans"

const PricingPage = () => {
  return (
    <div>
        <Navbar/>
        <Plans/>
        <Footer/>
    </div>
  )
}

export default PricingPage