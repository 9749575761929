import React, { useState, useEffect } from "react";
import axios from "axios";
import { TextField, Button } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation, useTranslation } from "react-i18next";
const API_URL = "https://fullsetters.com/api/employment/";

const Employment = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState([]);
  const [fields, setFields] = useState({
    employeer: "",
    jobTitle: "",
    startingDate: "",
    finishingDate: "",
    streetName: "",
    city: "",
    state: "",
    zipCode: "",
    province: "",
    relationship: "",
    name: "",
    email: "",
    phone: "",
  });

  const editHandler = (index) => {
    setActiveIndex(index);
    setFields(data[index]);
  };
  useEffect(() => {
    if (data.length > 0 && activeIndex === 0) {
      setFields(data[0]);
    }
  }, [activeIndex, data]);

  const getData = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(API_URL + user._id, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });

    const result = response.data;
    setData(result.data);
  };
  useEffect(() => {
    getData();
  }, []);

  const getUserId = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    setUserId(user._id);
  };
  useEffect(() => {
    getUserId();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const dataToSubmit = {
      applicationId: id,
      userId: userId,
      selectedRecords: {
        employment: fields?._id,
      },
    };

    axios
      .post("https://fullsetters.com/api/application/apply", dataToSubmit)
      .then(() => {
        toast.success("Application submitted successfully!");
        setTimeout(() => {
          navigate("/success");
        }, 1200);
      })
      .catch((error) => {
        toast.error("Error submitting application!");
        console.error("Error submitting application:", error);
      });
  };
  return (
    <div className="">
      <ToastContainer />
      <div className="max-w-[1550px] mx-auto my-10">
        <div className=" bg-white rounded">
          <h2 className="mb-2 px-4 py-4 text-2xl font-bold border-b pb-4 text-primary">
            {t("Employement")}
          </h2>

          <div className="p-4">
            <h3 className="mb-4 text-2xl font-bold pt-3 text-primary">
              {t("Work Experience")}
            </h3>
            <h3 className="mb-2 text-xxl font-normal text-secondary">
              {data?.length === 0 && "Please enter your Work Experience"}
            </h3>
            <div className="flex flex-wrap gap-1 mb-6">
              {data?.map((item, index) => {
                return (
                  <div key={index}>
                    <button
                      onClick={() => editHandler(index)}
                      className={`${
                        activeIndex !== index
                          ? "border-secondary text-secondary bg-transparent"
                          : "border-[#3867D6] text-white bg-[#3867D6]"
                      } px-4 py-2.5 border rounded-sm `}
                    >
                      {item?.jobTitle}
                    </button>
                  </div>
                );
              })}
            </div>
            {data?.length > 0 ? (
              <>
                <div>
                  <div className="py-5 bg-white">
                    <h2 className="mb-2 text-2xl font-bold text-left text-primary">
                      {fields?.employeer || ""}
                    </h2>
                  </div>
                  <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <TextField
                        fullWidth
                        label={t("Employeer")}
                        id="fullWidth"
                        name="employeer"
                        value={fields?.employeer || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("Job Title")}
                        id="fullWidth"
                        name="jobTitle"
                        value={fields?.jobTitle || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("Starting Date")}
                        id="fullWidth"
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        name="startingDate"
                        value={fields?.startingDate || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("Finishing Date")}
                        id="fullWidth"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        name="finishingDate"
                        value={fields?.finishingDate || ""}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="py-5 bg-white">
                    <h2 className="mb-2 text-2xl font-bold text-left text-primary">
                      {t("Work Address")}
                    </h2>
                  </div>
                  <div className="mb-6">
                    <TextField
                      fullWidth
                      label={t("Street name & number")}
                      id="fullWidth"
                      name="streetName"
                      value={fields?.streetName || ""}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <TextField
                        fullWidth
                        label={t("City")}
                        id="fullWidth"
                        name="city"
                        value={fields?.city || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("State")}
                        id="fullWidth"
                        name="state"
                        value={fields?.state || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("ZIP Code")}
                        id="fullWidth"
                        name="zipCode"
                        value={fields?.zipCode || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("Province")}
                        id="fullWidth"
                        name="province"
                        value={fields?.province || ""}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="py-5 bg-white">
                    <h2 className="mb-2 text-2xl font-bold text-left text-primary">
                      {t("Contact Person")}
                    </h2>
                  </div>

                  <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <TextField
                        fullWidth
                        label={t("Relationship")}
                        id="fullWidth"
                        name="relationship"
                        value={fields?.relationship || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("Name")}
                        id="fullWidth"
                        name="name"
                        value={fields?.name || ""}
                      />
                    </div>

                    <div>
                      <TextField
                        fullWidth
                        label={t("Email")}
                        id="fullWidth"
                        name="email"
                        value={fields?.email || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("Phone")}
                        id="fullWidth"
                        name="phone"
                        value={fields?.phone || ""}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <Link to="/branch">
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      backgroundColor: "#3867D6",
                    }}
                  >
                    {t("Go Back To Demographics")}
                  </Button>
                </Link>
              </div>
            )}
          </div>
          <div className="px-4 pb-6">
            <Button
              variant="contained"
              size="large"
              sx={{
                backgroundColor: "#3867D6",
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Employment);
