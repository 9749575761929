import React from "react";
import { Link, useLocation } from "react-router-dom";

const PaymentHistoryWrapper = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <div className="bg-white border border-[#D9D9D9] rounded-md">
      <div className="flex gap-4 border-b border-[#D9D9D9]">
        {data.map((item, index) => (
          <Link to={item.route} key={index}>
            <span
              className={`text-base font-medium items-center py-4 px-6 flex  ${
                currentPath === item.route
                  ? " border-b-2 border-b-[#3867D6] text-[#3867D6]"
                  : ""
              }`}
            >
              {item.icon}
              {item.title}
            </span>
          </Link>
        ))}
      </div>
      <div>{children}</div>
    </div>
  );
};

export default PaymentHistoryWrapper;

const data = [
  {
    id: 1,
    title: "Paid History",
    route: "/billing/payment-history/paid",
  },
  {
    id: 2,
    title: "Refunded History",
    route: "/billing/payment-history/refunded",
  },
];
