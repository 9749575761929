import React from "react";
import TableContainer from '@mui/material/TableContainer';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from '@mui/material/Paper';
import Layout from "./Layout";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const rows = [
  createData(
    "#FS124y71282",
    "Ln126494699418",
    "Loan",
    "9th June 2022, 14:29:38"
  ),
  createData(
    "#FS124y71282",
    "Ln126494699418",
    "Loan",
    "9th June 2022, 14:29:38"
  ),
  createData(
    "#FS124y71282",
    "Ln126494699418",
    "Loan",
    "9th June 2022, 14:29:38"
  ),
  createData(
    "#FS124y71282",
    "Ln126494699418",
    "Loan",
    "9th June 2022, 14:29:38"
  ),
];

const History = () => {
  return (
    <Layout>
      <div className="bg-white w-full rounded-t-lg">
        <h1 className="text-xl font-bold text-primary py-4 px-5 border-b border-b-[#D9D9D9]">
          Recent History
        </h1>
      </div>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, overflow: "auto" }}
          className="py-8 bg-white rounded-b-lg w-full"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Application Number</TableCell>
              <TableCell>Application Type</TableCell>
              <TableCell>Time</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  marginBottom: "8px",
                }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.calories}</TableCell>
                <TableCell
                  sx={{
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "22px",
                    color: "#A9A300",
                  }}
                >
                  {row.fat}
                </TableCell>
                <TableCell>{row.carbs}</TableCell>
                <TableCell>{row.protein}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  );
};

export default History;
