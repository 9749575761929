import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import FullsettersPromo from '../../assets/FullsettersPromo.mp4'

const VideoBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full py-40 gradient">
      <div className="max-w-[1550px] mx-auto px-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div className="flex self-center">
            <div className="max-w-[620px]">
              <h1 className="text-white md:text-4xl text-xl font-bold mb-8">
                {t("videoBannerTitle")}
              </h1>
              <p className="text-lg font-normal text-white">
                {t("videoBannerTitleDescription")}
              </p>
            </div>
          </div>
          <div>
            <div >
              <video
                src={FullsettersPromo}
                width="100%"
                height="815"
                autoPlay
                loop
                muted
                playsInline
                className="rounded-md"
              >
                <source src={FullsettersPromo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(VideoBanner);
