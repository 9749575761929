import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { withTranslation, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CookiesPolicyBanner = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  // Check if the user has accepted the cookies policy
  useEffect(() => {
    const isAccepted = localStorage.getItem("cookiesAccepted");
    setIsVisible(!isAccepted);
  }, []);

  const handleAccept = () => {
    // Store the acceptance status in local storage
    localStorage.setItem("cookiesAccepted", "true");
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed z-50 bottom-0 left-0 w-full bg-blue-500 text-white p-4">
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <div className="flex flex-wrap items-center">
          <svg
            className="h-6 w-6 text-white md:mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4a9 9 0 119-9h-2.032"
            />
          </svg>
          <p className="text-sm">
            {t("privacy-text")}
            <Link to="/privacy-policy">
              <span className="underline ml-1"> {t("learn-more")}</span>
            </Link>
          </p>
        </div>

        <div>
          <Button
            variant="text"
            size="large"
            sx={{
              color: "#FFFFFF",
            }}
            onClick={handleReject}
          >
            {t("reject")}
          </Button>
          <Button
            variant="text"
            size="large"
            sx={{
              color: "#FFFFFF",
            }}
            onClick={handleAccept}
          >
            {t("accept")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(CookiesPolicyBanner);
