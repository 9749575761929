import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
const Hero = () => {
  const { t } = useTranslation();
  return (
    <div className="main-container my-[72px]">
      <div className="mx-auto bg-[#EBF0FB] py-14 px-8 md:px-24 rounded-lg">
        <h1 className="text-2xl leading-[36px] md:text-4xl md:leading-[50px] lg:text-5xl lg:leading-[60px] text-primary font-bold max-w-4xl mx-auto text-center mb-8">
          {t("manage-your")}{" "}

          <span className="text-[#3867D6] pr-1.5">{t("Revolutionize")}</span>
          {t("the Way You Apply with")}
          <span className="text-[#3867D6] pl-1.5">{t("fullSetters")}</span>
        </h1>
        <p className="text-base md:text-lg lg:text-xl font-normal text-center text-secondary">
          {t("about-description")}
        </p>
      </div>
    </div>
  );
};

export default withTranslation()(Hero);
