import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../Layout";

const PaymentDetail = () => {
  return (
    <Layout>
      <div className="bg-white p-6 rounded shadow-md w-2/3 mx-auto mt-12">
        <div className="flex justify-between items-center mb-4">
          <Link to="/billing/payment-history/paid">
            <button className="text-blue-500">← Back</button>
          </Link>
          <h1 className="text-2xl font-bold">Payment details</h1>
          <div className="w-20"></div>
        </div>

        <div className="border-t border-b py-4 mb-4">
          <div className="text-gray-500 mb-2">Payment ID: H_2717815</div>
        </div>

        <div className="mb-6">
          <table className="w-full">
            <thead>
              <tr className="border-b text-left">
                <th className="pb-2 w-1/3">Services</th>
                <th className="pb-2 w-1/3">Period</th>
                <th className="pb-2 w-1/3 text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="py-2">
                <td>Business Web Hosting (billed every 2 years)</td>
                <td>2023-07-27 - 2025-07-27</td>
                <td className="text-right">$96.98</td>
              </tr>
              <tr className="py-2">
                <td>Daily Backup (billed every 2 years)</td>
                <td>2023-07-27 - 2025-07-27</td>
                <td className="text-right">$0.00</td>
              </tr>
              <tr className="py-2">
                <td>Setup</td>
                <td>2023-07-27 - 2023-07-27</td>
                <td className="text-right">$0.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="border-t py-4">
          <div className="flex justify-between mb-2">
            <span>Subtotal</span>
            <span>$96.98</span>
          </div>
          <div className="flex justify-between mb-2 text-gray-500">
            <span>Taxes & Fees ⓘ</span>
            <span>$0.00</span>
          </div>
          <div className="flex justify-between font-bold">
            <span>Total</span>
            <span>$96.98</span>
          </div>
        </div>

        <div className="mt-6">
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Download Invoice
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default PaymentDetail;
