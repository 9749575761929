import React from "react";
import AboutImage from "../../assets/about.jpg";
import Illustration from "../../assets/about-illustraion.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
const ContentCards = () => {
  const { t } = useTranslation();
  return (
    <div className="mx-auto px-4 md:px-8 lg:px-24 py-4 mb-10">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div className="p-6 md:p-8 rounded-lg bg-[#ECEEEF]">
          <div className="mb-4 md:mb-6">
            <img src={AboutImage} alt="" className="w-full rounded-lg" />
          </div>
          <div>
            <h1 className="text-xl md:text-3xl font-bold text-primary mb-4">
              {t("Team of 30 Professionals, working from")}
              <span className="text-[#3867D6] pl-2">{t("around the World")}</span>
            </h1>
            <p className="text-base md:text-lg font-normal text-secondary">
              {t("team-description")}
            </p>
          </div>
        </div>
        <div className="relative bg-[#ECEEEF] rounded-lg">
          <div className="p-6 md:p-8">
            <div>
              <h1 className="text-xl md:text-4xl font-bold text-primary mb-4 md:mb-6">
                {t("got-some-questions")}
                <span className="text-[#3867D6]"> {t("our-team")}</span>
              </h1>
              <Link to="/contact-us">
                <Button variant="outlined" size="large">
                  {t("contact-us")}
                </Button>
              </Link>
            </div>
            <div className="absolute -bottom-0 -right-0">
              <img
                src={Illustration}
                alt=""
                className="w-[300px] md:w-[420px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ContentCards);
