import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { IoIosChatbubbles } from "react-icons/io";

const List = ({ data, handleDelete, handleview }) => {
  const convertTime = (originalTime) => {
    const time = new Date(originalTime);
    const hours = time.getUTCHours();
    const minutes = time.getUTCMinutes();
    const seconds = time.getUTCSeconds();

    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className="w-full">
      <div className="max-w-[1550px] mx-auto px-4 my-12">
        <div className="bg-white rounded-t-lg">
          <h1 className="text-xl font-bold text-primary py-7 px-5 border-b border-b-[#D9D9D9]">
            Applicants
          </h1>
        </div>

        <div className="px-4 py-8 bg-white rounded-b-lg">
          <TableContainer>
            <Table
              className=""
              sx={{ minWidth: 750 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Application Number</TableCell>
                  <TableCell>Appied On</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {item?.user?.name}
                      </TableCell>
                      <TableCell>{item?._id}</TableCell>
                      <TableCell>{convertTime(item?.appliedOn)}</TableCell>
                      <TableCell>
                        <div className="flex items-center gap-5 md:gap-1 max-w-[400px] mx-auto">
                          <Tooltip title="View Applicant Detail" arrow>
                            <IconButton
                              onClick={() => handleview(item?.user?._id)}
                            >
                              <RemoveRedEyeIcon className="text-blue-500" />
                            </IconButton>
                          </Tooltip>

                          <Link to="/chats">
                            <Tooltip title="Chat" arrow>
                              <IconButton>
                                <IoIosChatbubbles className="text-2xl text-[#1976D2]" />
                              </IconButton>
                            </Tooltip>
                          </Link>
                          <div onClick={() => handleDelete(item?.user?._id)}>
                            <Tooltip title="Delete" arrow>
                              <IconButton>
                                <DeleteIcon className="text-red-500" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default List;
