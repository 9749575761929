import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Layout from "./Layout";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const rows = [
  createData("#FS124y71282", "$24.99", "received", "9th June 2022, 14:29:38"),
  createData("#FS124y71282", "$24.99", "cancle", "9th June 2022, 14:29:38"),
  createData("#FS124y71282", "$24.99", "pending", "9th June 2022, 14:29:38"),
  createData("#FS124y71282", "$24.99", "verified", "9th June 2022, 14:29:38"),
];
const styles = {
  Pending: {
    color: "orange",
  },
  Cancel: {
    color: "red",
  },
  Received: {
    color: "green",
  },
};

const Payments = () => {
  return (
    <Layout>
      <div>
        <div className="flex gap-4 flex-wrap justify-center sm:justify-start">
          <div className="w-full sm:w-[25%]">
            <div className="w-full h-48 bg-blue-500 rounded-lg flex items-center  px-4">
              <div className="flex flex-col ">
                <h2 className="text-white font-bold text-[40px] ">
                  <sup>$</sup>127,432.45,
                </h2>
                <span className="text-white font-bold text-xl opacity-70 px-4 ">
                  {" "}
                  Current Balance
                </span>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-[25%]">
            <div className="w-full h-48 bg-white  rounded-lg flex items-center  px-4">
              <div className="text-black font-bold text-[40px] flex flex-col ">
                <h2 className="text-black font-bold text-[40px] ">
                  <sup>$</sup>127,432.45,
                </h2>
                <span className="text-black font-bold text-xl px-4">
                  {" "}
                  Current Balance
                </span>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-[25%]">
            <div className="w-full h-48 bg-white rounded-lg flex items-center  px-4">
              <div className="flex flex-col ">
                <h2 className="text-[#FF5858] font-bold text-[40px] ">
                  <sup>$</sup>127,432.45,
                </h2>
                <span className="text-black font-bold text-xl px-4 ">
                  Current Balance
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className="my-12">
            <div className="bg-white rounded-t-lg">
              <h1 className="text-xl font-bold text-primary py-4 px-5 border-b border-b-[#D9D9D9]">
                Recent Payments
              </h1>
            </div>

            <div className=" py-8 bg-white rounded-b-lg">
              <TableContainer>
                <Table
                  className=""
                  sx={{ minWidth: 750 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>PaymentID</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          marginBottom: "8px",
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell>{row.calories}</TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Open Sans",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "22px",
                            ...(styles[row.status] || {}), // Apply the appropriate style based on row.status
                          }}
                        >
                          {row.fat}
                        </TableCell>
                        <TableCell>{row.carbs}</TableCell>
                        <TableCell>{row.protein}</TableCell>
                        <TableCell>
                          <a
                            href="/"
                            className="underline text-[#3867D6] cursor-pointer text-sm sm:text-lg font-medium"
                          >
                            View Details
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Payments;
