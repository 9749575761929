import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import cross from "../../../assets/cross.svg";
import Employementmodal from "../../../components/Employementmodal";
import Rentalmodal from "../../../components/Rentalmodal";
import DemographicModal from "../../../components/DemographicModal";
import Layout from "./Layout";
import { ToastContainer, toast } from "react-toastify";

const API_URL = "https://fullsetters.com/api/applications/";
const API = "https://fullsetters.com/api/applications/applicaion-detail";

const createData = (_id, userId, name, type, number, time) => {
  return { _id, name, userId, type, number, time };
};

const List = () => {
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axios.get(API_URL);
        const result = response.data.data;

        const formattedData = result.map((applications) =>
          createData(
            applications._id,
            applications.user._id,
            applications.user.name,
            applications.applicationType,
            applications.applicationNumber,
            applications.requestTime
          )
        );

        setData(formattedData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchApplications();
  }, []);

  // delete application by id
  const handleDelete = async (_id) => {
    try {
      // Delete the application
      await axios.delete(`${API_URL}${_id}`);

      toast.success("Application deleted successfully");
    } catch (error) {
      console.error(error);
      toast.error("Error deleting application. Please try again.");
    }
  };

  const handleview = async (user, applicationType) => {
    try {
      const response = await axios.post(API, {
        userId: user,
        applicationType: applicationType,
      });

      const applicationData = response.data.data;
      setSelectedData(applicationData);

      // Open the corresponding modal based on the application type
      if (
        applicationType === "School Application" ||
        applicationType === "Immigration Application" ||
        applicationType === "Job Application" ||
        applicationType === "Loan Application"
      ) {
        setIsModalOpen("demographic");
      } else if (applicationType === "Rental Application") {
        setIsModalOpen("rental");
      } else if (applicationType === "Employment Application") {
        setIsModalOpen("employment");
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error(error);
      toast.error("PError fetching application detail. Please try again.");
    }
  };

  const getTypeColor = (type) => {
    switch (type) {
      case "Loan Application":
        return "#FF7F50"; // Coral color
      case "Job Application":
        return "#32CD32"; // LimeGreen color
      case "School Application":
        return "#4169E1"; // RoyalBlue color
      case "Immigration Application":
        return "#FF1493"; // DeepPink color
      case "Rental Application":
        return "#283482";
      case "Employment Application":
        return "#FBC424";
      default:
        return "#000000"; // Black color (default)
    }
  };

  const DataRow = ({ row }) => (
    <TableRow
      key={row.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {row.name}
      </TableCell>
      <TableCell style={{ color: getTypeColor(row.type) }}>
        {row.type}
      </TableCell>
      <TableCell>{row.number}</TableCell>
      <TableCell>{row.time}</TableCell>
      <TableCell>
        <div className="flex justify-center gap-2 md:gap-4 py-3 px-3 items-center border border-black rounded w-full md:max-w-[110px] cursor-pointer mx-auto">
          <img src={cross} alt="" />
          <span onClick={() => handleDelete(row._id)}>Delete</span>
        </div>
      </TableCell>
      <TableCell>
        <div className="flex justify-center gap-2 md:gap-4 py-3 px-3 items-center border border-black rounded w-full md:max-w-[110px] cursor-pointer mx-auto">
          <span
            className="underline text-[#3867D6] cursor-pointer text-sm sm:text-lg font-medium"
            onClick={() => handleview(row.userId, row.type)}
          >
            View
          </span>
        </div>
      </TableCell>
    </TableRow>
  );

  return (
    <Layout>
      <ToastContainer />
      <div>
        <div className="bg-white rounded-t-lg">
          <h1 className="text-xl font-bold text-primary py-4 px-5 border-b border-b-[#D9D9D9]">
            Applications
          </h1>
        </div>

        <div className=" py-8 bg-white rounded-b-lg">
          <TableContainer>
            <Table
              className=""
              sx={{ minWidth: 750 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Application Type</TableCell>
                  <TableCell>Application Number</TableCell>
                  <TableCell>Request Time</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 ? (
                  data.map((row) => <DataRow key={row._id} row={row} />)
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      {data.length === 0
                        ? "No applications found."
                        : "Loading..."}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {isModalOpen === "demographic" && (
        <DemographicModal
          isOpen={true} // Pass the appropriate prop to control the modal visibility
          onClose={() => setIsModalOpen(false)}
          data={selectedData}
        />
      )}

      {isModalOpen === "employment" && (
        <Employementmodal
          isOpen={true} // Pass the appropriate prop to control the modal visibility
          onClose={() => setIsModalOpen(false)}
          data={selectedData}
        />
      )}

      {isModalOpen === "rental" && (
        <Rentalmodal
          isOpen={true} // Pass the appropriate prop to control the modal visibility
          onClose={() => setIsModalOpen(false)}
          data={selectedData}
        />
      )}
    </Layout>
  );
};

export default List;
