import React from "react";
import Contact from "../../assets/Contact.png";
import { Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation, useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const Hero = () => {
  const { t } = useTranslation();
  const initialValues = {
    fullName: "",
    email: "",
    officeHours: "",
    phone: "",
    fax: "",
    website: "",
    officeAddress: "",
    socialMedia: "",
    message: "",
  };
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleSubmit = async (values, actions) => {
    try {
      const response = await fetch("https://fullsetters.com/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (response.ok) {
        actions.resetForm();
        toast.success("Submitted Successfully!");
      } else {
        // Handle errors
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="main-container py-48">
      <ToastContainer />
      <div className="flex flex-col md:flex-row justify-between">
        <div className="bg-[#EBF0FB] pt-44">
          <img src={Contact} alt="Fullsettters" width={400} height={400} />
        </div>
        <div className="flex flex-col justify-center md:w-1/2">
          <h1 className="text-[#142D3C] text-4xl font-bold mb-4 font-robot">
            👋 {t("We’d love to hear from you.")}
          </h1>
          <p className="text-[#142D3C] text-lg font-[400px] mb-10">
            {t("Please get in touch by filling the form.")}
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="flex flex-col md:flex-row gap-4 mb-5">
                  <div className="w-full">
                    <p className="text-[#102E3D] text-base font-[400px] mb-3">
                      {t("Full Name")}
                    </p>
                    <Field
                      type="text"
                      name="fullName"
                      placeholder={t("Name")}
                      className="py-4 pl-4 w-full bg-[#E5E7EB] rounded-[2px] border-[1px] border-transparent hover:border-blue-400 outline-none"
                    />
                    {errors.fullName && touched.fullName && (
                      <div className="text-red-500 py-1 text-sm">
                        {errors.fullName}
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <p className="text-[#102E3D] text-base font-[400px] mb-3">
                      {t("Email Address")}
                    </p>
                    <Field
                      type="text"
                      name="email"
                      placeholder={t("Email")}
                      className="py-4 pl-4 w-full bg-[#E5E7EB] rounded-[2px] border-[1px] border-transparent hover:border-blue-400 outline-none"
                    />
                    {errors.email && touched.email && (
                      <div className="text-red-500 py-1 text-sm">
                        {errors.email}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-4 mb-5">
                  <div className="w-full">
                    <p className="text-[#102E3D] text-base font-[400px] mb-3">
                      {t("Office Hours")}
                    </p>
                    <Field
                      type="text"
                      name="officeHours"
                      placeholder={t("Office Hours")}
                      className="py-4 pl-4 w-full bg-[#E5E7EB] rounded-[2px] border-[1px] border-transparent hover:border-blue-400 outline-none"
                    />
                  </div>
                  <div className="w-full">
                    <p className="text-[#102E3D] text-base font-[400px] mb-3">
                      {t("Phone")}
                    </p>
                    <Field
                      type="text"
                      name="phone"
                      placeholder={t("Phone")}
                      className="py-4 pl-4 w-full bg-[#E5E7EB] rounded-[2px] border-[1px] border-transparent hover:border-blue-400 outline-none"
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-4 mb-5">
                  <div className="w-full">
                    <p className="text-[#102E3D] text-base font-[400px] mb-3">
                      {t("Fax")}
                    </p>
                    <Field
                      type="text"
                      name="fax"
                      placeholder={t("Fax")}
                      className="py-4 pl-4 w-full bg-[#E5E7EB] rounded-[2px] border-[1px] border-transparent hover:border-blue-400 outline-none"
                    />
                  </div>
                  <div className="w-full">
                    <p className="text-[#102E3D] text-base font-[400px] mb-3">
                      {t("Website")}
                    </p>
                    <Field
                      type="text"
                      name="website"
                      placeholder={t("Website")}
                      className="py-4 pl-4 w-full bg-[#E5E7EB] rounded-[2px] border-[1px] border-transparent hover:border-blue-400 outline-none"
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-4 mb-5">
                  <div className="w-full">
                    <p className="text-[#102E3D] text-base font-[400px] mb-3">
                      {t("Office Address")}
                    </p>
                    <Field
                      type="text"
                      name="officeAddress"
                      placeholder={t("Office Address")}
                      className="py-4 pl-4 w-full bg-[#E5E7EB] rounded-[2px] border-[1px] border-transparent hover:border-blue-400 outline-none"
                    />
                  </div>
                  <div className="w-full">
                    <p className="text-[#102E3D] text-base font-[400px] mb-3">
                      {t("Social Media")}
                    </p>
                    <Field
                      type="text"
                      name="socialMedia"
                      placeholder={t("Social Media")}
                      className="py-4 pl-4 w-full bg-[#E5E7EB] rounded-[2px] border-[1px] border-transparent hover:border-blue-400 outline-none"
                    />
                  </div>
                </div>
                <p className="text-[#102E3D] text-base font-[400px] mb-3">
                  {t("Your message")}
                </p>
                <Field
                  as="textarea"
                  type="text"
                  name="message"
                  rows={4}
                  placeholder={t("Your message")}
                  className="py-4 pl-4 w-full bg-[#E5E7EB] mb-6 rounded-[2px] hover:border-[1px] border-[1px] border-transparent hover:border-blue-400 outline-none"
                />
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: "#3867D6",
                  }}
                  type="submit"
                  onClick={handleSubmit}
                >
                  {t("Submit")}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Hero);
