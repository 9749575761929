import React from "react";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import Hero from "./Hero";
import Comapnies from "./Comapnies";
import WhyFullsetters from "./WhyFullsetters";
import WhatUsersSay from "./WhatUsersSay";
import VideoBanner from "./VideoBanner";
import Faq from "./Faq";

const HomePage = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Comapnies />
      <div id="whyFullSetters">
        <WhyFullsetters />
      </div>
      <VideoBanner />
      <WhatUsersSay />
      <div id="faqs">
        <Faq />
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
