import React from "react";
import SuccessImage from "../../assets/success.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const Success = () => {
  return (
    <div className="w-full">
      <div className="max-w-[770px] mx-auto px-4 my-12">
        <div className="px-5 pt-24 bg-white rounded-lg pb-14">
          <div>
            <div className="flex justify-center mb-10">
              <img src={SuccessImage} alt="" />
            </div>
            <h1 className="mb-4 text-2xl font-bold text-center text-primary">
              Your application have submitted successfully
            </h1>
            <p className="mb-24 text-lg text-center text-secondary">
              Please check your email for future updates.{" "}
            </p>
            <div className="flex justify-center ">
              <Link to="/product-and-services/">
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: "#3867D6",
                  }}
                >
                  Go back to master page
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
