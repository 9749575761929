import React from "react";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import Hero from "./Hero";
import Faq from "../Home/Faq";
const HelpcenterPage = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <div id="FAQ">
        <Faq />
      </div>
      <Footer />
    </div>
  );
};

export default HelpcenterPage;
