import React from "react";
import TagUser from "../../assets/tag-user.svg";
import Receipt from "../../assets/receipt-edit.svg";
import Status from "../../assets/status-up.svg";
import { withTranslation, useTranslation } from "react-i18next";

const WhyFullsetters = () => {
  const { t } = useTranslation();
  const data = [
    {
      title: t("why-fullSetters-title-1"),
      description: t("why-fullSetters-desc-1"),
      icon: TagUser,
    },
    {
      title: t("why-fullSetters-title-2"),
      description: t("why-fullSetters-desc-2"),
      icon: Receipt,
    },
    {
      title: t("why-fullSetters-title-3"),
      description: t("why-fullSetters-desc-3"),
      icon: Status,
    },
    {
      title: t("why-fullSetters-title-4"),
      description: t("why-fullSetters-desc-4"),
      icon: Receipt,
    },
    {
      title: t("why-fullSetters-title-5"),
      description: t("why-fullSetters-desc-5"),
      icon: TagUser,
    },
    {
      title: t("why-fullSetters-title-6"),
      description: t("why-fullSetters-desc-6"),
      icon: TagUser,
    },
  ];

  return (
    <div className="w-full py-20 bg-white">
      <div className="max-w-[1550px] mx-auto px-4">
        <h1 className="text-center text-[40px]  mb-12 font-bold text-primary">
          {t("why")} <span className="text-[#3867D6]">{t("fullSetters")}?</span>
        </h1>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          {data.map((item, index) => {
            return (
              <div key={index} className="bg-[#F8F9F9] px-8 py-6 rounded-lg">
                <div className="w-24 h-24 bg-[#E1E8F9] rounded-lg flex justify-center items-center mb-5">
                  <img src={item.icon} alt="" />
                </div>
                <h2 className="text-[22px] font-semibold text-primary mb-2">
                  {item.title}
                </h2>
                <p className="text-lg font-normal text-secondary">
                  {item.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(WhyFullsetters);
