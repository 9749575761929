import React, { useState, useEffect } from "react";
import Logo from "../../assets/mainLogo.svg";
import socketIOClient from "socket.io-client";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { withTranslation, useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  selectNotifications,
  setNotifications,
  setSelectedChatUser,
} from "../MainSlice";
import { FiLogOut } from "react-icons/fi";
import Drawer from "@mui/material/Drawer";
import { logout } from "../member-auth/authSlice";
import { googleLogout } from "@react-oauth/google";

const SOCKET_ENDPOINT = "https://fullsetters.com";
const API_URL = "https://fullsetters.com/api/notifications";

function notificationsLabel(count) {
  if (count === 0) {
    return "no notifications";
  }
  if (count > 99) {
    return "more than 99 notifications";
  }
  return `${count} notifications`;
}

function MobileMenu() {

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const notifications = useSelector(selectNotifications);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(!openDrawer);
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const socket = socketIOClient(SOCKET_ENDPOINT, {
      path: "/socket.io",
      query: {
        token: user?.token,
      },
    });
    if (socket) {
      socket.on("newMessageNotification", (notification) => {
        const newNotifications = [...notifications, notification];
        dispatch(setNotifications(newNotifications));
      });
    }

    // return () => {
    //   if (socket) {
    //     socket.off("newMessageNotification");
    //   }
    // };
  }, [notifications, dispatch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function changeLanguage(countryCode) {
    let langCode = "en";
    if (countryCode === "ES") {
      langCode = "es";
    } else if (countryCode === "DE") {
      langCode = "de";
    }
    i18n.changeLanguage(langCode);
  }

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await axios.get(`${API_URL}/${user._id}`);
        const notificationsData = response.data;
        dispatch(setNotifications(notificationsData));
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [dispatch]);

  const handleNotificationClick = async (notification) => {
    dispatch(
      setSelectedChatUser({
        _id: notification?.fromUserId,
        name: notification?.fromUserName,
      })
    );
    try {
      // Mark the notification as read
      await axios.patch(`${API_URL}/read/${notification._id}`);
      const updatedNotifications = notifications.filter(
        (n) => n._id !== notification._id
      );
      dispatch(setNotifications(updatedNotifications));

      // If there are more notifications from this user, clear them
      const hasMoreNotificationsFromUser = notifications.some(
        (n) => n.fromUserId === notification.fromUserId
      );

      if (hasMoreNotificationsFromUser) {
        await axios.delete(`${API_URL}/clear/${notification.fromUserId}`);
      }

      navigate("/chats");
    } catch (error) {
      console.error("Error handling notification:", error);
    }
  };

  const handleLogout = async () => {
    await googleLogout();
    dispatch(logout());
  };
  return (
    <div>
      <React.Fragment>
        <div className="w-full bg-white border-b border-b-[#D9D9D9]">
          <div className="px-4 py-4 flex gap-4 justify-between items-center">
            <div>
              <Link to="/">
                <img src={Logo} alt="Fullsettters" className="w-40" />
              </Link>
            </div>
            <div className="flex items-center">
              <IconButton
                aria-label={notificationsLabel(notifications?.length)}
                onClick={handleClick}
              >
                <Badge badgeContent={notifications?.length} color="info">
                  <NotificationsNoneIcon />
                </Badge>
              </IconButton>
              <span className="cursor-pointer relative">
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                >
                  {!notifications.length && (
                    <MenuItem>
                      <Typography variant="body2" color="text.secondary">
                        No New Messages
                      </Typography>
                    </MenuItem>
                  )}

                  {notifications?.length > 0 &&
                    notifications?.map((notif) => (
                      <MenuItem
                        key={notif._id}
                        onClick={() => handleNotificationClick(notif)}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography variant="subtitle1">
                          New Message from {notif?.fromUserName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {notif?.message}
                        </Typography>
                      </MenuItem>
                    ))}
                </Menu>
              </span>
              <Link to="/chats">
                <IconButton>
                  <MailIcon color="#707070" />
                </IconButton>
              </Link>
              <IconButton onClick={toggleDrawer()}>
                <MenuIcon
                  sx={{
                    width: "35px",
                    fontSize: "35px",
                  }}
                />
              </IconButton>
            </div>
          </div>
        </div>
        <Drawer
          anchor={"left"}
          open={openDrawer}
          onClose={toggleDrawer()}
          sx={{
            minWidth: "380px",
          }}
        >
          <div className="px-4 py-4 mb-10">
            <div className="mb-10   ">
              <Link to="/">
                <img src={Logo} alt="Fullsettters" />
              </Link>
            </div>
            {location.pathname !== "/product-and-services" &&
            location.pathname !== "/product-and-services/" ? (
              <div className="flex flex-col ">
                <Link to="/">
                  <Button
                    variant="text"
                    size="large"
                    sx={{
                      color: "#102E3D",
                    }}
                  >
                    {t("home")}
                  </Button>
                </Link>
                <Link to="/about">
                  <Button
                    variant="text"
                    size="large"
                    sx={{
                      color: "#102E3D",
                    }}
                  >
                    {t("about")}
                  </Button>
                </Link>
                <Link to="/help-center">
                  <Button
                    variant="text"
                    size="large"
                    sx={{
                      color: "#102E3D",
                    }}
                  >
                    {t("faqs")}
                  </Button>
                </Link>
                <Link to="/contact-us">
                  <Button
                    variant="text"
                    size="large"
                    sx={{
                      color: "#102E3D",
                    }}
                  >
                    {t("contact")}
                  </Button>
                </Link>
                <Link to="/blogs">
                  <Button
                    variant="text"
                    size="large"
                    sx={{
                      color: "#102E3D",
                    }}
                  >
                    {t("blogs")}
                  </Button>
                </Link>
                <Link to="/pricing">
                  <Button
                    variant="text"
                    size="large"
                    sx={{
                      color: "#102E3D",
                    }}
                  >
                    {t("pricing")}
                  </Button>
                </Link>
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <Link to="/product-and-services/applications">
                  <Button
                    variant="text"
                    size="large"
                    sx={{
                      color: "#102E3D",
                    }}
                  >
                    {t("my-applications")}
                  </Button>
                </Link>
              </div>
            )}

            <div className="flex flex-col gap-2">
              {user ? (
                <div className="flex flex-col gap-2">
                  <Link to="/account-settings">
                    <Button
                      variant="text"
                      size="large"
                      sx={{
                        color: "#102E3D",
                      }}
                    >
                      Account Settings
                    </Button>
                  </Link>
                  <Link to="/billing/subscriptions">
                    <Button
                      variant="text"
                      size="large"
                      sx={{
                        color: "#102E3D",
                      }}
                    >
                      Billing
                    </Button>
                  </Link>
                  <div
                    className="flex gap-2 pl-2 items-center cursor-pointer transition-all hover:text-red-500"
                    onClick={handleLogout}
                  >
                    <span>
                      <FiLogOut className="w-5 h-5" />
                    </span>
                    <span className="text-base font-medium">Logout</span>
                  </div>
                </div>
              ) : (
                <Link to="/login">
                  <Button variant="outlined" size="large">
                    {t("sign-in")}
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div className="mx-4">
            <ReactFlagsSelect
              placeholder="Select Language"
              countries={["US", "ES", "DE"]} // Add more country codes as needed
              customLabels={{ US: "US", ES: "ES", DE: "DE" }}
              selected={
                i18n.language.toUpperCase() === "EN"
                  ? "US"
                  : i18n.language.toUpperCase()
              }
              onSelect={(countryCode) => changeLanguage(countryCode)}
            />
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}

export default withTranslation()(MobileMenu);
