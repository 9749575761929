import React from "react";
import { withTranslation, useTranslation } from "react-i18next";


const Hero = () => {
  const { t } = useTranslation();
  return (
    <div className="main-container">
      <div className="pt-16">
        <h1 className="text-4xl font-bold font-Roboto text-[#142D3C] mb-6">
          {t("Fullsetters Terms of Service")}
        </h1>
        <p className="text-lg font-Roboto font-normal text-[#14D3C]">
          {t("terms-title")}
        </p>
      </div>
      <div className="py-16">
        <div className="max-w-[1012px] mb-8">
          <h2 className="text-xl font-bold font-Roboto text-[#142D3C] mb-3">
            {t("user-responsibilities")}
          </h2>
          <ul className="text-lg font-Roboto font-normal text-[#14D3C] list-disc list-item ml-10 mb-10">
            <li> {t("accurate-info")} </li>
            <li>   {t("account-confidentiality")} </li>
            <li>   {t("unlawful-activities")} </li>
          </ul>
          <h2 className="text-xl font-bold font-Roboto text-[#142D3C] mb-3">
            {t("use-of-service-title")}
          </h2>
          <ul className="text-lg font-Roboto font-normal text-[#14D3C] list-disc list-item ml-10 mb-10">
            <li>{t("non-exclusive-license")} </li>
            <li>{t("no-modify-service")} </li>
            <li>{t("third-party-services")} </li>
          </ul>
          <h2 className="text-xl font-bold font-Roboto text-[#142D3C] mb-3"> {t("intellectual-property-title")}</h2>
          <ul className="text-lg font-Roboto font-normal text-[#14D3C] list-disc list-item ml-10 mb-10">
            <li> {t("fullsetters-rights")} </li>
            <li> {t("no-use-trademarks")} </li>
          </ul>
          <h2 className="text-xl font-bold font-Roboto text-[#142D3C] mb-3"> {t("privacy-title")} </h2>
          <ul className="text-lg font-Roboto font-normal text-[#14D3C] list-disc list-item ml-10 mb-10">
            <li> {t("privacy-policy-text")} </li>
            <li> {t("consent-to-collection")} </li>
          </ul>
          <h2 className="text-xl font-bold font-Roboto text-[#142D3C] mb-3"> {t("liability-title")} </h2>
          <ul className="text-lg font-Roboto font-normal text-[#14D3C] list-disc list-item ml-10 mb-10">
            <li> {t("no-guarantee")} </li>
            <li> {t("not-liable")} </li>
          </ul>
          <ul className="text-lg font-Roboto font-normal text-[#14D3C] list-disc list-item ml-10 mb-10">
            <li> {t("termination-title")} </li>
            <li> {t("right-to-terminate")} </li>
            <li> {t("data-deletion")} </li>
          </ul>
          <h2 className="text-xl font-bold font-Roboto text-[#142D3C] mb-3"> {t("modifications-title")} </h2>
          <ul className="text-lg font-Roboto font-normal text-[#14D3C] list-disc list-item ml-10 mb-10">
            <li> {t("right-to-modify")} </li>
            <li> {t("review-terms")} </li>
          </ul>

          <p className="text-lg font-Roboto font-normal text-[#14D3C]">{t("final-agreement")}</p>

        </div>
      </div>

    </div >
  );
};

export default withTranslation()(Hero);
