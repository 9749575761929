import React from "react";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import Success from "./Success";

const SuccessPage = () => {
  return (
    <>
      <Navbar />
      <Success />
      <Footer />
    </>
  );
};

export default SuccessPage;
