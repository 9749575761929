import React from "react";
import Slider from "react-slick";
import QuoteIcon from "../../assets/quote.svg";
import { withTranslation, useTranslation } from "react-i18next";

const WhatUsersSay = () => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full py-20">
      <div className="max-w-[1550px] mx-auto px-4">
        <h2 className="text-primary text-center text-4xl md:text-[40px] font-bold mb-10">
          {t("what")}{" "}
          <span className="text-[#3867D6]">{t("fullSetters")} </span>{" "}
          {t("users-say")}?
        </h2>
        <div>
          <Slider {...settings}>
            <div>
              <Card description={t("testimonial-1")} />
            </div>
            <div>
              <Card description={t("testimonial-2")} />
            </div>

          </Slider>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(WhatUsersSay);

const Card = ({ description }) => {
  return (
    <div className="p-4 md:p-8 bg-white rounded-lg">
      <img src={QuoteIcon} alt="" className="w-8 h-8 md:w-12 md:h-12 mb-4" />
      <p className="text-base md:text-lg font-normal text-secondary mb-4 max-w-xl">
        {description}
      </p>
      {/* <div className="flex flex-col md:flex-row md:items-center gap-2">
        <div>
          <img src={Avatar} alt="" className="w-10 h-10 md:w-14 md:h-14" />
        </div>
        <div className="text-center md:text-left">
          <h2 className="text-base md:text-lg font-semibold text-primary">
            Person Name
          </h2>
          <p className="text-sm md:text-base font-normal text-secondary">
            Head Master
          </p>
        </div>
      </div> */}
    </div>
  );
};
