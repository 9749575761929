import React from "react";
import { withTranslation, useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div className="main-container">
      <div className="pt-16">
        <h1 className="text-4xl font-bold font-Roboto text-[#142D3C] mb-6">
          {t("Privacy and Policy")}
        </h1>
        <p className="text-lg font-Roboto font-normal text-[#14D3C]">
          {t("privacy-description")}
        </p>
      </div>
      <div className="py-16">
        <div className="max-w-[1012px] mb-8">
          <h2 className="text-xl font-bold font-Roboto text-[#142D3C] mb-3">
            {t("Information Collection")}
          </h2>
          <ul className="text-lg font-Roboto font-normal text-[#14D3C] list-disc list-item ml-10 mb-10">
            <li> {t("info-caclculation")} </li>
          </ul>
          <h2 className="text-xl font-bold font-Roboto text-[#142D3C] mb-3">
            {t("Use of Information")}
          </h2>
          <ul className="text-lg font-Roboto font-normal text-[#14D3C] list-disc list-item ml-10 mb-10">
            <li>{t("use-of-info-description")} </li>
          </ul>
          <h2 className="text-xl font-bold font-Roboto text-[#142D3C] mb-3">
            {" "}
            {t("Data Security")}
          </h2>
          <ul className="text-lg font-Roboto font-normal text-[#14D3C] list-disc list-item ml-10 mb-10">
            <li> {t("data-security-description")} </li>
          </ul>
          <h2 className="text-xl font-bold font-Roboto text-[#142D3C] mb-3">
            {" "}
            {t("Third-Party Integration:")}{" "}
          </h2>
          <ul className="text-lg font-Roboto font-normal text-[#14D3C] list-disc list-item ml-10 mb-10">
            <li> {t("Third-Party-Integration-description")} </li>
          </ul>
          <h2 className="text-xl font-bold font-Roboto text-[#142D3C] mb-3">
            {" "}
            {t("Cookies")}{" "}
          </h2>
          <ul className="text-lg font-Roboto font-normal text-[#14D3C] list-disc list-item ml-10 mb-10">
            <li> {t("cookies-description")} </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Hero);
