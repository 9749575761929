import React from "react";
import Clock from "../../assets/clock.svg";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import BlogImage1 from "../../assets/blog-1.jpg";
import BlogImage2 from "../../assets/blog-2.jpg";
import BlogImage3 from "../../assets/blog-3.jpg";
import BlogImage4 from "../../assets/blog-4.jpg";
import BlogImage5 from "../../assets/blog-5.jpg";
import BlogImage6 from "../../assets/blog-6.jpg";
import BlogImage7 from "../../assets/blog-7.jpg";
import BlogImage8 from "../../assets/blog-8.jpg";
import BlogImage9 from "../../assets/blog-9.jpg";

const Hero = () => {
  const { t } = useTranslation();
  const data = [
    {
      image: BlogImage1,
      title: t("blog-card1-title"),
      description: t("blog-card1-desc"),
      text: t("blog-card-timestamp"),
      slug: "blog/how-entrapreneurship-can-help-you-stand-out-at-work"
    },
    {
      image: BlogImage2,
      title: t("blog-card2-title"),
      description: t("blog-card2-desc"),
      text: t("blog-card-timestamp"),
      slug: "blog/discover-ins-and-outs-of-working-multiple-remote-jobs"
    },
    {
      image: BlogImage3,
      title: t("blog-card3-title"),
      description: t("blog-card3-desc"),
      text: t("blog-card-timestamp"),
      slug: "blog/how-to-figure-out-if-a-potential-employer-offers-paid-leave"
    },
    {
      image: BlogImage4,
      title: t("blog-card4-title"),
      description: t("blog-card4-desc"),
      text: t("blog-card-timestamp"),
      slug: "blog/how-to-explain-why-youre-right-for-the-job"
    },
    {
      image: BlogImage5,
      title: t("blog-card5-title"),
      description: t("blog-card5-desc"),
      text: t("blog-card-timestamp"),
      slug: "blog/tips-for-shaking-your-job-interview-jitters"
    },
    {
      image: BlogImage6,
      title: t("blog-card6-title"),
      description: t("blog-card6-desc"),
      text: t("blog-card-timestamp"),
      slug: "blog/how-to-know-your-resume-is-ready-to-be-submitted"
    },
    {
      image: BlogImage7,
      title: t("blog-card7-title"),
      description: t("blog-card7-desc"),
      text: t("blog-card-timestamp"),
      slug: "blog/how-to-end-a-networking-conversation-tactfully"
    },
    {
      image: BlogImage8,
      title: t("blog-card8-title"),
      description: t("blog-card8-desc"),
      text: t("blog-card-timestamp"),
      slug: "blog/how-to-follow-up-on-freelance-pitches-without-being-annoying"
    },
    {
      image: BlogImage9,
      title: t("blog-card9-title"),
      description: t("blog-card9-desc"),
      text: t("blog-card-timestamp"),
      slug: "blog/how-working-from-home-can-reduce-stress"
    },


  ];

  return (
    <div className="py-20 container mx-auto">
      <div>
        <h1 className="text-4xl font-Roboto font-bold text-center text-[#142D3C] mb-3">
          {t("blog-title")}
        </h1>
        <p className="text-lg font-normal text-[#142D3C] opacity-70 text-center">
          {t("blog-subtitle")}
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-10 px-10 py-16 ">
        {data.map((item, index) => {
          return (
            <Link to={`/${item.slug}`} key={index}>
              <div className="group">
                <div className="overflow-hidden">
                  <img src={item.image} alt={item.title} className="mb-4 rounded-lg object-cover h-[200px] w-full transition-all duration-500 transform  group-hover:scale-105" />
                </div>
                <h3 className="text-2xl font-bold text-primary mb-3">{item.title}</h3>
                <p className="text-lg font-normal text-secondary mb-2">
                  {item.description}
                </p>
                <div className="flex gap-2 py-2 items-center">
                  <img src={Clock} alt="" />
                  <span className="text-sm font-normal text-[#556987]">
                    {item.text}
                  </span>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default withTranslation()(Hero);


