import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
const List = ({ data, deleteHandler }) => {
  const calculateRemainingTime = (dateToApply) => {
    const now = new Date();
    const targetDate = new Date(dateToApply);
    const timeDiff = targetDate.getTime() - now.getTime();

    // Calculate the remaining time in hours, minutes, and seconds
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className="w-full">
      <div className="max-w-[1550px] mx-auto px-4 my-12">
        <div className="bg-white rounded-t-lg">
          <h1 className="text-xl font-bold text-primary py-4 px-5 border-b border-b-[#D9D9D9]">
            Applications List
          </h1>
        </div>

        <div className=" py-8 bg-white rounded-b-lg">
          <TableContainer>
            <Table
              className=""
              sx={{ minWidth: 750 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Application Title</TableCell>
                  <TableCell>Application Type</TableCell>
                  <TableCell>Application Number</TableCell>
                  {/* <TableCell>Total Proposals</TableCell> */}
                  <TableCell>Time Left</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item?.title}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.type}
                    </TableCell>
                    <TableCell>{item?._id}</TableCell>
                    {/* <TableCell>{item?.totalProposals || 0}</TableCell> */}
                    <TableCell>
                      {calculateRemainingTime(item?.expiryTime)}
                    </TableCell>
                    <TableCell>
                      <Link to={`/product-and-services/applicants/${item?._id}`}>
                        <Tooltip title="View Applicants" arrow>
                          <IconButton>
                            <RemoveRedEyeIcon className="text-[#1976D2]" />
                          </IconButton>
                        </Tooltip>
                      </Link>
                      <Tooltip title="Delete" arrow>
                        <IconButton onClick={() => deleteHandler(item?._id)}>
                          <DeleteIcon className="text-red-500" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default List;
