import React, { useEffect, useState } from "react";
import axios from "axios";
import MapIcon from "../../assets/mappin.svg";
import ApplicationLogo from "../../assets/logo-74.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const API_URL = "https://fullsetters.com/api/application/";
const ApplicationCard = () => {
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState("");
  const getData = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    setUserId(user?._id);
    const response = await axios.get(API_URL);

    const result = response.data;
    setData(result);
  };
  useEffect(() => {
    getData();
  }, []);

  const createSlug = (title) => {
    const slug = title
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+/g, "-")
      .replace(/(^-|-$)+/g, "");
    return slug;
  };
 
  return (
    <>
      {data?.length > 0 && data?.map((item, index) => {
        const formattedDate = item?.expiryTime?.split("T")[0];
        return (
          <div
            key={index}
            className="max-w-[500px] bg-[#FBFBFB] border border-[#D9D9D9] rounded-lg p-5 mb-4"
          >
            <div className="flex gap-3 mb-4">
              <div>
                <img
                  src={ApplicationLogo}
                  alt=""
                  className="object-cover w-24"
                />
              </div>
              <div>
                <h2 className="mb-2 text-xl font-bold text-primary">
                  {item.type}
                </h2>
                <p className="mb-2 text-sm font-medium text-secondary">
                  500+ Company Size
                </p>
                <div className="flex gap-x-1">
                  <img src={MapIcon} alt="" />
                  <span className="flex self-center text-sm font-normal text-secondary">
                    New York City
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-5">
              <h1 className="flex mb-2 self-center text-xl font-normal text-[#142D3C] opacity-80">
                {item?.title}
              </h1>
              <p className="text-base font-normal text-secondary line-clamp-3 mb-6">
                {item?.description}
              </p>
              <p className="text-base font-normal text-secondary">
                {formattedDate}
              </p>
            </div>
            <div className="flex gap-x-1">
              {item?.createdBy === userId ? (
                <Button
                  variant="contained"
                  size="large"
                  sx={{ backgroundColor: "#3867D6" }}
                  disabled
                >
                  Apply with Fullsetters
                </Button>
              ) : (
                <Link to={`${createSlug(item.type)}/${item._id}`}>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ backgroundColor: "#3867D6" }}
                  >
                    Apply with Fullsetters
                  </Button>
                </Link>
              )}

              <Button variant="text" size="large" sx={{ color: "#87979E" }}>
                Learn More
              </Button>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ApplicationCard;
