import React, { useState, useEffect } from "react";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import ListTable from "./List";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const API_URL = "https://fullsetters.com/api/application";

const ApplicationListPage = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(`${API_URL}/${user._id}/applications`, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });
    if (response?.status === 200) {
      const result = response.data;
      setData(result);
    } else if (response?.status === 404) {
      toast.error(response?.message);
    } else {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const deleteHandler = async (id) => {
    const user = await JSON.parse(localStorage.getItem("user"));
    const response = await axios.delete(`${API_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });
    if (response.status === 200) {
      getData();
      toast.success("Application deleted successfully!");
    }
  };
  return (
    <>
      <ToastContainer />
      <Navbar />
      <ListTable data={data} deleteHandler={deleteHandler} />
      <Footer />
    </>
  );
};

export default ApplicationListPage;
