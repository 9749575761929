import React from "react";
import { useParams } from "react-router-dom";
import Clock from "../../assets/clock.svg";
import { withTranslation, useTranslation } from "react-i18next";
import BlogImage1 from "../../assets/Post-1.jpg";
import BlogImage2 from "../../assets/Post-2.png";
import BlogImage3 from "../../assets/Post-3.png";
import BlogImage4 from "../../assets/Post-4.png";
import BlogImage5 from "../../assets/Post-5.png";
import BlogImage6 from "../../assets/Post-6.png";
import BlogImage7 from "../../assets/Post-7.png";
import BlogImage8 from "../../assets/Post-8.png";
import BlogImage9 from "../../assets/Post-9.png";

const Hero = () => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const data = [
    {
      image: BlogImage1,
      title: t("blog-card1-title"),
      summary: t("blog-1-summary"),
      headingOne: t("blog-1-headingOne"),
      headingTwo: t("blog-1-headingTwo"),
      headingThree: t("blog-1-headingThree"),
      headingFour: t("blog-1-headingFour"),
      headingFive: t("blog-1-headingFive"),
      contentOne: t("blog-1-contentOne"),
      contentTwo: t("blog-1-contentTwo"),
      contentThree: t("blog-1-contentThree"),
      contentFour: t("blog-1-contentFour"),
      contentFive: t("blog-1-contentFive"),
      conclusionHeading: t("blog-1-conclusionheading"),
      conclusionContent: t("blog-1-concluisonContent"),
      slug: "how-entrapreneurship-can-help-you-stand-out-at-work",
    },
    {
      image: BlogImage2,
      summary: t("blog-2-summary"),
      headingOne: t("blog-2-headingOne"),
      headingTwo: t("blog-2-headingTwo"),
      headingThree: t("blog-2-headingThree"),
      headingFour: t("blog-2-headingFour"),
      headingFive: t("blog-2-headingFive"),
      contentOne: t("blog-2-contentOne"),
      contentTwo: t("blog-2-contentTwo"),
      contentThree: t("blog-2-contentThree"),
      contentFour: t("blog-2-contentFour"),
      contentFive: t("blog-2-contentFive"),
      conclusionHeading: t("blog-2-conclusionheading"),
      conclusionContent: t("blog-2-concluisonContent"),
      slug: "discover-ins-and-outs-of-working-multiple-remote-jobs",
    },
    {
      image: BlogImage3,
      title: t("blog-card3-title"),
      summary: t("blog-3-summary"),
      headingOne: t("blog-3-headingOne"),
      headingTwo: t("blog-3-headingTwo"),
      headingThree: t("blog-3-headingThree"),
      headingFour: t("blog-3-headingFour"),
      headingFive: t("blog-3-headingFive"),
      contentOne: t("blog-3-contentOne"),
      contentTwo: t("blog-3-contentTwo"),
      contentThree: t("blog-3-contentThree"),
      contentFour: t("blog-3-contentFour"),
      contentFive: t("blog-3-contentFive"),
      conclusionHeading: t("blog-3-conclusionheading"),
      conclusionContent: t("blog-3-concluisonContent"),
      slug: "how-to-figure-out-if-a-potential-employer-offers-paid-leave",
    },
    {
      image: BlogImage4,
      title: t("blog-card4-title"),
      summary: t("blog-4-summary"),
      headingOne: t("blog-4-headingOne"),
      headingTwo: t("blog-4-headingTwo"),
      headingThree: t("blog-4-headingThree"),
      headingFour: t("blog-4-headingFour"),
      headingFive: t("blog-4-headingFive"),
      headingSix: t("blog-4-headingSix"),
      contentOne: t("blog-4-contentOne"),
      contentTwo: t("blog-4-contentTwo"),
      contentThree: t("blog-4-contentThree"),
      contentFour: t("blog-4-contentFour"),
      contentFive: t("blog-4-contentFive"),
      contentSix: t("blog-4-contentSix"),
      conclusionHeading: t("blog-4-conclusionheading"),
      conclusionContent: t("blog-4-concluisonContent"),
      slug: "how-to-explain-why-youre-right-for-the-job",
    },
    {
      image: BlogImage5,
      title: t("blog-card5-title"),
      summary: t("blog-5-summary"),
      headingOne: t("blog-5-headingOne"),
      headingTwo: t("blog-5-headingTwo"),
      headingThree: t("blog-5-headingThree"),
      headingFour: t("blog-5-headingFour"),
      headingFive: t("blog-5-headingFive"),
      headingSix: t("blog-5-headingSix"),
      contentOne: t("blog-5-contentOne"),
      contentTwo: t("blog-5-contentTwo"),
      contentThree: t("blog-5-contentThree"),
      contentFour: t("blog-5-contentFour"),
      contentFive: t("blog-5-contentFive"),
      contentSix: t("blog-5-contentSix"),
      conclusionHeading: t("blog-5-conclusionheading"),
      conclusionContent: t("blog-5-concluisonContent"),
      slug: "tips-for-shaking-your-job-interview-jitters",
    },
    {
      image: BlogImage6,
      title: t("blog-card6-title"),
      summary: t("blog-6-summary"),
      headingOne: t("blog-6-headingOne"),
      headingTwo: t("blog-6-headingTwo"),
      headingThree: t("blog-6-headingThree"),
      headingFour: t("blog-6-headingFour"),
      headingFive: t("blog-6-headingFive"),
      headingSix: t("blog-6-headingSix"),
      contentOne: t("blog-6-contentOne"),
      contentTwo: t("blog-6-contentTwo"),
      contentThree: t("blog-6-contentThree"),
      contentFour: t("blog-6-contentFour"),
      contentFive: t("blog-6-contentFive"),
      contentSix: t("blog-6-contentSix"),
      conclusionHeading: t("blog-6-conclusionheading"),
      conclusionContent: t("blog-6-concluisonContent"),
      slug: "how-to-know-your-resume-is-ready-to-be-submitted",
    },
    {
      image: BlogImage7,
      title: t("blog-card7-title"),
      summary: t("blog-7-summary"),
      headingOne: t("blog-7-headingOne"),
      headingTwo: t("blog-7-headingTwo"),
      headingThree: t("blog-7-headingThree"),
      headingFour: t("blog-7-headingFour"),
      headingFive: t("blog-7-headingFive"),
      contentOne: t("blog-7-contentOne"),
      contentTwo: t("blog-7-contentTwo"),
      contentThree: t("blog-7-contentThree"),
      contentFour: t("blog-7-contentFour"),
      contentFive: t("blog-7-contentFive"),
      conclusionHeading: t("blog-7-conclusionheading"),
      conclusionContent: t("blog-7-concluisonContent"),
      slug: "how-to-end-a-networking-conversation-tactfully",
    },
    {
      image: BlogImage8,
      title: t("blog-card8-title"),
      summary: t("blog-8-summary"),
      headingOne: t("blog-8-headingOne"),
      headingTwo: t("blog-8-headingTwo"),
      headingThree: t("blog-8-headingThree"),
      headingFour: t("blog-8-headingFour"),
      headingFive: t("blog-8-headingFive"),
      headingSix: t("blog-8-headingSix"),
      contentOne: t("blog-8-contentOne"),
      contentTwo: t("blog-8-contentTwo"),
      contentThree: t("blog-8-contentThree"),
      contentFour: t("blog-8-contentFour"),
      contentSix: t("blog-8-contentSix"),
      conclusionHeading: t("blog-8-conclusionheading"),
      conclusionContent: t("blog-8-concluisonContent"),
      slug: "how-to-follow-up-on-freelance-pitches-without-being-annoying",
    },
    {
      image: BlogImage9,
      title: t("blog-card9-title"),
      summary: t("blog-9-summary"),
      headingOne: t("blog-9-headingOne"),
      headingTwo: t("blog-9-headingTwo"),
      headingThree: t("blog-9-headingThree"),
      headingFour: t("blog-9-headingFour"),
      headingFive: t("blog-9-headingFive"),
      headingSix: t("blog-9-headingSix"),
      contentOne: t("blog-9-contentOne"),
      contentTwo: t("blog-9-contentTwo"),
      contentThree: t("blog-9-contentThree"),
      contentFour: t("blog-9-contentFour"),
      contentFive: t("blog-9-contentFive"),
      contentSix: t("blog-9-contentSix"),
      conclusionHeading: t("blog-9-conclusionheading"),
      conclusionContent: t("blog-9-concluisonContent"),
      slug: "how-working-from-home-can-reduce-stress",
    },
  ];
  const blogImages = {
    "how-entrapreneurship-can-help-you-stand-out-at-work": BlogImage1,
    "discover-ins-and-outs-of-working-multiple-remote-jobs": BlogImage2,
    "how-to-figure-out-if-a-potential-employer-offers-paid-leave": BlogImage3,
    "how-to-explain-why-youre-right-for-the-job": BlogImage4,
    "tips-for-shaking-your-job-interview-jitters": BlogImage5,
    "how-to-know-your-resume-is-ready-to-be-submitted": BlogImage6,
    "how-to-end-a-networking-conversation-tactfully": BlogImage7,
    "how-to-follow-up-on-freelance-pitches-without-being-annoying": BlogImage8,
    "how-working-from-home-can-reduce-stress": BlogImage9,
  };
  const blogPost = data.find((post) => post.slug === slug);
  const blogImage = blogImages[slug];

  if (!blogPost) {
    return <div>Blog post not found</div>;
  }

  return (
    <div className="main-container py-16">
       <div>
        <div className=" md:py-10 mb-4 rounded-lg">
          <img src={blogImage} alt="" />
        </div>
        <div>
          <h1 className="text-xl md:text-[32px] font-bold text-primary py-4 md:py-6">
            {blogPost.title}
          </h1>
          <div className="flex gap-2 items-center">
            <img src={Clock} alt="" />
            <span className="text-sm font-normal text-[#556987]">
              4 days Ago
            </span>
          </div>
          <div className="py-7">
            <p className="text-base max-w-[900px] md:text-lg text-[#556987] font-normal ">
              {blogPost.summary}
            </p>
            <div className="flex flex-col gap-4 py-20">
              <div className="flex flex-col gap-3">
                <p className="text-xl font-medium text-[#142D3X]">
                  1.{blogPost.headingOne}
                </p>
                <p className="text-base text-[#556987] font-normal max-w-[800px]">
                  {blogPost.contentOne}
                </p>
              </div>
              <div className="flex flex-col gap-3">
                <p className="text-xl font-medium text-[#142D3X]">
                  2.{blogPost.headingTwo}
                </p>
                <p className="text-base text-[#556987] font-normal max-w-[800px]">
                  {blogPost.contentTwo}
                </p>
              </div>
              <div className="flex flex-col gap-3">
                <p className="text-xl font-medium text-[#142D3X]">
                  3.{blogPost.headingThree}
                </p>
                <p className="text-base text-[#556987] font-normal max-w-[800px]">
                  {blogPost.contentThree}
                </p>
              </div>
              <div className="flex flex-col gap-3">
                <p className="text-xl font-medium text-[#142D3X]">
                  4.{blogPost.headingFour}
                </p>
                <p className="text-base text-[#556987] font-normal max-w-[800px]">
                  {blogPost.contentFour}
                </p>
              </div>
              <div className="flex flex-col gap-3">
                <p className="text-xl font-medium text-[#142D3X]">
                  5.{blogPost.headingFive}
                </p>
                <p className="text-base text-[#556987] font-normal max-w-[800px]">
                  {blogPost.contentFive}
                </p>
              </div>
              {blogPost.headingSix && blogPost.contentSix && (
                <div className="flex flex-col gap-3">
                  <p className="text-xl font-medium text-[#142D3X]">
                    6.{blogPost.headingSix}:
                  </p>
                  <p className="text-base text-[#556987] font-normal max-w-[800px]">
                    {blogPost.contentSix}
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-xl font-medium text-[#142D3X]">
                {blogPost.conclusionHeading}
              </p>
              <p className="text-base text-[#556987] font-normal max-w-[800px]">
                {blogPost.conclusionContent}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Hero);
