import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translations as enTranslations } from "./locales/en/translations";
import { translations as deTranslations } from "./locales/de/translations";
import { translations as esTranslations } from "./locales/es/translations";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      translations: enTranslations,
    },
    de: {
      translations: deTranslations,
    },
    es: {
      translations: esTranslations,
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "es", "de"];

export default i18n;
