import React from "react";
import Layout from "../Layout";

const PaymentMethods = () => {
  return (
    <Layout>
      <h2>Payment method list</h2>
    </Layout>
  );
};

export default PaymentMethods;
