import axios from "axios";
import Cookies from "universal-cookie";

const API_URL = "https://fullsetters.com/api/admin/";
const cookies = new Cookies();
// Register user
const register = async (userData) => {
  await axios.post(API_URL, userData);
};

// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + "login", userData);
  if (response.data) {
    localStorage.setItem("admin", JSON.stringify(response.data));
    cookies.set("admin", JSON.stringify(response.data), {
      path: "/",
    });
  }

  return response.data;
};

// Logout user
const logout = () => {
  localStorage.removeItem("admin");
  cookies.remove("admin");
};

const authService = {
  register,
  logout,
  login,
};

export default authService;
