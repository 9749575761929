import React, { useState, useEffect } from "react";
import Navbar from "../Layout/Navbar";
import Footer from "../Layout/Footer";
import ChatUserList from "./ChatUserList";
import Chatbox from "./Chat";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectChatUser } from "../MainSlice";

const API_URL = "https://fullsetters.com";

const ChatPage = () => {
  const selectedChatUser = useSelector(selectChatUser);
  const [activeUserId, setActiveUserId] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setActiveUserId(user?._id);
  }, []);

  const sendMessage = async (messageContent, receiverId) => {
    try {
      await axios.post(`${API_URL}/api/chat/send`, {
        senderId: activeUserId,
        receiverId: receiverId,
        message: messageContent,
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="flex justify-between w-full h-[87.5vh]">
        <div className="bg-white h-full">
          <ChatUserList selectedUser={selectedChatUser} />
        </div>
        <div className="w-full">
          {selectedChatUser && (
            <Chatbox
              activeUserId={activeUserId}
              receiverId={selectedChatUser._id}
              receiverName={selectedChatUser?.name.toUpperCase()}
              sendMessage={sendMessage}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChatPage;
