import React, { useState, useEffect } from "react";
import Logo from "../../assets/mainLogo.svg";
import socketIOClient from "socket.io-client";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import ProfileSection from "../common/ProfileSection";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import axios from "axios";
import { withTranslation, useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  selectNotifications,
  setNotifications,
  setSelectedChatUser,
} from "../MainSlice";
import MobileMenu from "./MobileMenu";
const SOCKET_ENDPOINT = "https://fullsetters.com";
const API_URL = "https://fullsetters.com/api/notifications";

function notificationsLabel(count) {
  if (count === 0) {
    return "no notifications";
  }
  if (count > 99) {
    return "more than 99 notifications";
  }
  return `${count} notifications`;
}

const Navbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const notifications = useSelector(selectNotifications);

  const location = useLocation();

  const isOnMasterPage = location.pathname.startsWith("/product-and-services");

  const [helpMenuAnchor, setHelpMenuAnchor] = useState(null);
  const isHelpMenuOpen = Boolean(helpMenuAnchor);
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const socket = socketIOClient(SOCKET_ENDPOINT, {
      path: "/socket.io",
      query: {
        token: user?.token,
      },
    });
    if (socket) {
      socket.on("newMessageNotification", (notification) => {
        const newNotifications = [...notifications, notification];
        dispatch(setNotifications(newNotifications));
      });
    }

    // return () => {
    //   if (socket) {
    //     socket.off("newMessageNotification");
    //   }
    // };
  }, [notifications, dispatch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function changeLanguage(countryCode) {
    let langCode = "en";
    if (countryCode === "ES") {
      langCode = "es";
    } else if (countryCode === "DE") {
      langCode = "de";
    }
    i18n.changeLanguage(langCode);
  }
  const handleHelpMenuOpen = (event) => {
    setHelpMenuAnchor(event.currentTarget);
  };

  const handleHelpMenuClose = () => {
    setHelpMenuAnchor(null);
  };

  const handleScrollToFAQ = () => {
    const faqSection = document.getElementById("FAQ");
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: "smooth" });
    }
    handleHelpMenuClose();
  };
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await axios.get(`${API_URL}/${user._id}`);
        const notificationsData = response.data;
        dispatch(setNotifications(notificationsData));
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [dispatch]);

  const handleNotificationClick = async (notification) => {
    dispatch(
      setSelectedChatUser({
        _id: notification?.fromUserId,
        name: notification?.fromUserName,
      })
    );
    try {
      // Mark the notification as read
      await axios.patch(`${API_URL}/read/${notification._id}`);
      const updatedNotifications = notifications.filter(
        (n) => n._id !== notification._id
      );
      dispatch(setNotifications(updatedNotifications));

      // If there are more notifications from this user, clear them
      const hasMoreNotificationsFromUser = notifications.some(
        (n) => n.fromUserId === notification.fromUserId
      );

      if (hasMoreNotificationsFromUser) {
        await axios.delete(`${API_URL}/clear/${notification.fromUserId}`);
      }

      navigate("/chats");
    } catch (error) {
      console.error("Error handling notification:", error);
    }
  };

  return (
    <>
    <div className="block lg:hidden">
      <MobileMenu />
    </div>
      <div className="w-full bg-white border-b border-b-[#D9D9D9] hidden lg:block">
        <div className="max-w-[1550px] mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/">
            <img src={Logo} alt="Fullsettters" />
          </Link>
          {location.pathname !== "/product-and-services" &&
          location.pathname !== "/product-and-services/" ? (
            <div className="flex items-center lg:gap-5 mt-4 lg:mt-0">
              <Link to="/">
                <Button
                  variant="text"
                  size="large"
                  sx={{
                    color: "#102E3D",
                  }}
                >
                  {t("home")}
                </Button>
              </Link>
              <Link to="/about">
                <Button
                  variant="text"
                  size="large"
                  sx={{
                    color: "#102E3D",
                  }}
                >
                  {t("about")}
                </Button>
              </Link>
              <div>
                <Button
                  variant="text"
                  size="large"
                  sx={{
                    color: "#102E3D",
                  }}
                  onClick={handleHelpMenuOpen}
                  endIcon={<FiChevronDown />}
                >
                  {t("help")}
                </Button>
                <Menu
                  anchorEl={helpMenuAnchor}
                  open={isHelpMenuOpen}
                  onClose={handleHelpMenuClose}
                >
                  <MenuItem onClick={handleScrollToFAQ}>
                    <Link to="/help-center"> {t("faqs")}</Link>
                  </MenuItem>
                  <MenuItem onClick={handleHelpMenuClose}>
                    <Link to="/contact-us">{t("contact")}</Link>
                  </MenuItem>
                  <MenuItem onClick={handleHelpMenuClose}>
                    <Link to="/blogs">{t("blogs")}</Link>
                  </MenuItem>
                  <MenuItem onClick={handleHelpMenuClose}>
                    <Link to="/pricing">{t("pricing")}</Link>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          ) : (
            <div className="flex flex-col lg:flex-row items-center justify-end lg:gap-5 mt-4 lg:mt-0">
              <Link to="/product-and-services/applications">
                <Button
                  variant="text"
                  size="large"
                  sx={{
                    color: "#102E3D",
                  }}
                >
                  {t("my-applications")}
                </Button>
              </Link>
            </div>
          )}

          <div className="flex flex-col lg:flex-row items-center gap-4 mt-4 lg:mt-0">
            {location.pathname === "/branch/demographics" && (
              <Link to="/product-and-services">
                <Button variant="outlined" size="large">
                  {t("switch-to-master-tab")}
                </Button>
              </Link>
            )}
            {isOnMasterPage && (
              <Link to="/product-and-services/new-application">
                <Button variant="outlined" size="large">
                  {t("new-application")}
                </Button>
              </Link>
            )}
            <div className="flex items-center gap-x-10">
              <div className="flex items-center ">
                <IconButton
                  aria-label={notificationsLabel(notifications?.length)}
                  onClick={handleClick}
                >
                  <Badge badgeContent={notifications?.length} color="info">
                    <NotificationsNoneIcon />
                  </Badge>
                </IconButton>
                <span className="cursor-pointer relative">
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    {!notifications.length && (
                      <MenuItem>
                        <Typography variant="body2" color="text.secondary">
                          No New Messages
                        </Typography>
                      </MenuItem>
                    )}

                    {notifications?.length > 0 &&
                      notifications?.map((notif) => (
                        <MenuItem
                          key={notif._id}
                          onClick={() => handleNotificationClick(notif)}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography variant="subtitle1">
                            New Message from {notif?.fromUserName}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {notif?.message}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Menu>
                </span>
                <Link to="/chats">
                  <IconButton>
                    <MailIcon color="#707070" />
                  </IconButton>
                </Link>
                <div className="max-w-[100px] ml-5">
                  <ReactFlagsSelect
                    placeholder="Select Language"
                    countries={["US", "ES", "DE"]} // Add more country codes as needed
                    customLabels={{ US: "US", ES: "ES", DE: "DE" }}
                    selected={
                      i18n.language.toUpperCase() === "EN"
                        ? "US"
                        : i18n.language.toUpperCase()
                    }
                    onSelect={(countryCode) => changeLanguage(countryCode)}
                  />
                </div>
              </div>
              <div className="mb-1.5">
                {user ? (
                  <ProfileSection />
                ) : (
                  <Link to="/login">
                    <Button variant="outlined" size="large">
                      {t("sign-in")}
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withTranslation()(Navbar);
