import React from "react";
import Hero from "./Hero";
import ContentCards from "./ContentCards";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";

const AboutPage = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <ContentCards />
      <Footer />
    </>
  );
};

export default AboutPage;
