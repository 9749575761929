import React from "react";
import Layout from "../../Layout";
import { Link } from "react-router-dom";

const RefundedPaymentDetails = () => {
  return (
    <Layout>
      <div className="bg-white p-6 rounded shadow-md w-2/3 mx-auto mt-12">
        <div className="flex justify-between items-center mb-4">
          <Link to="/billing/payment-history/refunded">
            <button className="text-blue-500">← Back</button>
          </Link>
          <h1 className="text-2xl font-bold">Refunded Payment details</h1>
          <div className="w-20"></div>
        </div>

        <div className="border-t border-b py-4 mb-4">
          <div className="text-gray-500 mb-2">Payment ID: H_2717815</div>
        </div>

        <div className="mb-6">
          <table className="w-full">
            <thead>
              <tr className="border-b text-left">
                <th className="pb-2 w-1/3">Services</th>
                <th className="pb-2 w-1/3">Period</th>
                <th className="pb-2 w-1/3 text-right">Refunded Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="py-2">
                <td>Business Web Hosting (billed every 2 years)</td>
                <td>2023-07-27 - 2025-07-27</td>
                <td className="text-right text-red-500">-$96.98</td>
              </tr>
              <tr className="py-2">
                <td>Daily Backup (billed every 2 years)</td>
                <td>2023-07-27 - 2025-07-27</td>
                <td className="text-right text-red-500">-$0.00</td>
              </tr>
              <tr className="py-2">
                <td>Setup</td>
                <td>2023-07-27 - 2023-07-27</td>
                <td className="text-right text-red-500">-$0.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="border-t py-4">
          <div className="flex justify-between mb-2">
            <span>Total Refunded</span>
            <span className="text-red-500">-$96.98</span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RefundedPaymentDetails;
