import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Button } from "@mui/material";
import { withTranslation, useTranslation } from "react-i18next";

const Employementmodal = ({ isOpen, onClose, employmentData }) => {
  const { t } = useTranslation();

  const [fields, setFields] = useState({
    employeer: "",
    jobTitle: "",
    startingDate: "",
    finishingDate: "",
    streetName: "",
    city: "",
    state: "",
    zipCode: "",
    province: "",
    relationship: "",
    name: "",
    email: "",
    phone: "",
  });
  useEffect(() => {
    setFields(employmentData[0]);
  }, [employmentData]);
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="xl"
    >
      <div>
        <DialogTitle id="alert-dialog-title">Applicant Detail</DialogTitle>
        <div className="max-w-[1550px] mx-auto my-10">
          <div className=" bg-white rounded">
            <div className="p-4">
              <h3 className="mb-4 text-2xl font-bold pt-3 text-primary">
                {t("Work Experience")}
              </h3>
              <h3 className="mb-2 text-xxl font-normal text-secondary">
                {employmentData?.length === 0 && "Please enter your Work Experience"}
              </h3>

              <>
                <div>
                  <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <TextField
                        fullWidth
                        label={t("Employeer")}
                        id="fullWidth"
                        variant="filled"
                        name="employeer"
                        value={fields?.employeer || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("Job Title")}
                        id="fullWidth"
                        variant="filled"
                        name="jobTitle"
                        value={fields?.jobTitle || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("Starting Date")}
                        id="fullWidth"
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        name="startingDate"
                        value={fields?.startingDate || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("Finishing Date")}
                        id="fullWidth"
                        variant="filled"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        name="finishingDate"
                        value={fields?.finishingDate || ""}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="py-5 bg-white">
                    <h2 className="mb-2 text-2xl font-bold text-left text-primary">
                      {t("Work Address")}
                    </h2>
                  </div>
                  <div className="mb-6">
                    <TextField
                      fullWidth
                      label={t("Street name & number")}
                      id="fullWidth"
                      variant="filled"
                      name="streetName"
                      value={fields?.streetName || ""}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <TextField
                        fullWidth
                        label={t("City")}
                        id="fullWidth"
                        variant="filled"
                        name="city"
                        value={fields?.city || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("State")}
                        id="fullWidth"
                        variant="filled"
                        name="state"
                        value={fields?.state || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("ZIP Code")}
                        id="fullWidth"
                        variant="filled"
                        name="zipCode"
                        value={fields?.zipCode || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("Province")}
                        id="fullWidth"
                        variant="filled"
                        name="province"
                        value={fields?.province || ""}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="py-5 bg-white">
                    <h2 className="mb-2 text-2xl font-bold text-left text-primary">
                      {t("Contact Person")}
                    </h2>
                  </div>

                  <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <TextField
                        fullWidth
                        label={t("Relationship")}
                        id="fullWidth"
                        variant="filled"
                        name="relationship"
                        value={fields?.relationship || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("Name")}
                        id="fullWidth"
                        variant="filled"
                        name="name"
                        value={fields?.name || ""}
                      />
                    </div>

                    <div>
                      <TextField
                        fullWidth
                        label={t("Email")}
                        id="fullWidth"
                        variant="filled"
                        name="email"
                        value={fields?.email || ""}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={t("Phone")}
                        id="fullWidth"
                        variant="filled"
                        name="phone"
                        value={fields?.phone || ""}
                      />
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>

        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default withTranslation()(Employementmodal);
