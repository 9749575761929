import React, { Suspense, lazy } from "react";
import SuspenseLoader from "./components/common/SuspenseLoader";
import { Route, Routes, useLocation } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import Contactus from "./pages/ContactUs";
import TermsandServices from "./pages/Terms and Services";
import PrivacyPolicyPage from "./pages/PrivacyAndPolicy";
import Helpcenter from "./pages/HelpCenter";
import BlogPost from "./pages/BlogPost";
import BlogDetails from "./pages/BlogDetails";
import Pricing from "./pages/Pricing";
import MasterPage from "./pages/Master";
import EmploymentApplication from "./pages/Applications/Employment";
import Success from "./pages/Success";
import AdminLogin from "./pages/Admin/AdminLogin";
import AdminSingup from "./pages/Admin/AdminSingup";

import ProtectedRoute from "./protectedRoute";
import "./App.css";
import ProtectedAdminRoute from "./ProtectedAdminRoute";
import RentalApplication from "./pages/Applications/Rental";
import SchoolApplicationPage from "./pages/Applications/School";
import LoanApplicationPage from "./pages/Applications/Loan";
import ImmigrationApplicationPage from "./pages/Applications/Immigration";
import JobApplicationPage from "./pages/Applications/Job";
import NewApplicationPage from "./pages/PostNewApplication";
import ApplicationListPage from "./pages/ApplicationList";
import UserDetails from "./pages/Admin/Dashboard/UserDetails";
import IdVerification from "./pages/Admin/Dashboard/IdVerification";
import Payments from "./pages/Admin/Dashboard/Payments";
import History from "./pages/Admin/Dashboard/History";
import Application from "./pages/Admin/Dashboard/Application";
import ChartInfo from "./pages/Admin/Dashboard/ChartInfo";
import AccountSettingsForm from "./pages/AccountSettings/SettingsForm";
import UsersTable from "./pages/Admin/Dashboard/UsersTable";
import CookiesPolicyBanner from "./components/common/CookiesPolicyBanner";
import { ScrollToTop } from "./components/Layout/ScrollToTop";
import Copyright from "./pages/Copyright/Copyright";
import Subscriptions from "./pages/Billing/Subscriptions";
import PaidHistory from "./pages/Billing/PaymentHistory/Paid";
import RefundedHistory from "./pages/Billing/PaymentHistory/Refunded";
import PaymentDetail from "./pages/Billing/PaymentHistory/Paid/PaymentDetail";
import RefundedPaymentDetails from "./pages/Billing/PaymentHistory/Refunded/RefundedPaymentDetails";
import PaymentMethods from "./pages/Billing/PaymentMethods";
import PaymentMethodDetail from "./pages/Billing/PaymentMethods/PaymentMethodDetail";
import Chatpage from "./components/Chat";
import Applicants from "./pages/Applicants";
import Verify from "./components/member-auth/Verify";

// import ScrollToTop from "./components/ScrollToTop";
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loader(lazy(() => import("./components/member-auth/Login")));
const Signup = Loader(lazy(() => import("./components/member-auth/Signup")));
const ForgetPassword = Loader(
  lazy(() => import("./components/member-auth/ForgetPassword"))
);
const UpdatePassword = Loader(
  lazy(() => import("./components/member-auth/UpdatePassword"))
);
const Demographics = Loader(lazy(() => import("./pages/Branch/Demographics")));
const Education = Loader(lazy(() => import("./pages/Branch/Education")));
const Experiences = Loader(lazy(() => import("./pages/Branch/Experiences")));
const Employement = Loader(lazy(() => import("./pages/Branch/Employement")));
const Skills = Loader(lazy(() => import("./pages/Branch/Skills")));
const References = Loader(lazy(() => import("./pages/Branch/References")));
const Certificates = Loader(lazy(() => import("./pages/Branch/Certificates")));
const Others = Loader(lazy(() => import("./pages/Branch/Others")));
const Residential = Loader(lazy(() => import("./pages/Branch/Residential")));
const Resume = Loader(lazy(() => import("./pages/Branch/Resume")));

// While navigation
function ScrollToTopWhileNavigation() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <CookiesPolicyBanner />
      <ScrollToTopWhileNavigation />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about/" element={<About />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/terms-of-services" element={<TermsandServices />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/copyright" element={<Copyright />} />
        <Route path="/help-center" element={<Helpcenter />} />
        <Route path="/blogs" element={<BlogPost />} />
        <Route path="/blog/:slug" element={<BlogDetails />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/billing/subscriptions" element={<Subscriptions />} />
        <Route path="/billing/payment-history/paid" element={<PaidHistory />} />
        <Route
          path="/billing/payment-history/paid/:id"
          element={<PaymentDetail />}
        />
        <Route
          path="/billing/payment-history/refunded"
          element={<RefundedHistory />}
        />
        <Route
          path="/billing/payment-history/refunded/:id"
          element={<RefundedPaymentDetails />}
        />
        <Route path="/billing/payment-methods" element={<PaymentMethods />} />
        <Route
          path="/billing/payment-methods/:id"
          element={<PaymentMethodDetail />}
        />
        <Route path="/account-settings" element={<AccountSettingsForm />} />
        <Route path="/login/" element={<Login />} />
        <Route path="/signup/" element={<Signup />} />
        <Route path="/verify/:token" element={<Verify />} />
        <Route path="/forgetpassword/" element={<ForgetPassword />} />
        <Route path="/updatepassword/" element={<UpdatePassword />} />
        <Route path="/admin/login/" element={<AdminLogin />} />
        <Route path="/admin/signup/" element={<AdminSingup />} />
        <Route
          path="/admin"
          element={<ProtectedAdminRoute component={<ChartInfo />} />}
        />
        <Route
          path="/admin/dashboard/users"
          element={<ProtectedAdminRoute component={<UsersTable />} />}
        />
        <Route
          path="/admin/dashboard/idverification"
          element={<ProtectedAdminRoute component={<IdVerification />} />}
        />
        <Route
          path="/admin/dashboard/payments"
          element={<ProtectedAdminRoute component={<Payments />} />}
        />
        <Route
          path="/admin/dashboard/history"
          element={<ProtectedAdminRoute component={<History />} />}
        />
        <Route
          path="/admin/dashboard/application"
          element={<ProtectedAdminRoute component={<Application />} />}
        />
        <Route
          path="/admin/dashboard/user-details"
          element={<ProtectedAdminRoute component={<UserDetails />} />}
        />
        <Route
          path="/branch/demographics"
          element={<ProtectedRoute component={<Demographics />} />}
        />
        <Route
          path="/branch/education"
          element={<ProtectedRoute component={<Education />} />}
        />
        <Route
          path="/branch/experiences"
          element={<ProtectedRoute component={<Experiences />} />}
        />
        <Route
          path="/branch/employement"
          element={<ProtectedRoute component={<Employement />} />}
        />
        <Route
          path="/branch/skills"
          element={<ProtectedRoute component={<Skills />} />}
        />
        <Route
          path="/branch/references"
          element={<ProtectedRoute component={<References />} />}
        />
        <Route
          path="/branch/certificates"
          element={<ProtectedRoute component={<Certificates />} />}
        />
        <Route
          path="/branch/others"
          element={<ProtectedRoute component={<Others />} />}
        />
        <Route
          path="/branch/residential"
          element={<ProtectedRoute component={<Residential />} />}
        />
        <Route
          path="/branch/resume"
          element={<ProtectedRoute component={<Resume />} />}
        />

        <Route
          path="/product-and-services/"
          element={<ProtectedRoute component={<MasterPage />} />}
        />
        <Route
          path="/product-and-services/employment/:id"
          element={<ProtectedRoute component={<EmploymentApplication />} />}
        />
        <Route
          path="/product-and-services/rental/:id"
          element={<ProtectedRoute component={<RentalApplication />} />}
        />
        <Route
          path="/product-and-services/school/:id"
          element={<ProtectedRoute component={<SchoolApplicationPage />} />}
        />
        <Route
          path="/product-and-services/loan/:id"
          element={<ProtectedRoute component={<LoanApplicationPage />} />}
        />
        <Route
          path="/product-and-services/immigration/:id"
          element={
            <ProtectedRoute component={<ImmigrationApplicationPage />} />
          }
        />
        <Route
          path="/product-and-services/job/:id"
          element={<ProtectedRoute component={<JobApplicationPage />} />}
        />
        <Route
          path="/product-and-services/new-application"
          element={<ProtectedRoute component={<NewApplicationPage />} />}
        />
        <Route
          path="/product-and-services/applications/"
          element={<ProtectedRoute component={<ApplicationListPage />} />}
        />
        <Route
          path="/product-and-services/applicants/:applicationId"
          element={<ProtectedRoute component={<Applicants />} />}
        />
        <Route
          path="/success/"
          element={<ProtectedRoute component={<Success />} />}
        />
        <Route
          path="/chats/"
          element={<ProtectedRoute component={<Chatpage />} />}
        />
        {/* <Route
          path="/inbox/:id"
          element={<ProtectedRoute component={<Inbox />} />}
        /> */}
      </Routes>
    </>
  );
}

export default App;
