import { useEffect, useState } from "react";
import { BiChat } from "react-icons/bi";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  // const [reachedFooter, setReachedFooter] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    // window.addEventListener('scroll', toggleColor);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
      // window.removeEventListener('scroll', toggleColor);
    };
  }, []);

  return (
    <div className="fixed bottom-4 right-4 z-[1000]">
      <Link to="/chats">
        <button
          type="button"
          id="scroll-to-top"
          aria-label="Scroll to top"
          className={classNames(
            isVisible ? "opacity-100" : "opacity-0",
            "inline-flex items-center rounded-full bg-[#3867D6] p-3 text-white shadow-sm transition-opacity hover:bg-blue-biolet focus:outline-none"
          )}
        >
          <BiChat className="h-6 w-6" aria-hidden="true" />
        </button>
      </Link>
    </div>
  );
};
