import React, { useEffect, useState } from "react";
import axios from "axios";
import socketIOClient from "socket.io-client";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNotifications,
  setNotifications,
  setSelectedChatUser,
} from "../MainSlice";

const API_URL = "https://fullsetters.com/api/chat";
const NOTIFY_API_URL = "https://fullsetters.com/api/notifications";
const SOCKET_ENDPOINT = "https://fullsetters.com";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ChatUserList = ({ selectedUser }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const [value, setValue] = useState(0);
  const [chatUsers, setChatUsers] = useState([]);
  const [newApplicants, setNewApplicants] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      const posterId = user._id;
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/chat-users/${posterId}`);
        setChatUsers(response.data.chatUsers);
        setNewApplicants(response.data.newApplicants);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
      setLoading(false);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    // Initilizing should must be in useEffect hook
    const socket = socketIOClient(SOCKET_ENDPOINT, {
       path: "/socket.io",
      query: {
        token: user?.token,
      },
    });
    socket.on("newApplicant", (data) => {
      if (data.applicationId) {
        setNewApplicants((prevUsers) => {
          // Extract user details from the event payload
          const newUser = data.userDetails;

          // Add the new user to the list
          return prevUsers.some((u) => u._id === newUser._id)
            ? prevUsers
            : [newUser, ...prevUsers];
        });
      }
    });

    return () => {
      // socket.disconnect();
      socket.off("newApplicant");
    };
  }, []);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const socket = socketIOClient(SOCKET_ENDPOINT, {
      path: "/socket.io",
      query: { token: user?.token },
    });

    socket.on("newMessage", (data) => {
      const { newMessage } = data;

      if (newMessage.sender !== selectedUser?._id) {
        toast.info(
          `New message from ${newMessage.senderName}: ${newMessage.message}`
        );
      }
      setChatUsers((prevUsers) => {
        const updatedUsers = prevUsers.filter(
          (u) => u._id !== newMessage.sender
        );
        const senderUser = prevUsers.find((u) => u._id === newMessage.sender);
        if (senderUser) {
          return [senderUser, ...updatedUsers];
        }
        return [
          { _id: newMessage.sender, name: newMessage.senderName },
          ...prevUsers,
        ];
      });
    });

    socket.on("newMessageNotification", (notification) => {
      const newNotifications = [...notifications, notification];
      dispatch(setNotifications(newNotifications));
    });

    return () => {
      socket.disconnect();
      // socket.off("newApplicant");
    };
  }, [dispatch, notifications, selectedUser?._id]);

  if (loading) {
    return <CircularProgress />;
  }
  const handleSelectUser = async (userId, userName) => {
    dispatch(setSelectedChatUser({ _id: userId, name: userName }));

    // Check if there are notifications for the selected user
    const userNotificationsExist = notifications.some(
      (notification) => notification.fromUserId === userId
    );

    if (userNotificationsExist) {
      try {
        // Clear notifications related to the selected user from the backend
        await axios.delete(`${NOTIFY_API_URL}/clear/${userId}`);

        // Clear notifications related to the selected user from the frontend state
        const updatedNotifications = notifications.filter(
          (notification) => notification.fromUserId !== userId
        );
        dispatch(setNotifications(updatedNotifications));
      } catch (error) {
        console.error("Error clearing notifications:", error);
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const renderUserListItem = (user) => {
    const lastMessage = user.latestMessage || "No messages yet";

    return (
      <ListItem
        key={user._id}
        alignItems="flex-start"
        button
        onClick={() => handleSelectUser(user._id, user.name)}
        style={{
          backgroundColor:
            user._id === selectedUser?._id ? "#f0f0f0" : "transparent",
          // fontWeight: isUnread ? "bold" : "normal",
        }}
      >
        <ListItemAvatar>
          <Avatar alt={user.name} src="/static/images/avatar/1.jpg" />{" "}
        </ListItemAvatar>
        <ListItemText
          primary={user.name}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {lastMessage}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    );
  };

  return (
    <div className="max-w-[350px] min-w-[350px] bg-white">
      <ToastContainer />
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Chat Users" {...a11yProps(0)} />
        <Tab label="New Users" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <List sx={{ bgcolor: "background.paper" }}>
          {chatUsers.map(renderUserListItem)}
        </List>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <List sx={{ bgcolor: "background.paper" }}>
          {newApplicants.map(renderUserListItem)}
        </List>
      </CustomTabPanel>
    </div>
  );
};

export default ChatUserList;
