import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const DemographicModal = ({
  isOpen,
  onClose,
  rentalData,
  demographicsData,
  referenceData,
  educationHistoryData,
  experienceData,
}) => {
  const { t } = useTranslation();

  const [demoGraphicsData] = useState(demographicsData);
  const [residentialData] = useState(rentalData);
  const [educationalData] = useState(educationHistoryData);
  const [referencesData] = useState(referenceData);
  const [employmentData] = useState(experienceData);

  const [demoFields, setDemoFields] = useState({
    prefix: "",
    sufix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    otherName: "",
    emailAddress: "",
    phoneNumber: "",
    dateofBirth: "",
    gender: "",
    ethnicity: "",
    race: "",
    activeMilitary: "",
    disabledVeteran: "",
    workPhone: "",
    homePhone: "",
    cellPhone: "",
    contactEmail: "",
    residentialStreetName: "",
    residentialAddress2: "",
    residentialCity: "",
    residentialState: "",
    residentialZipCode: "",
    residentialCountry: "",
    billingStreetName: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingCountry: "",
    billingZipCode: "",
    shippingStreetName: "",
    shippingAddress2: "",
    shippingCity: "",
    shippingState: "",
    shippingCountry: "",
    shippingZipCode: "",
  });
  const [residentialFields, setResidentialFields] = useState({
    streetName: "",
    city: "",
    state: "",
    zipCode: "",
    province: "",
  });

  const [educationalFields, setEducationalFields] = useState({
    schoolName: "",
    degree: "",
    streetName: "",
    city: "",
    state: "",
    zipCode: "",
    province: "",
    major: "",
    minor: "",
    startingDate: "",
    finishingDate: "",
    relationship: "",
    name: "",
    email: "",
    phone: "",
  });
  const [referencesfields, setReferencesfields] = useState({
    relationship: "",
    name: "",
    email: "",
    phone: "",
    streetName: "",
    city: "",
    state: "",
    zipCode: "",
    province: "",
  });
  const [employmentfields, setEmploymentfields] = useState({
    employeer: "",
    jobTitle: "",
    startingDate: "",
    finishingDate: "",
    streetName: "",
    city: "",
    state: "",
    zipCode: "",
    province: "",
    relationship: "",
    name: "",
    email: "",
    phone: "",
  });
  useEffect(() => {
    setDemoFields(demoGraphicsData);
    setResidentialFields(residentialData[0]);
    setEducationalFields(educationalData[0]);
    setReferencesfields(referencesData[0]);
    setEmploymentfields(employmentData[0]);
  }, [
    demoGraphicsData,
    educationalData,
    employmentData,
    referencesData,
    residentialData,
  ]);
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"xl"}
    >
      <h2 className="text-center text-2xl font-bold  text-primary pt-10 pb-10">
        Applicant Detail
      </h2>
      <>
        <div className="bg-white px-5 pb-4 pt-4">
          <h2 className="text-left text-2xl font-bold  text-primary ">
            {t("Demographics")}
          </h2>
        </div>

        <div className="rounded-b-lg bg-white px-5 pb-8 ">
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <FormControl fullWidth variant="filled">
                <InputLabel id="prefix">{t("Prefix")}</InputLabel>
                <Select
                  labelId="prefix"
                  id="prefix-select"
                  name="prefix"
                  label="prefix"
                  value={demoFields.prefix || ""}
                >
                  <MenuItem value={"mr"}>Mr.</MenuItem>
                  <MenuItem value={"mrs"}>Mrs.</MenuItem>
                  <MenuItem value={"miss"}>Miss.</MenuItem>
                  <MenuItem value={"dr"}>Dr.</MenuItem>
                  <MenuItem value={"prof"}>Prof.</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth variant="filled">
                <InputLabel id="sufix">{t("Suffix")}</InputLabel>
                <Select
                  labelId="sufix"
                  name="sufix"
                  id="sufix-select"
                  label="sufix"
                  value={demoFields?.sufix || ""}
                >
                  <MenuItem value={"I"}>I</MenuItem>
                  <MenuItem value={"II"}>II</MenuItem>
                  <MenuItem value={"III"}>III</MenuItem>
                  <MenuItem value={"IV"}>IV</MenuItem>
                  <MenuItem value={"V"}>V</MenuItem>
                  <MenuItem value={"sr"}>Sr.</MenuItem>
                  <MenuItem value={"jr"}>Jr.</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("First Name")}
                id="First-Name"
                name="firstName"
                value={demoFields.firstName || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Middle Name")}
                id="Middle-Name"
                name="middleName"
                value={demoFields.middleName || ""}
              />
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Last Name")}
                id="Last-Name"
                name="lastName"
                value={demoFields.lastName || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Other Names")}
                id="other-Name"
                name="otherName"
                value={demoFields.otherName || ""}
              />
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Email Address")}
                id="Email-address"
                name="emailAddress"
                value={demoFields.emailAddress || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Phone Number")}
                id="Phone-number"
                name="phoneNumber"
                value={demoFields.phoneNumber || ""}
              />
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Date of Birth")}
                id="Date-of-birth"
                InputLabelProps={{ shrink: true }}
                type="date"
                name="dateofBirth"
                value={formatDate(demoFields.dateofBirth) || ""}
              />
            </div>
            <div>
              <FormControl fullWidth variant="filled">
                <InputLabel id="gender">{t("Gender")}</InputLabel>
                <Select
                  labelId="gender"
                  id="gender-select"
                  name="gender"
                  label="gender"
                  value={demoFields.gender || ""}
                >
                  <MenuItem value={"male"}>{t("Male")}</MenuItem>
                  <MenuItem value={"female"}>{t("Female")}</MenuItem>
                  <MenuItem value={"other"}>{t("Other")}</MenuItem>
                  <MenuItem value={"Decline To Answer"}>
                    {t("Decline To Answer")}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <FormControl fullWidth variant="filled">
                <InputLabel id="ethnicity">{t("Ethnicity")}</InputLabel>
                <Select
                  labelId="ethnicity"
                  id="ethnicity-select"
                  name="ethnicity"
                  label="ethnicity"
                  value={demoFields.ethnicity || ""}
                >
                  <MenuItem value={"Hispanic or Latino"}>
                    {t("Hispanic or Latino")}
                  </MenuItem>
                  <MenuItem value={"Not Hispanic or Latino"}>
                    {t("Not Hispanic or Latino")}
                  </MenuItem>
                  <MenuItem value={"Other"}>{t("Other")}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth variant="filled">
                <InputLabel id="race">{t("Race")}</InputLabel>
                <Select
                  variant="filled"
                  labelId="race"
                  id="race-select"
                  label="race"
                  name="race"
                  value={demoFields.race || ""}
                >
                  <MenuItem value={"American Indian or Alaska Native"}>
                    {t("American Indian or Alaska Native")}
                  </MenuItem>
                  <MenuItem value={"Asian"}>{t("Asian")}</MenuItem>
                  <MenuItem value={"Black or African American"}>
                    {t("Black or African American")}
                  </MenuItem>
                  <MenuItem value={"Native Hawaiian or Pacific Islander"}>
                    {t("Native Hawaiian or Pacific Islander")}
                  </MenuItem>
                  <MenuItem value={"White"}>{t("White")}</MenuItem>
                  <MenuItem value={"Other Race"}>{t("Other Race")}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <FormControl fullWidth variant="filled">
                <InputLabel id="activeMilitary">
                  {t("Active Military?")}
                </InputLabel>
                <Select
                  labelId="activeMilitary"
                  name="activeMilitary"
                  id="activeMilitary-select"
                  label="activeMilitary"
                  value={demoFields.activeMilitary || ""}
                >
                  <MenuItem value={"yes"}>{t("Yes")}</MenuItem>
                  <MenuItem value={"no"}>{t("No")}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth variant="filled">
                <InputLabel id="disabledVeteran">
                  {t("Are you a disabled Veteran?")}
                </InputLabel>
                <Select
                  labelId="disabledVeteran"
                  name="disabledVeteran"
                  id="disabledVeteran-select"
                  label="disabledVeteran"
                  value={demoFields.disabledVeteran || ""}
                >
                  <MenuItem value={"yes"}>{t("Yes")}</MenuItem>
                  <MenuItem value={"no"}>{t("No")}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="bg-white py-5">
            <h2 className="mb-2 text-left text-2xl font-bold text-primary">
              {t("Contact info")}
            </h2>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Work Phone")}
                id="work-phone"
                name="workPhone"
                value={demoFields.workPhone || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Home Phone")}
                id="home-phone"
                name="homePhone"
                value={demoFields.homePhone || ""}
              />
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Cell Phone")}
                id="cell-phone"
                name="cellPhone"
                value={demoFields.cellPhone || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Email")}
                id="contact-email"
                name="contactEmail"
                value={demoFields.contactEmail || ""}
              />
            </div>
          </div>
          <div className="bg-white py-5">
            <h2 className="mb-2 text-left text-2xl font-bold text-primary">
              {t("Residential Address")}
            </h2>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6">
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Street name & number")}
                id="residential-street-name-number"
                name="residentialStreetName"
                value={demoFields.residentialStreetName || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Address 2 (Optional)")}
                id="residential-address2"
                name="residentialAddress2"
                value={demoFields.residentialAddress2 || ""}
              />
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("City")}
                id="residential-city"
                name="residentialCity"
                value={demoFields.residentialCity || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("State")}
                id="residentialState"
                name="residentialState"
                value={demoFields.residentialState || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("ZIP Code")}
                id="residentialZipCode"
                name="residentialZipCode"
                value={demoFields.residentialZipCode || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Country")}
                id="residentialCountry"
                name="residentialCountry"
                value={demoFields.residentialCountry || ""}
              />
            </div>
          </div>
          <div className="py-5 bg-white">
            <h2 className="mb-2 text-2xl font-bold text-left text-primary">
              {t("Shipping Address")}
            </h2>
          </div>
          <div className="grid grid-cols-1 gap-6 mb-6">
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Street name & number")}
                id="shipping-street-name-number"
                name="shippingStreetName"
                value={demoFields.shippingStreetName || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Address 2 (Optional)")}
                id="shippingAddress2"
                name="shippingAddress2"
                value={demoFields.shippingAddress2 || ""}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("City")}
                id="shippingCity"
                name="shippingCity"
                value={demoFields.shippingCity || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("State")}
                id="shippingState"
                name="shippingState"
                value={demoFields.shippingState || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("ZIP Code")}
                id="shippingZipCode"
                name="shippingZipCode"
                value={demoFields.shippingZipCode || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Country")}
                id="shippingCountry"
                name="shippingCountry"
                value={demoFields.shippingCountry || ""}
              />
            </div>
          </div>
          <div className="py-5 bg-white">
            <h2 className="mb-2 text-2xl font-bold text-left text-primary">
              {t("Billing Address")}
            </h2>
          </div>
          <div className="grid grid-cols-1 gap-6 mb-6">
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Street name & number")}
                id="billing-street-name-number"
                name="billingStreetName"
                value={demoFields.billingStreetName || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Address 2 (Optional)")}
                id="billingAddress2"
                name="billingAddress2"
                value={demoFields.billingAddress2 || ""}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("City")}
                id="billingCity"
                name="billingCity"
                value={demoFields.billingCity || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("State")}
                id="billingState"
                name="billingState"
                value={demoFields.billingState || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("ZIP Code")}
                id="billingZipCode"
                name="billingZipCode"
                value={demoFields.billingZipCode || ""}
              />
            </div>
            <div>
              <TextField
                variant="filled"
                fullWidth
                label={t("Country")}
                id="billingCountry"
                name="billingCountry"
                value={demoFields.billingCountry || ""}
              />
            </div>
          </div>
          <div>
            <div className="bg-white pt-5">
              <h2 className=" text-left text-2xl font-bold text-primary">
                {t("Work Experience")}
              </h2>
            </div>
            <div>
              <div>
                <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label={t("Employeer")}
                      id="fullWidth"
                      name="employeer"
                      value={employmentfields?.employeer || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label={t("Job Title")}
                      id="fullWidth"
                      name="jobTitle"
                      value={employmentfields?.jobTitle || ""}
                    />
                  </div>
                </div>
                <div className="mb-6">
                  <TextField
                    variant="filled"
                    fullWidth
                    label={t("Street name & number")}
                    id="fullWidth"
                    name="streetName"
                    value={employmentfields?.streetName || ""}
                  />
                </div>
                <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label={t("Starting Date")}
                      id="fullWidth"
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      name="startingDate"
                      value={employmentfields?.startingDate || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label={t("Finishing Date")}
                      id="fullWidth"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      name="finishingDate"
                      value={employmentfields?.finishingDate || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white py-5">
            <h2 className="mb-2 text-left text-2xl font-bold text-primary">
              {t("Education History")}
            </h2>
          </div>
          <>
            <div>
              <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
                <div>
                  <TextField
                    variant="filled"
                    fullWidth
                    label={t("School Name")}
                    id="fullWidth"
                    name="schoolName"
                    value={educationalFields?.schoolName || ""}
                  />
                </div>
                <div>
                  <TextField
                    variant="filled"
                    fullWidth
                    label={t("Degree")}
                    id="fullWidth"
                    name="degree"
                    value={educationalFields?.degree || ""}
                  />
                </div>
                <div>
                  <TextField
                    variant="filled"
                    fullWidth
                    label={t("Major")}
                    id="fullWidth"
                    name="major"
                    value={educationalFields?.major || ""}
                  />
                </div>
                <div>
                  <TextField
                    variant="filled"
                    fullWidth
                    label={t("Minor")}
                    id="fullWidth"
                    name="minor"
                    value={educationalFields?.minor || ""}
                  />
                </div>
                <div>
                  <TextField
                    variant="filled"
                    fullWidth
                    label={t("Starting Date")}
                    id="fullWidth"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    name="startingDate"
                    value={educationalFields?.startingDate || ""}
                  />
                </div>
                <div>
                  <TextField
                    variant="filled"
                    fullWidth
                    label={t("Finishing Date")}
                    id="fullWidth"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    name="finishingDate"
                    value={educationalFields?.finishingDate || ""}
                  />
                </div>
              </div>
            </div>
          </>
          <div className="bg-white py-5">
            <h2 className="mb-2 text-left text-2xl font-bold text-primary">
              {t("References")}
            </h2>
          </div>
          <>
            <>
              <div>
                <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label={t("Relationship")}
                      id="fullWidth"
                      name="relationship"
                      value={referencesfields?.relationship || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label={t("Name")}
                      id="fullWidth"
                      name="name"
                      value={referencesfields?.name || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label={t("Email Address")}
                      id="fullWidth"
                      name="email"
                      value={referencesfields?.email || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label={t("Phone Number")}
                      id="fullWidth"
                      name="phone"
                      value={referencesfields?.phone || ""}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label={t("Street name & number")}
                      id="fullWidth"
                      name="streetName"
                      value={referencesfields?.streetName || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label={t("City")}
                      id="fullWidth"
                      name="city"
                      value={referencesfields?.city || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label={t("State")}
                      id="fullWidth"
                      name="state"
                      value={referencesfields?.state || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label={t("ZIP Code")}
                      id="fullWidth"
                      name="zipCode"
                      value={referencesfields?.zipCode || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label={t("Province")}
                      id="fullWidth"
                      name="province"
                      value={referencesfields?.province || ""}
                    />
                  </div>
                </div>
              </div>
            </>
          </>
          <>
            <div className="bg-white py-5">
              <h2 className="mb-2 text-left text-2xl font-bold text-primary">
                {t("Residential History")}
              </h2>
            </div>

            <div className="mb-6">
              <TextField
                variant="filled"
                fullWidth
                label={t("Street name & number")}
                id="fullWidth"
                name="streetName"
                value={residentialFields.streetName || ""}
              />
            </div>
            <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
              <div>
                <TextField
                  variant="filled"
                  fullWidth
                  label={t("City")}
                  id="fullWidth"
                  name="city"
                  value={residentialFields.city || ""}
                />
              </div>
              <div>
                <TextField
                  variant="filled"
                  fullWidth
                  label={t("State")}
                  id="fullWidth"
                  name="state"
                  value={residentialFields.state || ""}
                />
              </div>
            </div>

            <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
              <div>
                <TextField
                  variant="filled"
                  fullWidth
                  label={t("ZIP Code")}
                  id="fullWidth"
                  name="zipCode"
                  value={residentialFields.zipCode || ""}
                />
              </div>
              <div>
                <TextField
                  variant="filled"
                  fullWidth
                  label={t("Province")}
                  id="fullWidth"
                  name="province"
                  value={residentialFields.province || ""}
                />
              </div>
            </div>
          </>
        </div>
      </>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DemographicModal;
