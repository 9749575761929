/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import TableList from "./List";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Employementmodal from "../../components/Employementmodal";
import DemographicModal from "../../components/DemographicModal";
import Rentalmodal from "../../components/Rentalmodal";

const APP_API_URL = "https://fullsetters.com/api/application";

const Applicants = () => {
  const { applicationId } = useParams();
  const [rentalData, setRentalData] = useState([]);
  const [demographicsData, setDemographicsData] = useState([]);
  const [referencesData, setReferencesData] = useState([]);
  const [educationHistoryData, setEducationHistoryData] = useState([]);
  const [employmentData, setEmploymentData] = useState([]);
  const [experienceData, setExperienceData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const getData = async () => {
    // const user = await JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(
      APP_API_URL + "/applicants/" + applicationId
    );
    if (response?.status === 200) {
      const result = response.data;
      setData(result);
    } else {
      toast.error("Something went wrong!");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleDelete = async (applicantId) => {
    try {
      const response = await axios.delete(
        `${APP_API_URL}/${applicationId}/applicants/${applicantId}`
      );

      if (response.status === 200) {
        const updatedData = data.filter(
          (applicant) => applicant.user._id !== applicantId
        );
        setData(updatedData);
        toast.success("Applicant deleted successfully");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error deleting applicant. Please try again.");
    }
  };
  const handleview = async (applicantId) => {
    try {
      const response = await axios.get(
        `${APP_API_URL}/${applicationId}/applicants/${applicantId}`
      );
      const applicationType = response?.data?.applicationType;
      setEducationHistoryData(response?.data?.applicant?.selectedRecords?.education);
      setReferencesData(response?.data?.applicant?.selectedRecords?.references);
      setDemographicsData(response?.data?.applicant?.selectedRecords?.demographics);
      setRentalData(response?.data?.applicant?.selectedRecords?.rental);
      setEmploymentData(response?.data?.applicant?.selectedRecords?.employment);
      setExperienceData(response?.data?.applicant?.selectedRecords?.experiences);

      // Open the corresponding modal based on the application type
      if (
        applicationType === "School" ||
        applicationType === "Immigration" ||
        applicationType === "Job" ||
        applicationType === "Loan"
      ) {
        setIsModalOpen("demographic");
      } else if (applicationType === "Rental") {
        setIsModalOpen("rental");
      } else if (applicationType === "Employment") {
        setIsModalOpen("employment");
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error(error);
      toast.error("PError fetching application detail. Please try again.");
    }
  };

  return (
    <>
      <Navbar />
      <ToastContainer />
      <TableList
        data={data}
        handleDelete={handleDelete}
        handleview={handleview}
      />
      {isModalOpen === "demographic" && (
        <DemographicModal
          isOpen={true}
          onClose={() => setIsModalOpen(false)}
          rentalData={rentalData}
          demographicsData={demographicsData}
          referenceData={referencesData}
          educationHistoryData={educationHistoryData}
          experienceData={experienceData}
        />
      )}

      {isModalOpen === "employment" && (
        <Employementmodal
          isOpen={true}
          onClose={() => setIsModalOpen(false)}
          employmentData={employmentData}
        />
      )}

      {isModalOpen === "rental" && (
        <Rentalmodal
          isOpen={true}
          onClose={() => setIsModalOpen(false)}
          rentalData={rentalData}
        />
      )}
      <Footer />
    </>
  );
};

export default Applicants;
