import React, { useEffect, useState } from "react";
import { logout } from "../../adminAuthSlice";
import Logo from "../../../../assets/logo.png";
import {
  FiLogOut,
  FiHome,
  FiUsers,
  FiCheckCircle,
  FiClock,
  FiDollarSign,
  FiFileText,
  FiTag,
} from "react-icons/fi";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";

const Layout = ({ children, activeIndex }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.adminAuth);
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(!openDrawer);
  };

  const handleLogout = async () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  const tabsDataWithIcons = [
    {
      id: 1,
      title: "Dashboard",
      route: "/admin",
      icon: <FiHome />,
    },
    {
      id: 2,
      title: "Users",
      route: "/admin/dashboard/users/",
      icon: <FiUsers />,
    },
    {
      id: 3,
      title: "ID Verification",
      route: "/admin/dashboard/idverification",
      icon: <FiCheckCircle />,
    },
    {
      id: 4,
      title: "History",
      route: "/admin/dashboard/history",
      icon: <FiClock />,
    },
    {
      id: 5,
      title: "Payments",
      route: "/admin/dashboard/payments",
      icon: <FiDollarSign />,
    },
    {
      id: 6,
      title: "Pricing Plans",
      route: "/admin/dashboard/plans",
      icon: <FiTag />,
    },
    {
      id: 7,
      title: "Applications",
      route: "/admin/dashboard/application",
      icon: <FiFileText />,
    },
  ];

  return (
    <>
      <div className=" py-2 min-h-[90px] bg-white border-b border-b-[#D9D9D9]">
        <div className="flex items-center justify-between">
          <div className="pl-10">
            <Link to={"/"}>
              <img src={Logo} alt="" className="w-28" />
            </Link>
          </div>
          <div className="block md:hidden">
            <IconButton onClick={toggleDrawer()}>
              <MenuIcon
                sx={{
                  fontSize: "35px",
                  width: "35px",
                }}
              />
            </IconButton>
          </div>
        </div>
      </div>
      <div className={`flex`}>
        <div className="relative">
          <div className="pl-4 w-64 bg-white h-full relative  hidden md:block">
            <div className={`flex flex-col pt-10`}>
              {tabsDataWithIcons.map((item, index) => {
                return (
                  <Link
                    to={item.route}
                    key={index}
                    className={` flex items-center mb-1 gap-4 px-4 rounded-l-full transition-all hover:text-white hover:bg-[#3867D6]`}
                  >
                    <span className="text-2xl">{item.icon}</span>
                    <span
                      className={`text-xl font-normal py-3 block ${
                        activeIndex === index
                          ? "bg-[rgb(235, 240, 251)] border-l-4 border-l-[#3867D6]"
                          : ""
                      }`}
                    >
                      {item.title}
                    </span>
                  </Link>
                );
              })}
            </div>

            <div className="cursor-pointer  gap-x-3 pl-4 ">
              <div className="flex gap-5 py-5  items-center">
                <div>
                  <Avatar alt="Remy Sharp" src={Logo}>
                    B
                  </Avatar>
                </div>

                <div className="flex flex-col flex-wrap ">
                  <span className="text-sm font-normal opacity-50 text-[#142D3C] ">
                    Admin
                  </span>
                  <span className="text-xl text-[#142D3C] font-normal">
                    {user?.name}
                  </span>
                </div>
              </div>
              <div onClick={handleLogout} className="flex items-center gap-2">
                <span className="flex self-center">
                  <FiLogOut className="text-[#FF5858]" />
                </span>

                <span className="flex self-center text-[#FF5858]">Logout</span>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden block">
          <Drawer anchor={"left"} open={openDrawer} onClose={toggleDrawer()}>
            <div className="pl-4 w-64">
              <div className="flex justify-start px-4 pt-8 mb-10">
                <Link to={"/"}>
                  <img src={Logo} alt="" className="w-28" />
                </Link>
              </div>

              <div className={`flex flex-col pt-10`}>
                {tabsDataWithIcons.map((item, index) => {
                  return (
                    <Link
                      to={item.route}
                      key={index}
                      className={` flex items-center mb-1 gap-4 px-4 rounded-l-full transition-all hover:text-white hover:bg-[#3867D6]`}
                    >
                      <span className="text-2xl">{item.icon}</span>
                      <span
                        className={`text-xl font-normal py-3 block ${
                          activeIndex === index
                            ? "bg-[rgb(235, 240, 251)] border-l-4 border-l-[#3867D6]"
                            : ""
                        }`}
                      >
                        {item.title}
                      </span>
                    </Link>
                  );
                })}
              </div>

              <div className="absolute cursor-pointer bottom-4 gap-x-3 pl-4 ">
                <div className="flex gap-5 py-5  items-center">
                  <div>
                    <Avatar alt="Remy Sharp" src={Logo}>
                      B
                    </Avatar>
                  </div>

                  <div className="flex flex-col flex-wrap ">
                    <span className="text-sm font-normal opacity-50 text-[#142D3C] ">
                      Admin
                    </span>
                    <span className="text-xl text-[#142D3C] font-normal">
                      {user?.name}
                    </span>
                  </div>
                </div>
                <div onClick={handleLogout} className="flex items-center gap-2">
                  <span className="flex self-center">
                    <FiLogOut className="text-[#FF5858]" />
                  </span>

                  <span className="flex self-center text-[#FF5858]">
                    Logout
                  </span>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
        <div className="max-w-7xl mx-auto px-4 py-6 w-full overflow-hidden min-h-screen">
          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;
