import React from "react";
import { Link } from "react-router-dom";
import FooterLogo from "../../assets/footerLogo.png";
import facebookIcon from "../../assets/Facebook.svg";
import instaIcon from "../../assets/Instagram.svg";
import twitterIcon from "../../assets/Twitter.svg";
import youtubeIcon from "../../assets/Youtube.svg";
import linkedinIcon from "../../assets/Linkedin.svg";
import { useTranslation, withTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-white border-t border-t-[#D9D9D9]">
      <div className="max-w-[1550px] mx-auto px-4 pt-16">
        <div className="grid grid-cols-1 gap-10 md:grid-cols-3 mb-10">
          <div className="md:mb-0">
            <div className="mb-10">
              <Link to="/">
                <img src={FooterLogo} alt="Fullsetters" />
              </Link>
            </div>
            <div className="flex gap-2">
              <a
                href="https://www.facebook.com/fullsetters"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebookIcon} alt="" />
              </a>
              <a
                href="http://www.instagram.com/fullsetters/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instaIcon} alt="" />
              </a>
              <a
                href="https://twitter.com/fullsetters"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitterIcon} alt="" />
              </a>
              <a
                href="https://youtube.com/@fullsetters"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtubeIcon} alt="" />
              </a>
              <a
                href="https://www.LinkedIn.com/company/fullsettersinc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedinIcon} alt="" />
              </a>
            </div>
          </div>
          <div>
            <h2 className="mb-5 text-xl font-semibold text-primary">
              {t("quick-links")}
            </h2>
            <Link to="/about" className="footerLink">
              {t("about")}
            </Link>
            <Link to="/contact-us" className="footerLink">
              {t("contact-us")}
            </Link>
            <Link to="/terms-of-services" className="footerLink">
              {t("tos")}
            </Link>
            <Link to="/privacy-policy" className="footerLink">
              {t("privacy-policy")}
            </Link>
          </div>
          <div>
            <h2 className="mb-5 text-xl font-semibold text-primary">
              {t("more")}
            </h2>
            <Link to="/blogs" className="footerLink">
              {t("blog-posts")}
            </Link>
            <Link to="/pricing" className="footerLink">
              {t("pricing")}
            </Link>
            <Link to="/account-settings" className="footerLink">
              {t("account-settings")}
            </Link>
          </div>
        </div>
        <div className="flex flex-col-reverse pb-5 sm:flex-row sm:justify-between gap-4 max-w-7xl">
          <p className="text-gray-600">
            © 2023 Fullsetters, Inc. All rights reserved.
          </p>
          <div className="flex justify-center gap-3 ">
            <Link to="/copyright" className="footerLink">
              {t("Copyright")}
            </Link>
            <span className="footerLink">{"|"}</span>
            <Link to="/terms-of-services" className="footerLink">
              {t("tos")}
            </Link>
            <span className="footerLink">{"|"}</span>
            <Link to="/privacy-policy" className="footerLink">
              {t("Privacy")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Footer);
