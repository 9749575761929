import React from "react";
import { Button } from "@mui/material";
import BannerVideo from "../../assets/fullsetter-hero.mp4";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-wrap items-center w-full min-h-[80vh] py-10 md:py-0">
      <div className="max-w-[1550px] mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
          <div className="max-w-[620px]">
            <div className="mb-10">
              <h1 className="md:text-4xl md:leading-[56px] text-2xl max-w-6xl text-primary font-bold mb-6">
                <span className="text-[#3867D6]"> {t("fullsetter")}</span>
                {t("ultimate-solution")}
                <span className="text-[#3867D6]"> {t("hassle-free")}</span>{" "}
                {t("applications")}
              </h1>
              <p className="text-lg font-normal text-secondary max-w-[620px]">
                {t("hero-description")}
              </p>
            </div>
            <div>
              <div className="flex gap-x-2">
                <Link to={"/branch/demographics"}>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      backgroundColor: "#3867D6",
                    }}
                  >
                    {t("get-started")}
                  </Button>
                </Link>
                <AnchorLink href="#whyFullSetters">
                  <Button
                    variant="text"
                    size="large"
                    sx={{
                      color: "#3867D6",
                    }}
                  >
                    {t("learn-more")}
                  </Button>
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <video
              src={BannerVideo}
              width="450"
              height="300"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={BannerVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Hero);
