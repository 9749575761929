import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";

const API_URL = "https://fullsetters.com/api/users/emailVerify?token=";

const Verify = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState("");

  useEffect(() => {
    axios
      .post(API_URL + token)
      .then((res) => {
        setLoading(false);
        setVerificationStatus("success");
        // Redirect to login page after successful verification
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        setVerificationStatus("error");
      });
  }, [token, navigate]);

  return (
    <div className="flex justify-center items-center h-full">
      {loading ? (
        <CircularProgress />
      ) : verificationStatus === "success" ? (
        <div className="text-center h-screen flex items-center w-full">
          <div className="shadow-sm max-w-xl flex justify-center mx-auto p-4 w-full">
            <Alert severity="success">
              <AlertTitle>Email Verified Successfully!</AlertTitle>
              You can now login to your account.
            </Alert>
          </div>
        </div>
      ) : (
        <div className="text-center h-screen justify-center flex items-center w-full">
          <div>
            <Alert severity="error">
              <AlertTitle>Email Verification Failed</AlertTitle>
              Please try again later or contact support.
            </Alert>
            <Button
              variant="contained"
              onClick={() => navigate("/signup")}
              sx={{ mt: 2 }}
            >
              Go back to Signup
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Verify;
