import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
const Hero = () => {
  const { t } = useTranslation();

  const data = [
    { feature: t("tos") },
    { feature: t("Disclaimer") },
    { feature: t("Privacy") },
    { feature: t("tos") },
    { feature: t("Disclaimer") },
    { feature: t("Privacy") },
    { feature: t("tos") },
    { feature: t("Disclaimer") },
    { feature: t("Privacy") },
    { feature: t("tos") },
  ];
  return (
    <div className=" mb-14">
      <div className="backgroundpic h-screen  py-[100px] mb-14">
        <div className="text-center">
          <h1 className="text-[#142D3C] text-[40px] font-bold mb-3">
            {t("How we may help you out?")}
          </h1>
          <p className="text-[#142D3C] text-lg font-normal mb-14">
            {t("Search for any kind of help")}{" "}
          </p>
          <div className="m-auto w-11/12 sm:w-5/6 md:w-3/4 lg:w-1/2 xl:w-2/5">
            <input
              type="text"
              placeholder={t("Search")}
              className="w-full py-5 rounded-md pl-5 focus-within:outline-none text-[rgba(20,45,60,0.4)] text-xl"
            />
          </div>
        </div>
      </div>

      <div className="main-container">
        <h1 className="text-[#142D3C] text-[40px] text-center font-bold mb-10">
          {t("Featured Topics")}
        </h1>
        <div className="grid grid-cols-2  gap-5">
          {data.map(({ feature }, index) => {
            return (
              <div key={index}>
                <div className="text-[#142D3C] text-xl font-normal bg-[#E5E7EB] rounded-[8px] py-5 w-full text-center">

                  {feature}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Hero);
