import React from "react";
import Layout from "./Layout";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";

const data = [
  { month: "Jan", pv: 2400, users: 100 },
  { month: "Feb", pv: 1398, users: 80 },
  { month: "Mar", pv: 9800, users: 500 },
  { month: "Apr", pv: 3908, users: 150 },
  { month: "May", pv: 4800, users: 200 },
  { month: "Jun", pv: 3800, users: 100 },
  { month: "Jul", pv: 4300, users: 120 },
  { month: "Aug", pv: 2400, users: 80 },
  { month: "Sep", pv: 1398, users: 50 },
  { month: "Oct", pv: 6800, users: 300 },
  { month: "Nov", pv: 3908, users: 150 },
  { month: "Dec", pv: 4800, users: 200 },
];

const Chartinfo = () => {
  return (
    <>
      <Layout>
        <div className="">
          <div className="flex flex-wrap gap-4 justify-center sm:justify-start mb-4">
            <div className="w-full sm:w-[25%]">
              <div className="w-full h-48 bg-white rounded-lg flex items-center px-4">
                <div className="flex flex-col ">
                  <h2 className="text-black font-bold text-[32px] sm:text-[40px] ">
                    127,432,
                  </h2>
                  <span className="text-black font-bold text-lg sm:text-xl opacity-70 ">
                    {" "}
                    Submitted Application
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-[25%]">
              <div className="w-full h-48 bg-white  rounded-lg flex items-center  px-4">
                <div className="text-black font-bold text-[32px] sm:text-[40px] flex flex-col ">
                  <h2 className="text-black font-bold text-[32px] sm:text-[40px] ">
                    127,432,
                  </h2>
                  <span className="text-black font-bold text-lg sm:text-xl">
                    Subscribers Count
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-[25%]">
              <div className="w-full h-48 bg-white rounded-lg flex items-center  px-4">
                <div className="flex flex-col ">
                  <h2 className="text-black font-bold text-[32px] sm:text-[40px] ">
                    47039
                  </h2>
                  <span className="text-black font-bold text-lg sm:text-xl  ">
                    Posted Application
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="w-full lg:col-span-1">
              <ResponsiveContainer
                width="100%"
                height={500}
                className="bg-[#FFFFFF]"
              >
                <LineChart data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="pv"
                    stroke="#4074DC"
                    activeDot={{ r: 8 }}
                  />
                  {data.map((entry, index) => (
                    <Text
                      key={`label-${index}`}
                      x={index * 41 + 20.5}
                      y={260}
                      textAnchor="middle"
                      fill="#666"
                      fontSize={14}
                    >
                      {entry.pv}
                    </Text>
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className="w-full bg-white lg:col-span-2">
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  data={data}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="users" fill="#4074DC" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Chartinfo;
