import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ToastContainer, toast } from "react-toastify";
import Layout from "./Layout";

const AccountSettingsForm = () => {
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    // Handle form submission logic here
    if (fullName === "" || gender === "" || phone === "" || email === "") {
      toast.error("All fields are required.");
      return;
    }
    toast.success("Successfully Added!");
    setFullName("");
    setGender("");
    setEmail("");
  };

  return (
    <Layout>
      <ToastContainer position="top-left" />

      <div className=" py-10 grid grid-cols-1 gap-6 md:grid-cols-2">
        <div>
          <label htmlFor="">First Name</label>
          <TextField
            fullWidth
            label="First Name"
            id="fullWidth"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div>
          <FormControl fullWidth>
            <label htmlFor="">Gender</label>
            <Select
              labelId="usertype"
              id="type-select"
              value={gender}
              label="Status"
              onChange={(e) => setGender(e.target.value)}
            >
              <MenuItem value={"active"}>Male</MenuItem>
              <MenuItem value={"pending"}>Female</MenuItem>
              <MenuItem value={"approved"}>Don't Wish</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2 ">
        <div>
          <label htmlFor="" className="mb-10">
            Email Address
          </label>
          <TextField
            fullWidth
            type="email"
            label="Email"
            id="fullWidth"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="" className="mb-10">
            Phone Number
          </label>
          <TextField
            fullWidth
            type="number"
            label="phone number"
            id="fullWidth"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
      </div>

      <div>
        <Button
          variant="contained"
          size="large"
          sx={{
            backgroundColor: "#3867D6",
            padding: "5px 20px",
          }}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </Layout>
  );
};

export default AccountSettingsForm;
