import React, { useState, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation, useTranslation } from "react-i18next";

const DEMOGRAPHICS_API_URL = "https://fullsetters.com/api/demographics/";
const RENTAL_API_URL = "https://fullsetters.com/api/rental/";
const Educatinal_API_URL = "https://fullsetters.com/api/education/";
const References_API_URL = "https://fullsetters.com/api/references/";
// Requirement is work experience but we have to pass employment detail based on client requirement
const Employement_API_URL = "https://fullsetters.com/api/employment/";

const Form = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [activeDemoIndex, setActiveDemoIndex] = useState(0);
  const [activeresidentialIndex, setActiveResidentialIndex] = useState(0);
  const [activeEducatinIndex, setActiveEducatinIndex] = useState(0);
  const [activeReferenceIndex, setActiveReferenceIndex] = useState(0);
  const [activeEmploymenteIndex, setActiveEmploymentIndex] = useState(0);
  const [activePaymentMethod, setActivePaymentMethod] = useState(0);

  const [demoGraphicsData, setDemoGraphicsData] = useState([]);
  const [residentialData, setResidentialData] = useState([]);
  const [educationalData, setEducationalData] = useState([]);
  const [referencesData, setReferencesData] = useState([]);
  const [employmentData, setEmploymentData] = useState([]);
  const paymentsData = [];

  const [userId, setUserId] = useState("");

  const [demoFields, setDemoFields] = useState({
    prefix: "",
    sufix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    otherName: "",
    emailAddress: "",
    phoneNumber: "",
    dateofBirth: "",
    gender: "",
    ethnicity: "",
    race: "",
    activeMilitary: "",
    disabledVeteran: "",
    workPhone: "",
    homePhone: "",
    cellPhone: "",
    contactEmail: "",
    residentialStreetName: "",
    residentialAddress2: "",
    residentialCity: "",
    residentialState: "",
    residentialZipCode: "",
    residentialCountry: "",
    billingStreetName: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingCountry: "",
    billingZipCode: "",
  });
  const [residentialFields, setResidentialFields] = useState({
    streetName: "",
    city: "",
    state: "",
    zipCode: "",
    province: "",
  });

  const [educationalFields, setEducationalFields] = useState({
    schoolName: "",
    degree: "",
    streetName: "",
    city: "",
    state: "",
    zipCode: "",
    province: "",
    major: "",
    minor: "",
    startingDate: "",
    finishingDate: "",
    relationship: "",
    name: "",
    email: "",
    phone: "",
  });
  const [referencesfields, setReferencesfields] = useState({
    relationship: "",
    name: "",
    email: "",
    phone: "",
    streetName: "",
    city: "",
    state: "",
    zipCode: "",
    province: "",
  });
  const [employmentfields, setEmploymentfields] = useState({
    employeer: "",
    jobTitle: "",
    startingDate: "",
    finishingDate: "",
    streetName: "",
    city: "",
    state: "",
    zipCode: "",
    province: "",
    relationship: "",
    name: "",
    email: "",
    phone: "",
  });
  const [paymentFields, setPaymentfields] = useState({
    cardNumber: "",
    expirationDate: "",
    CVC: "",
  });

  const getUserId = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    setUserId(user._id);
  };
  useEffect(() => {
    getUserId();
  }, []);

  // Input Field Handler
  const handleSubmit = (e) => {
    e.preventDefault();

    const dataToSubmit = {
      applicationId: id,
      userId: userId,
      selectedRecords: {
        rental: residentialFields?._id,
        demographics: demoFields?._id,
        education: educationalFields?._id,
        references: referencesfields?._id,
        employment: employmentfields?._id,
      },
    };

    axios
      .post("https://fullsetters.com/api/application/apply", dataToSubmit)
      .then(() => {
        toast.success("Application submitted successfully!");
        setTimeout(() => {
          navigate("/success");
        }, 1200);
      })
      .catch((error) => {
        toast.error("Error submitting application!");
        console.error("Error submitting application:", error);
      });
  };

  const demoGraphicsEditHandler = (index) => {
    setActiveDemoIndex(index);
    setDemoFields(demoGraphicsData[index]);
  };
  const residentialDataEditHandler = (index) => {
    setActiveResidentialIndex(index);
    setResidentialFields(residentialData[index]);
  };
  const educationEditHandler = (index) => {
    setActiveEducatinIndex(index);
    setEducationalFields(educationalData[index]);
  };
  const referencesEditHandler = (index) => {
    setActiveReferenceIndex(index);
    setReferencesfields(referencesData[index]);
  };

  const experienceEditHandler = (index) => {
    setActiveEmploymentIndex(index);
    setEmploymentfields(employmentData[index]);
  };
  const paymentEditHandler = (index) => {
    setActivePaymentMethod(index);
    setPaymentfields(paymentsData[index]);
  };

  const getDemoGraphicsData = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(DEMOGRAPHICS_API_URL + user._id, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });

    const result = response.data;
    setDemoGraphicsData(result.data);
  };
  const getResidentialData = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(RENTAL_API_URL + user._id, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });

    const result = response.data;
    setResidentialData(result.data);
  };
  const getEducationalData = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(Educatinal_API_URL + user._id, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });

    const result = response.data;
    setEducationalData(result.data);
  };
  const getReferencesData = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(References_API_URL + user._id, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });

    const result = response.data;
    setReferencesData(result.data);
  };
  const getEmploymentData = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(Employement_API_URL + user._id, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });

    const result = response.data;
    setEmploymentData(result.data);
  };
  useEffect(() => {
    getDemoGraphicsData();
    getResidentialData();
    getEducationalData();
    getReferencesData();
    getEmploymentData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  return (
    <div className="w-full">
      <ToastContainer />
      <div className="mx-auto my-12 max-w-[1550px] px-4">
        <div className="rounded-t-lg bg-white">
          <h1 className="border border-b px-5 py-4 text-left text-2xl font-bold text-primary">
            {t("Jobs Application")}
          </h1>
        </div>
        <div className="bg-white px-5 pb-4 pt-4">
          <h2 className="text-left text-2xl font-bold  text-primary ">
            {t("Demographics")}
          </h2>
          <div className="my-4 flex flex-wrap gap-1">
            {demoGraphicsData?.map((item, index) => {
              return (
                <div key={index}>
                  <button
                    onClick={() => demoGraphicsEditHandler(index)}
                    className={`${
                      activeDemoIndex !== index
                        ? "border-secondary text-secondary bg-transparent"
                        : "border-[#3867D6] bg-[#3867D6] text-white"
                    } rounded-sm border px-6 py-2.5 `}
                  >
                    {item?.firstName}
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <div className="rounded-b-lg bg-white px-5 pb-8 ">
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <FormControl fullWidth>
                <InputLabel id="prefix">{t("Prefix")}</InputLabel>
                <Select
                  labelId="prefix"
                  id="prefix-select"
                  name="prefix"
                  label="prefix"
                  value={demoFields.prefix || ""}
                >
                  <MenuItem value={"mr"}>Mr.</MenuItem>
                  <MenuItem value={"mrs"}>Mrs.</MenuItem>
                  <MenuItem value={"miss"}>Miss.</MenuItem>
                  <MenuItem value={"dr"}>Dr.</MenuItem>
                  <MenuItem value={"prof"}>Prof.</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel id="sufix">{t("Suffix")}</InputLabel>
                <Select
                  labelId="sufix"
                  name="sufix"
                  id="sufix-select"
                  label="sufix"
                  value={demoFields?.sufix || ""}
                >
                  <MenuItem value={"I"}>I</MenuItem>
                  <MenuItem value={"II"}>II</MenuItem>
                  <MenuItem value={"III"}>III</MenuItem>
                  <MenuItem value={"IV"}>IV</MenuItem>
                  <MenuItem value={"V"}>V</MenuItem>
                  <MenuItem value={"sr"}>Sr.</MenuItem>
                  <MenuItem value={"jr"}>Jr.</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                fullWidth
                label={t("First Name")}
                id="First-Name"
                name="firstName"
                value={demoFields.firstName || ""}
              />
            </div>
            <div>
              <TextField
                fullWidth
                label={t("Middle Name")}
                id="Middle-Name"
                name="middleName"
                value={demoFields.middleName || ""}
              />
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                fullWidth
                label={t("Last Name")}
                id="Last-Name"
                name="lastName"
                value={demoFields.lastName || ""}
              />
            </div>
            <div>
              <TextField
                fullWidth
                label={t("Other Names")}
                id="other-Name"
                name="otherName"
                value={demoFields.otherName || ""}
              />
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                fullWidth
                label={t("Email Address")}
                id="Email-address"
                name="emailAddress"
                value={demoFields.emailAddress || ""}
              />
            </div>
            <div>
              <TextField
                fullWidth
                label={t("Phone Number")}
                id="Phone-number"
                name="phoneNumber"
                value={demoFields.phoneNumber || ""}
              />
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                fullWidth
                label={t("Date of Birth")}
                id="Date-of-birth"
                InputLabelProps={{ shrink: true }}
                type="date"
                name="dateofBirth"
                value={formatDate(demoFields.dateofBirth) || ""}
              />
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel id="gender">{t("Gender")}</InputLabel>
                <Select
                  labelId="gender"
                  id="gender-select"
                  name="gender"
                  label="gender"
                  value={demoFields.gender || ""}
                >
                  <MenuItem value={"male"}>{t("Male")}</MenuItem>
                  <MenuItem value={"female"}>{t("Female")}</MenuItem>
                  <MenuItem value={"other"}>{t("Other")}</MenuItem>
                  <MenuItem value={"Decline To Answer"}>
                    {t("Decline To Answer")}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <FormControl fullWidth>
                <InputLabel id="ethnicity">{t("Ethnicity")}</InputLabel>
                <Select
                  labelId="ethnicity"
                  id="ethnicity-select"
                  name="ethnicity"
                  label="ethnicity"
                  value={demoFields.ethnicity || ""}
                >
                  <MenuItem value={"Hispanic or Latino"}>
                    {t("Hispanic or Latino")}
                  </MenuItem>
                  <MenuItem value={"Not Hispanic or Latino"}>
                    {t("Not Hispanic or Latino")}
                  </MenuItem>
                  <MenuItem value={"Other"}>{t("Other")}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel id="race">{t("Race")}</InputLabel>
                <Select
                  labelId="race"
                  id="race-select"
                  label="race"
                  name="race"
                  value={demoFields.race || ""}
                >
                  <MenuItem value={"American Indian or Alaska Native"}>
                    {t("American Indian or Alaska Native")}
                  </MenuItem>
                  <MenuItem value={"Asian"}>{t("Asian")}</MenuItem>
                  <MenuItem value={"Black or African American"}>
                    {t("Black or African American")}
                  </MenuItem>
                  <MenuItem value={"Native Hawaiian or Pacific Islander"}>
                    {t("Native Hawaiian or Pacific Islander")}
                  </MenuItem>
                  <MenuItem value={"White"}>{t("White")}</MenuItem>
                  <MenuItem value={"Other Race"}>{t("Other Race")}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <FormControl fullWidth>
                <InputLabel id="activeMilitary">
                  {t("Active Military?")}
                </InputLabel>
                <Select
                  labelId="activeMilitary"
                  name="activeMilitary"
                  id="activeMilitary-select"
                  label="activeMilitary"
                  value={demoFields.activeMilitary || ""}
                >
                  <MenuItem value={"yes"}>{t("Yes")}</MenuItem>
                  <MenuItem value={"no"}>{t("No")}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel id="disabledVeteran">
                  {t("Are you a disabled Veteran?")}
                </InputLabel>
                <Select
                  labelId="disabledVeteran"
                  name="disabledVeteran"
                  id="disabledVeteran-select"
                  label="disabledVeteran"
                  value={demoFields.disabledVeteran || ""}
                >
                  <MenuItem value={"yes"}>{t("Yes")}</MenuItem>
                  <MenuItem value={"no"}>{t("No")}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="bg-white py-5">
            <h2 className="mb-2 text-left text-2xl font-bold text-primary">
              {t("Contact info")}
            </h2>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                fullWidth
                label={t("Work Phone")}
                id="work-phone"
                name="workPhone"
                value={demoFields.workPhone || ""}
              />
            </div>
            <div>
              <TextField
                fullWidth
                label={t("Home Phone")}
                id="home-phone"
                name="homePhone"
                value={demoFields.homePhone || ""}
              />
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                fullWidth
                label={t("Cell Phone")}
                id="cell-phone"
                name="cellPhone"
                value={demoFields.cellPhone || ""}
              />
            </div>
            <div>
              <TextField
                fullWidth
                label={t("Email")}
                id="contact-email"
                name="contactEmail"
                value={demoFields.contactEmail || ""}
              />
            </div>
          </div>
          <div className="bg-white py-5">
            <h2 className="mb-2 text-left text-2xl font-bold text-primary">
              {t("Residential Address")}
            </h2>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6">
            <div>
              <TextField
                fullWidth
                label={t("Street name & number")}
                id="residential-street-name-number"
                name="residentialStreetName"
                value={demoFields.residentialStreetName || ""}
              />
            </div>
            <div>
              <TextField
                fullWidth
                label={t("Address 2 (Optional)")}
                id="residential-address2"
                name="residentialAddress2"
                value={demoFields.residentialAddress2 || ""}
              />
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                fullWidth
                label={t("City")}
                id="residential-city"
                name="residentialCity"
                value={demoFields.residentialCity || ""}
              />
            </div>
            <div>
              <TextField
                fullWidth
                label={t("State")}
                id="residentialState"
                name="residentialState"
                value={demoFields.residentialState || ""}
              />
            </div>
            <div>
              <TextField
                fullWidth
                label={t("ZIP Code")}
                id="residentialZipCode"
                name="residentialZipCode"
                value={demoFields.residentialZipCode || ""}
              />
            </div>
            <div>
              <TextField
                fullWidth
                label={t("Country")}
                id="residentialCountry"
                name="residentialCountry"
                value={demoFields.residentialCountry || ""}
              />
            </div>
          </div>
          <div>
            <div className="bg-white pt-5">
              <h2 className=" text-left text-2xl font-bold text-primary">
                {t("Work Experience")}
              </h2>
              <div className="mt-4 mb-8 flex flex-wrap gap-1">
                {employmentData?.map((item, index) => {
                  return (
                    <div key={index}>
                      <button
                        onClick={() => experienceEditHandler(index)}
                        className={`${
                          activeEmploymenteIndex !== index
                            ? "border-secondary text-secondary bg-transparent"
                            : "border-[#3867D6] bg-[#3867D6] text-white"
                        } rounded-sm border px-6 py-2.5 `}
                      >
                        {item?.jobTitle}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div>
                <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
                  <div>
                    <TextField
                      fullWidth
                      label={t("Employeer")}
                      id="fullWidth"
                      name="employeer"
                      value={employmentfields?.employeer || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label={t("Job Title")}
                      id="fullWidth"
                      name="jobTitle"
                      value={employmentfields?.jobTitle || ""}
                    />
                  </div>
                </div>
                <div className="mb-6">
                  <TextField
                    fullWidth
                    label={t("Street name & number")}
                    id="fullWidth"
                    name="streetName"
                    value={employmentfields?.streetName || ""}
                  />
                </div>
                <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
                  <div>
                    <TextField
                      fullWidth
                      label={t("Starting Date")}
                      id="fullWidth"
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      name="startingDate"
                      value={employmentfields?.startingDate || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label={t("Finishing Date")}
                      id="fullWidth"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      name="finishingDate"
                      value={employmentfields?.finishingDate || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white py-5">
            <h2 className="mb-2 text-left text-2xl font-bold text-primary">
              {t("Education History")}
            </h2>
            <div className="my-4 flex flex-wrap gap-1">
              {educationalData?.map((item, index) => {
                return (
                  <div key={index}>
                    <button
                      onClick={() => educationEditHandler(index)}
                      className={`${
                        activeEducatinIndex !== index
                          ? "border-secondary text-secondary bg-transparent"
                          : "border-[#3867D6] bg-[#3867D6] text-white"
                      } rounded-sm border px-6 py-2.5 `}
                    >
                      {item?.schoolName}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <>
            <div>
              <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
                <div>
                  <TextField
                    fullWidth
                    label={t("School Name")}
                    id="fullWidth"
                    name="schoolName"
                    value={educationalFields?.schoolName || ""}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label={t("Degree")}
                    id="fullWidth"
                    name="degree"
                    value={educationalFields?.degree || ""}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label={t("Major")}
                    id="fullWidth"
                    name="major"
                    value={educationalFields?.major || ""}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label={t("Minor")}
                    id="fullWidth"
                    name="minor"
                    value={educationalFields?.minor || ""}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label={t("Starting Date")}
                    id="fullWidth"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    name="startingDate"
                    value={educationalFields?.startingDate || ""}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label={t("Finishing Date")}
                    id="fullWidth"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    name="finishingDate"
                    value={educationalFields?.finishingDate || ""}
                  />
                </div>
              </div>
            </div>
          </>
          <div className="bg-white py-5">
            <h2 className="mb-2 text-left text-2xl font-bold text-primary">
              {t("References")}
            </h2>
            <div className="flex gap-1 mb-4">
              {referencesData?.map((item, index) => {
                return (
                  <div key={index}>
                    <button
                      onClick={() => referencesEditHandler(index)}
                      className={`${
                        activeReferenceIndex !== index
                          ? "border-secondary text-secondary bg-transparent"
                          : "border-[#3867D6] bg-[#3867D6] text-white"
                      } rounded-sm border px-6 py-2.5 `}
                    >
                      {item?.name}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <>
            <>
              <div>
                <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <TextField
                      fullWidth
                      label={t("Relationship")}
                      id="fullWidth"
                      name="relationship"
                      value={referencesfields?.relationship || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label={t("Name")}
                      id="fullWidth"
                      name="name"
                      value={referencesfields?.name || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label={t("Email Address")}
                      id="fullWidth"
                      name="email"
                      value={referencesfields?.email || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label={t("Phone Number")}
                      id="fullWidth"
                      name="phone"
                      value={referencesfields?.phone || ""}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <TextField
                      fullWidth
                      label={t("Street name & number")}
                      id="fullWidth"
                      name="streetName"
                      value={referencesfields?.streetName || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label={t("City")}
                      id="fullWidth"
                      name="city"
                      value={referencesfields?.city || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label={t("State")}
                      id="fullWidth"
                      name="state"
                      value={referencesfields?.state || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label={t("ZIP Code")}
                      id="fullWidth"
                      name="zipCode"
                      value={referencesfields?.zipCode || ""}
                    />
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label={t("Province")}
                      id="fullWidth"
                      name="province"
                      value={referencesfields?.province || ""}
                    />
                  </div>
                </div>
              </div>
            </>
          </>
          <>
            <div className="bg-white py-5">
              <h2 className="mb-2 text-left text-2xl font-bold text-primary">
                {t("Residential History")}
              </h2>
              <div className="my-4 flex flex-wrap gap-1">
                {residentialData?.map((item, index) => {
                  return (
                    <div key={index}>
                      <button
                        onClick={() => residentialDataEditHandler(index)}
                        className={`${
                          activeresidentialIndex !== index
                            ? "border-secondary text-secondary bg-transparent"
                            : "border-[#3867D6] bg-[#3867D6] text-white"
                        } rounded-sm border px-6 py-2.5 `}
                      >
                        {item?.streetName}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="mb-6">
              <TextField
                fullWidth
                label={t("Street name & number")}
                id="fullWidth"
                name="streetName"
                value={residentialFields.streetName || ""}
              />
            </div>
            <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
              <div>
                <TextField
                  fullWidth
                  label={t("City")}
                  id="fullWidth"
                  name="city"
                  value={residentialFields.city || ""}
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  label={t("State")}
                  id="fullWidth"
                  name="state"
                  value={residentialFields.state || ""}
                />
              </div>
            </div>

            <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
              <div>
                <TextField
                  fullWidth
                  label={t("ZIP Code")}
                  id="fullWidth"
                  name="zipCode"
                  value={residentialFields.zipCode || ""}
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  label={t("Province")}
                  id="fullWidth"
                  name="province"
                  value={residentialFields.province || ""}
                />
              </div>
            </div>
          </>
          <div className="bg-white py-5">
            <h2 className="mb-2 text-left text-2xl font-bold text-primary">
              {t("Payment")}
            </h2>
            <div className="flex gap-1">
              <div>
                <button
                  onClick={() => paymentEditHandler(0)}
                  className={`${
                    activePaymentMethod !== 0
                      ? "border-secondary text-secondary bg-transparent"
                      : "border-[#3867D6] bg-[#3867D6] text-white"
                  } rounded-sm border px-6 py-2.5 `}
                >
                  {t("Your Visa Card")}
                </button>
              </div>
              <div>
                <button
                  onClick={() => paymentEditHandler(1)}
                  className={`${
                    activePaymentMethod !== 1
                      ? "border-secondary text-secondary bg-transparent"
                      : "border-[#3867D6] bg-[#3867D6] text-white"
                  } rounded-sm border px-6 py-2.5 `}
                >
                  Your Mastercard
                </button>
              </div>
            </div>
          </div>
          <div className="mb-6">
            <TextField
              fullWidth
              label="Card Number"
              id="fullWidth"
              name="cardNumber"
              value={paymentFields.cardNumber || ""}
            />
          </div>
          <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <TextField
                fullWidth
                label="Expiration Date"
                id="fullWidth"
                name="expirationDate"
                value={paymentFields.expirationDate || ""}
              />
            </div>
            <div>
              <TextField
                fullWidth
                label="CVC"
                id="fullWidth"
                name="cvc"
                value={paymentFields.CVC || ""}
              />
            </div>
          </div>

          <Button
            variant="contained"
            size="large"
            sx={{
              backgroundColor: "#3867D6",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Form);
