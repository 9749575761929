import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import socketIOClient from "socket.io-client";
import {
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Avatar,
  IconButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const SOCKET_ENDPOINT = "https://fullsetters.com";
const API_URL = "https://fullsetters.com/api/chat";

const ChatPage = ({ activeUserId, receiverId, receiverName }) => {
  const chatRef = useRef(null);
  const [userInfo, setUserInfo] = useState();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, [receiverId, newMessage]);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUserInfo(user);

    // Initialize socket connection
    const socket = socketIOClient(SOCKET_ENDPOINT, {
      path: "/socket.io",
      query: { token: user?.token },
    });

    // Listen for new messages
    socket.on("newMessage", ({ newMessage }) => {
      if (
        newMessage.sender === receiverId ||
        newMessage.receiver === receiverId
      ) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    });
    socket.on("messageStatusUpdate", ({ messageId, status }) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg._id === messageId ? { ...msg, status } : msg
        )
      );
    });

    return () => {
      socket.disconnect();
    };
  }, [receiverId]);

  useEffect(() => {
    if (receiverId && activeUserId) {
      const fetchMessages = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/receive/${activeUserId}?otherUserId=${receiverId}`
          );
          setMessages(response.data);
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      };

      fetchMessages();
    }
  }, [activeUserId, receiverId]);

  const handleSendMessage = async () => {
    try {
      const response = await axios.post(`${API_URL}/send`, {
        senderId: userInfo._id,
        receiverId: receiverId,
        message: newMessage,
      });

      // Update the messages state with the new message
      setMessages((prevMessages) => [...prevMessages, response.data]);
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };
  return (
    <div className="h-[87.4vh] bg-[#f5f5f5] flex flex-col">
      <AppBar position="static" color="primary">
        <Toolbar>
          <Avatar alt={receiverName} src="/path/to/receiver/avatar.jpg" />
          <Typography variant="h6" color="inherit" ml={2}>
            {receiverName}
          </Typography>
        </Toolbar>
      </AppBar>

      <div className="flex-grow overflow-auto px-5 py-3" ref={chatRef}>
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`flex ${
              msg.sender === activeUserId ? "justify-end" : "justify-start"
            } my-2`}
          >
            <div
              className={`inline-block p-2 rounded-lg ${
                msg.sender === activeUserId
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
            >
              {msg.message}
            </div>
          </div>
        ))}
      </div>

      <div className="flex p-3 bg-white border-t border-gray-200">
        <TextField
          fullWidth
          variant="standard"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          className="mr-2"
        />
        <IconButton color="primary" onClick={handleSendMessage}>
          <SendIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default ChatPage;
