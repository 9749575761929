import React from "react";

import Form from "./Form";
import Navbar from "../../../components/Layout/Navbar";
import Footer from "../../../components/Layout/Footer";

const JobApplicationPage = () => {
  return (
    <>
      <Navbar />
      <Form />
      <Footer />
    </>
  );
};

export default JobApplicationPage;
