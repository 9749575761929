export const translations = {
  home: "Inicio",
  about: "Acerca de Nosotros",
  solutions: "Soluciones",
  help: "Ayuda",
  faqs: "Preguntas frecuentes",
  contact: "Contacto",
  blogs: "Blogs",
  pricing: "Precios",
  "Get 4 Digit Code": "Obtener código de 4 dígitos",
  "Forgot Password": "Has olvidado tu contraseña",
  "pricing-title": "Elige el plan de precios perfecto para tu éxito",
  "password-sent-message":
    "Le hemos enviado un código de restablecimiento de contraseña a su correo electrónico. ¿No puedes encontrarlo? ¡Revisa también tu casilla de spam!",
  "my-applications": "Mis Aplicaciones",
  "switch-to-master-tab": "Productos y Servicios",
  "new-application": "Nueva Aplicación",
  "sign-in": "Iniciar sesión",
  notifications: "Notificaciones",
  fullsetter: "FullSetters:",
  resume: "currículum",
  "ultimate-solution": "La solución definitiva para",
  jobs: "Trabajos",
  "has-never-been": "nunca ha sido tan fácil.",
  "hassle-free": "Hassle-Free",
  applications: "Applications",
  "hero-description":
    "¡Desbloquea aplicaciones sin esfuerzo con FullSetters! Ahorra tiempo y elimina el rellenado repetitivo de formularios. Nuestra base de datos segura almacena tus datos, rellenando automáticamente formularios de solicitud para empleos, alquileres, inmigración, escuelas, préstamos y más. Simplifica tu trayecto con FullSetters y experimenta un proceso de solicitud sin problemas.",
  "get-started": "Empezar",
  "learn-more": "Saber más",
  "trusted-companies": "Confianza de empresas de todos los tamaños",
  why: "Diversas Soluciones de Aplicación por",
  fullSetters: "FullSetters",
  "why-fullSetters-title-1": "Apartamentos en Alquiler",
  "why-fullSetters-title-2": "Escuelas",
  "why-fullSetters-title-3": "Trabajos",
  "why-fullSetters-title-4": "Préstamos",
  "why-fullSetters-title-5": "Inmigración",
  "why-fullSetters-title-6": "General",
  "why-fullSetters-desc-1":
    "Descubre y solicita tu apartamento en alquiler soñado con FullSetters. Nuestra aplicación simplifica el proceso de solicitud de apartamentos, permitiéndote enviar solicitudes y hacer un seguimiento de tu progreso sin esfuerzo.",
  "why-fullSetters-desc-2":
    "FullSetters te capacita para navegar por el proceso de solicitud sin problemas. Desde la presentación de formularios hasta la gestión de documentos requeridos, FullSetters hace que las solicitudes escolares sean muy sencillas.",
  "why-fullSetters-desc-3":
    "Desbloquea oportunidades de carrera con FullSetters. Nuestra aplicación revoluciona la experiencia de solicitud de empleo, permitiéndote aplicar sin esfuerzo a una amplia gama de puestos. Ahorra tiempo con solicitudes autocompletadas.",
  "why-fullSetters-desc-4":
    "Simplifica tu viaje de solicitud de préstamo con FullSetters. Desde préstamos personales hasta hipotecas, nuestra aplicación agiliza el proceso, ayudándote a completar solicitudes de manera eficiente y segura.",
  "why-fullSetters-desc-5":
    "Navega por el proceso de inmigración de manera fluida con FullSetters. Nuestra aplicación te guía a través de los pasos complejos, ayudándote a presentar solicitudes precisas y completas.",
  "why-fullSetters-desc-6":
    "Experimenta la comodidad de FullSetters en varios tipos de solicitudes. Ya sea solicitando licencias, permisos u otras solicitudes generales, nuestra aplicación simplifica el proceso.",
  Revolutionize: "Revolucionar",
  "the Way You Apply with": "La manera en que aplicas con",
  videoBannerTitle: "FullSetters en Acción: Simplifica tu Proceso de Solicitud",
  videoBannerTitleDescription:
    "Mira nuestro video introductorio para ver FullSetters en acción. Descubre cómo nuestra plataforma simplifica las complejidades de las solicitudes, ahorrándote tiempo y esfuerzo. Experimenta la comodidad de formularios autocompletados, recomendaciones personalizadas y gestión segura de documentos. Descubre de primera mano cómo FullSetters te capacita para navegar por el panorama de las solicitudes con facilidad y alcanzar tus objetivos.",
  what: "Qué",
  "users-say": "Opiniones de Usuarios",
  "testimonial-1":
    "Como estudiante que solicita ingreso en escuelas, FullSetters ha sido un salvavidas. La plataforma simplifica el proceso de solicitud, permitiéndome centrarme en prepararme para mi futuro.",
  "testimonial-2":
    "FullSetters ha facilitado la solicitud de préstamos. La interfaz amigable de la plataforma y las funciones seguras de gestión de documentos han simplificado el proceso de solicitud de préstamos para mí.",
  "faq-title-1": "¿Es gratuito el uso de FullSetters?",
  "faq-title-2":
    "¿Puede FullSetters ayudarme con varios tipos de aplicaciones?",
  "faq-title-3": "¿Qué tan seguro es FullSetters?",
  "faq-title-4":
    "¿Ofrece FullSetters integración con otras plataformas o sistemas?",
  "faq-title-5":
    "¿Qué hago si necesito ayuda o tengo preguntas mientras uso FullSetters?",
  "faq-title-6":
    "¿FullSetters ofrece una aplicación móvil para acceder en cualquier lugar?",
  "faq-title-7": "¿Cómo puedo comenzar con FullSetters?",
  "faq-desc-1":
    "¡Absolutamente! FullSetters ofrece una Prueba Gratuita, proporcionando a los usuarios la oportunidad de experimentar las capacidades de la plataforma antes de tomar una decisión. Después de la prueba, los usuarios pueden elegir entre una variedad de planes, incluidos los planes Estudiantil, Individual y de Organización, cada uno con características adaptadas a sus necesidades. Con FullSetters, tienes la flexibilidad de seleccionar el mejor plan para tu proceso de solicitud.",
  "faq-desc-2":
    "¡Por supuesto! FullSetters es tu solución integral para múltiples tipos de aplicaciones. Ya sea para solicitudes de empleo, alquileres, escuelas, préstamos, inmigración u otros propósitos, nuestra plataforma te permite gestionar cada categoría sin problemas. Con FullSetters, puedes gestionar y enviar diversas solicitudes, eliminando tareas repetitivas. Experimenta la comodidad de una única plataforma para todas tus necesidades de solicitud y simplifica tu camino al éxito.",
  "faq-desc-3":
    "FullSetters toma la seguridad de los datos y la privacidad muy en serio. Empleamos medidas robustas para garantizar el máximo nivel de seguridad para nuestros usuarios. Nuestra plataforma se adhiere a protocolos de cifrado estándar de la industria, protegiendo tu información personal de accesos no autorizados. También actualizamos regularmente nuestras medidas de seguridad. Puedes estar seguro de que tu data es manejada con el máximo cuidado y confidencialidad. Tu confianza y tranquilidad son esenciales para nosotros.",
  "faq-desc-4":
    "Sí, FullSetters ofrece una integración perfecta con otras plataformas y sistemas para mejorar tu experiencia. Nuestra plataforma está diseñada teniendo en cuenta la flexibilidad, lo que permite una integración suave con API en varias aplicaciones y herramientas de terceros.",
  "faq-desc-5":
    "En FullSetters, valoramos tu experiencia y estamos comprometidos a brindar un excelente soporte al cliente. Si alguna vez necesitas ayuda o tienes preguntas mientras usas nuestra plataforma, nuestro dedicado equipo de soporte está aquí para ayudarte.<0/><0/> Puedes contactarnos a través de nuestra página de contacto o enviarnos un correo electrónico a support@fullsetters.com. Nuestro equipo de expertos responderá rápidamente a tus preguntas, proporcionará orientación y abordará cualquier inquietud que puedas tener.<0/><0/> Entendemos que a veces puede ser desafiante navegar por el proceso de solicitud, y queremos asegurarnos de que tengas una experiencia fluida y exitosa con FullSetters. Ten la seguridad de que siempre estamos aquí para apoyarte en cada paso del camino. Tu satisfacción es nuestra prioridad, y esperamos poder ayudarte en todo lo que necesites.",
  "faq-desc-6":
    "Sí, FullSetters comprende la importancia de la flexibilidad y la conveniencia. Por eso ofrecemos una aplicación móvil fácil de usar que te permite acceder a nuestra plataforma estés donde estés.<0/><0/> Con la aplicación móvil de FullSetters, puedes gestionar tus aplicaciones, actualizar tu perfil y mantenerte conectado con tu proceso de solicitud desde cualquier lugar y en cualquier momento. Ya sea que estés viajando, en el trabajo o simplemente prefieras usar tu dispositivo móvil, nuestra aplicación te permite mantener la productividad y estar comprometido con tu proceso de solicitud.",
  "faq-desc-7":
    "¡Empezar con FullSetters es rápido y sencillo! Sigue estos simples pasos para comenzar tu proceso de solicitud sin problemas:<0/><1><2>Visita nuestro sitio web en www.fullsetters.com y haz clic en el botón 'Comenzar'.</2><2>Crea tu cuenta proporcionando tu dirección de correo electrónico y configurando una contraseña segura.</2><2>Elige un plan que se adapte mejor a tus necesidades: Estudiante, Individual u Organización.</2><2>Disfruta de una prueba gratuita para explorar la plataforma y experimentar sus poderosas funciones.</2><2>Completa tu perfil llenando información esencial, como datos demográficos, educación, habilidades y más.</2><2>Comienza a solicitar las oportunidades que desees, ya sean empleos, apartamentos en alquiler, escuelas, préstamos, inmigración u otros proyectos.</2><2>Relájate y deja que FullSetters simplifique tu proceso de solicitud, ahorrándote tiempo y esfuerzo.</2></1><0/>¡Emprende tu proceso de solicitud con FullSetters hoy y descubre las posibilidades de aplicaciones sin complicaciones! Es hora de tomar el control de tu carrera y futuras oportunidades con facilidad.",
  "quick-links": "Enlaces Rápidos",
  tos: "Términos de Servicio",
  "privacy-policy": "Política de Privacidad",
  "contact-us": "Contáctanos",
  more: "Más",
  "blog-posts": "Entradas de Blog",
  "blog-detail": "Detalle de Blog",
  "account-settings": "Configuración de Cuenta",
  "privacy-text":
    "Este sitio web utiliza cookies para asegurarse de que obtenga la mejor experiencia en nuestro sitio web.",
  close: "Cerrar",
  "manage-your": "Gestionar tu",
  Resume: "Currículum",
  "and-applying-for": "y Solicitar",
  Jobs: "Empleos",
  "has never been this easier.": "nunca ha sido tan fácil.",
  "about-page-description":
    "Lorem ipsum dolor sit amet,sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  "got-some-questions":
    "¿Tienes algunas preguntas? Por favor, ponte en contacto con",
  "our-team": "nuestro Equipo",
  "about-description":
    "En FullSetters, estamos dedicados a empoderar a las personas en sus trayectorias profesionales. Nuestra plataforma revoluciona la forma en que gestionas y solicitas diversas aplicaciones, ya sea para trabajos, apartamentos en alquiler, escuelas, préstamos, inmigración y más. Con FullSetters, ganas un poderoso aliado que simplifica el proceso de solicitud, ahorrándote tiempo y esfuerzo. Creemos que solicitar oportunidades debería ser sin complicaciones y sin problemas, por eso hemos desarrollado una plataforma fácil de usar que simplifica todo el proceso. Nuestra avanzada tecnología e interfaz intuitiva te permiten rellenar formularios de solicitud con facilidad, almacenar documentos importantes y seguir tu progreso todo en un solo lugar. Con FullSetters, puedes descubrir un mundo de posibilidades. Únete a la comunidad de FullSetters hoy y experimenta un nuevo nivel de eficiencia, comodidad y éxito en tus esfuerzos de solicitud. Juntos, navegaremos el proceso de solicitud con facilidad, abriendo puertas a infinitas oportunidades y moldeando tu futuro.",
  "team-description":
    "Nuestro equipo está dedicado a ofrecer un servicio excepcional desde diversos rincones del mundo. Con una perspectiva global y una amplia gama de expertise, nuestro equipo trabaja en colaboración para entregar soluciones innovadoras que satisfagan tus necesidades. Aprovechamos nuestro conocimiento y experiencia colectiva para garantizar que recibas el más alto nivel de apoyo y expertise en la gestión de aplicaciones.",
  "Team of 30 Professionals, working from":
    "Equipo de 30 profesionales, trabajando desde",
  "around the World": "todo el mundo",
  Disclaimer: "Aviso legal",
  Privacy: "Privacidad",
  "How we may help you out?": "¿Cómo podemos ayudarte?",
  "Search for any kind of help": "Busca cualquier tipo de ayuda",
  Search: "Buscar",
  "Featured Topics": "Temas destacados",
  "FAQs?": "¿Preguntas frecuentes?",
  "We’d love to hear from you.": "Nos encantaría saber de ti.",
  "Please get in touch by filling the form.":
    "Por favor, ponte en contacto rellenando el formulario.",
  "Full Name": "Nombre completo",
  "Email Address": "Dirección de correo electrónico",
  Name: "Nombre",
  Email: "Correo electrónico",
  "Your message": "Tu mensaje",
  Submit: "Enviar",
  "Fullsetters Blogs": "Blogs de Fullsetters",
  "Explore the latest news from the job market.":
    "Explora las últimas noticias del mercado laboral.",
  "blog-description":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.",
  Pricing: "Precios",
  "pricing-description":
    "Experimenta el poder de una aplicación sin esfuerzo con la simplificada estructura de precios de Fullsetters y disfruta de una prueba gratuita. Descubre planes personalizados para estudiantes, profesionales y organizaciones, que te empoderarán para desbloquear tu potencial y alcanzar tus metas con facilidad. Elige tu camino hacia el éxito y comienza tu prueba gratuita hoy mismo.",
  "Fullsetters Terms of Service": "Términos del Servicio de Fullsetters",
  "Privacy Policy": "Política de Privacidad",
  Copyright: "Derechos de autor",
  "Module Data": "Datos del Módulo",
  "Please enter your Demographics Details":
    "Por favor ingrese sus Detalles Demográficos",
  Prefix: "Prefijo",
  Suffix: "Sufijo",
  "First Name": "Nombre de pila",
  "Middle Name": "Segundo nombre",
  "Last Name": "Apellido",
  "Other Names": "Otros nombres",
  Demographics: "Demografía",
  "Phone Number": "Número de teléfono",
  "Date of Birth": "Fecha de nacimiento",
  Gender: "Género",
  Male: "Masculino",
  Female: "Femenino",
  Other: "Otro",
  Ethnicity: "Etnicidad",
  Asian: "Asiático",
  European: "Europeo",
  American: "Americano",
  African: "Africano",
  Race: "Raza",
  Brown: "Marrón",
  Black: "Negro",
  White: "Blanco",
  "Active Military?": "¿Activo en el Ejército?",
  Yes: "Sí",
  No: "No",
  "Are you a disabled Veteran?": "¿Es usted un Veterano discapacitado?",
  "Contact info": "Información de contacto",
  "Work Phone": "Teléfono laboral",
  "Home Phone": "Teléfono de casa",
  "Cell Phone": "Teléfono celular",
  "Residential Address": "Dirección de residencia",
  "Street name & number": "Nombre y número de la calle",
  "Address 2 (Optional)": "Dirección 2 (Opcional)",
  City: "Ciudad",
  State: "Estado",
  "ZIP Code": "Código ZIP",
  Country: "País",
  "Billing Address": "Dirección de facturación",
  "Shipping Address": "Dirección de envío",
  "Use Billing Address as Shipping Address":
    "Usar dirección de facturación como dirección de envío",
  Update: "Actualizar",
  Save: "Guardar",
  Cancel: "Cancelar",
  Delete: "Eliminar",
  Education: "Educación",
  "Please enter your Education Details":
    "Por favor ingrese los Detalles de su Educación",
  "School Name": "Nombre de la Escuela",
  Degree: "Grado",
  "School Address": "Dirección de la Escuela",
  Province: "Provincia",
  "Study Details": "Detalles del Estudio",
  Major: "Especialización",
  Minor: "Menor",
  "Starting Date": "Fecha de Inicio",
  "Finishing Date": "Fecha de Finalización",
  "Contact Person": "Persona de Contacto",
  Relationship: "Relación",
  Phone: "Teléfono",
  Experiences: "Experiencias",
  "Please enter your Experiences Details":
    "Por favor ingrese los Detalles de sus Experiencias",
  "Experience Title": "Título de la Experiencia",
  Industry: "Industria",
  Manager: "Gerente",
  Employement: "Empleo",
  "Please enter your Employement details":
    "Por favor ingrese los detalles de su Empleo",
  Employeer: "Empleador",
  "Job Title": "Título del Trabajo",
  "Work Address": "Dirección del Trabajo",
  Skills: "Habilidades",
  "Please enter your Skills details":
    "Por favor ingrese los detalles de sus Habilidades",
  Tools: "Herramientas",
  Standards: "Normas",
  References: "Referencias",
  "Please enter your References Details":
    "Por favor ingrese los Detalles de sus Referencias",
  Certifications: "Certificaciones",
  "Please enter your Certifications Details":
    "Por favor ingrese los Detalles de sus Certificaciones",
  "Certification Title": "Título de la Certificación",
  "Certificate Provider": "Proveedor de la Certificación",
  Highlights: "Aspectos Destacados",
  Others: "Otros",
  "Please enter any other details that you want.":
    "Por favor ingrese cualquier otro detalle que desee.",
  Title: "Título",
  Description: "Descripción",
  "Residential History": "Historial Residencial",
  "Please enter your residential history":
    "Por favor ingrese su historial residencial",
  "Go Back To Demographics": "Volver a Datos Demográficos",
  Payment: "Pago",
  "Your Visa Card": "Tu tarjeta Visa",
  "Your Mastercard": "Tu tarjeta Mastercard",
  "Card Number": "Número de tarjeta",
  "Expiration Date": "Fecha de vencimiento",
  CVC: "CVC",
  "Rental Apartments": "Apartamentos en Alquiler",
  "terms-title":
    'Estos Términos de Servicio ("Términos") rigen su uso de los servicios, sitio web y aplicaciones móviles de FullSetters (referidos colectivamente como el "Servicio"). Al acceder o usar el Servicio, acepta estar vinculado por estos Términos.',
  "user-responsibilities": "Responsabilidades del Usuario",
  "accurate-info":
    "Debe proporcionar información precisa y actualizada durante el proceso de registro.",
  "account-confidentiality":
    "Es responsable de mantener la confidencialidad de sus credenciales de cuenta y de cualquier actividad que ocurra bajo su cuenta.",
  "unlawful-activities":
    "Acepta no participar en actividades ilegales o no autorizadas mientras utiliza el Servicio.",
  "use-of-service-title": "Uso del Servicio",
  "non-exclusive-license":
    "FullSetters le otorga una licencia no exclusiva y no transferible para usar el Servicio con fines personales o comerciales, según lo previsto.",
  "no-modify-service":
    "No puede copiar, modificar, distribuir, vender o alquilar ninguna parte del Servicio sin el consentimiento previo por escrito de FullSetters.",
  "third-party-services":
    "Reconoce que FullSetters puede usar servicios y recursos de terceros para proporcionar el Servicio.",
  "intellectual-property-title": "Propiedad Intelectual",
  "fullsetters-rights":
    "FullSetters retiene todos los derechos, título e interés en y para el Servicio, incluidos todos los derechos de propiedad intelectual.",
  "no-use-trademarks":
    "No puede usar las marcas comerciales, logos u otra información propietaria de FullSetters sin permiso previo por escrito.",
  "privacy-title": "Privacidad y Datos",
  "privacy-policy-text":
    "FullSetters respeta su privacidad y maneja su información personal de acuerdo con su Política de Privacidad.",
  "consent-to-collection":
    "Al usar el Servicio, consiente la recopilación, uso y divulgación de su información personal según lo descrito en la Política de Privacidad.",
  "liability-title": "Limitaciones de Responsabilidad",
  "no-guarantee":
    "FullSetters se esfuerza por proporcionar información precisa y confiable, pero no garantiza la integridad, precisión o fiabilidad del Servicio.",
  "not-liable":
    "FullSetters no será responsable por ningún daño directo, indirecto, incidental o consecuente resultante de su uso del Servicio.",
  "termination-title": "Terminación",
  "right-to-terminate":
    "FullSetters se reserva el derecho de suspender o terminar su acceso al Servicio en cualquier momento, por cualquier motivo, sin previo aviso.",
  "data-deletion":
    "Al terminar, su acceso al Servicio y cualquier dato asociado puede ser eliminado permanentemente.",
  "modifications-title": "Modificaciones a los Términos",
  "right-to-modify":
    "FullSetters se reserva el derecho de modificar o actualizar estos Términos en cualquier momento.",
  "review-terms":
    "Es su responsabilidad revisar los Términos periódicamente para cualquier cambio.",
  "final-agreement":
    "Al usar el Servicio de FullSetters, acepta cumplir con estos Términos de Servicio. Si no está de acuerdo con alguna parte de estos Términos, por favor deje de usar el Servicio.",
  "blog-title": "Blogs de Fullsetters",
  "blog-subtitle": "Explora las últimas noticias del mercado laboral.",
  "blog-card1-title": "¿Trabajando en múltiples trabajos remotos?",
  "blog-card1-desc":
    "Descubre los pros y contras de trabajar en múltiples trabajos remotos, desde gestionar tu horario hasta maximizar tu productividad y aprovecha los beneficios ...",
  "blog-card2-title":
    "¿Cómo averiguar si un empleador potencial ofrece licencia remunerada?",
  "blog-card2-desc":
    "Descubre las estrategias esenciales para determinar si un empleador potencial ofrece licencia remunerada y asegúrate de que la tengas ...",
  "blog-card3-title":
    "Cómo el emprendimiento puede ayudarte a destacar en el trabajo",
  "blog-card3-desc":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "blog-card4-title": "Cómo explicar por qué eres el adecuado para el trabajo",
  "blog-card4-desc":
    "Ya sea que estés hablando con el reclutador o estés en las etapas finales del proceso de entrevista, sabes que tienes lo necesario para el puesto ...",
  "blog-card5-title":
    "Consejos para superar los nervios en una entrevista de trabajo",
  "blog-card5-desc":
    "Supera los nervios de una entrevista de trabajo con estos consejos de expertos: aprende técnicas de relajación efectivas, prepárate a fondo con entrevistas simuladas y enfócate en tus calificaciones ...",
  "blog-card6-title":
    "Cómo saber que tu currículum está listo para ser enviado",
  "blog-card6-desc":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "blog-card7-title": "Cómo terminar una conversación de networking con tacto",
  "blog-card7-desc":
    "Domina el arte de terminar conversaciones de networking con tacto con estos consejos: Exprésate agradecido por la valiosa ...",
  "blog-card8-title":
    "Cómo hacer un seguimiento de las propuestas freelance sin ser molesto",
  "blog-card8-desc":
    "Navega la delgada línea de hacer seguimiento a propuestas freelance sin ser molesto empleando estas estrategias ...",
  "blog-card9-title": "Cómo trabajar desde casa puede reducir el estrés",
  "blog-card9-desc":
    "Descubre los beneficios de reducir el estrés trabajando desde casa: disfruta de un horario flexible que promueva el equilibrio entre trabajo y vida personal, elimina los estresores relacionados con el desplazamiento ...",
  "blog-card-timestamp": "hace 4 días",
  "future-achiever-title": "Logro Futuro",
  "for-students": "Para Estudiantes",
  "future-achiever-description":
    "Navega tu camino hacia el éxito con el Plan Estudiantil de Fullsetters y disfruta de una prueba gratuita",
  "get-started-now": "Comenzar Ahora",
  "career-catalyst-title": "Catalizador de Carrera",
  "for-individuals": "Para Individuos",
  "career-catalyst-description":
    "Desbloquea acceso ilimitado a todas las funciones con nuestra suscripción asequible. Regístrate para una prueba gratuita",
  "enterprise-innovator-title": "Innovador Empresarial",
  "for-organizations": "Para Organizaciones",
  "enterprise-innovator-description":
    "Soluciones a medida para empresas y organizaciones con necesidades únicas. Comienza con una prueba gratuita",

  "student-plan-0": "Acceso a todas las categorías de aplicaciones",
  "student-plan-1": "100 envíos de aplicaciones por mes",
  "student-plan-2": "Alertas de búsqueda de empleo",
  "student-plan-3": "Seguimiento básico de aplicaciones",
  "student-plan-4": "Soporte al cliente estándar",
  "student-plan-5": "Gestión de currículums",
  "individual-plan-0": "Acceso a todas las categorías de aplicaciones",
  "individual-plan-1": "Envíos de aplicaciones ilimitados",
  "individual-plan-2": "Seguimiento avanzado de aplicaciones",
  "individual-plan-3": "Soporte al cliente prioritario",
  "individual-plan-4": "Plantillas de currículum personalizables",
  "individual-plan-5": "Análisis e información de currículum",
  "enterprise-plan-0": "Herramientas de colaboración en equipo",
  "enterprise-plan-1": "Acceso a todas las categorías de aplicaciones",
  "enterprise-plan-2": "Envíos de aplicaciones ilimitados",
  "enterprise-plan-3": "Seguimiento y análisis de aplicaciones premium",
  "enterprise-plan-4":
    "Integración de API para flujos de trabajo sin problemas",
  "enterprise-plan-5": "Soporte y orientación personalizados",

  // blog-1 content

  "blog-1-summary":
    "En el mercado laboral en constante evolución de hoy en día, el concepto de trabajar en múltiples empleos remotos ha ganado una tracción significativa. Con el auge de las oportunidades de trabajo a distancia y el deseo de una mayor flexibilidad y diversas fuentes de ingresos, cada vez más personas están explorando la opción de equilibrar múltiples empleos remotos. Sin embargo, navegar por esta configuración de empleo única requiere una consideración y planificación cuidadosas. En este blog, profundizaremos en los elementos esenciales de trabajar en múltiples empleos remotos, cubriendo aspectos importantes como la gestión del tiempo, la productividad, los desafíos potenciales y los beneficios generales de este acuerdo.",
  "blog-1-headingOne": "Comprender la gestión del tiempo:",
  "blog-1-contentOne":
    "Uno de los factores clave para gestionar con éxito múltiples empleos remotos es la gestión efectiva del tiempo. Equilibrar múltiples roles, plazos y responsabilidades puede ser exigente, pero con las estrategias adecuadas, se vuelve alcanzable. Exploraremos técnicas como la priorización, la programación y la creación de límites para optimizar su tiempo y asegurarse de cumplir con sus compromisos.",
  "blog-1-headingTwo": "Maximizar la productividad:",
  "blog-1-contentTwo":
    "Mantener la productividad mientras se equilibran múltiples empleos remotos es crucial para cumplir con los plazos y alcanzar el éxito. Proporcionaremos consejos e ideas prácticas para aumentar la productividad, incluida la organización de tareas, el establecimiento de metas realistas, el aprovechamiento de herramientas de productividad y la adopción de hábitos laborales eficientes. Descubra cómo aprovechar al máximo su tiempo y energía para sobresalir en todos sus esfuerzos laborales a distancia.",
  "blog-1-headingThree": "Superar los desafíos:",
  "blog-1-contentThree":
    "Trabajar en múltiples empleos remotos puede presentar ciertos desafíos que deben abordarse de manera proactiva. Discutiremos obstáculos comunes como posibles conflictos de intereses, horarios superpuestos y la gestión de la carga de trabajo. Al explorar estrategias para una comunicación efectiva, establecer expectativas claras y encontrar un equilibrio entre diferentes roles, puede navegar por estos desafíos y mitigar su impacto en su trabajo.",
  "blog-1-headingFour": "Beneficios de diversas fuentes de ingresos:",
  "blog-1-contentFour":
    "Una de las principales motivaciones para buscar múltiples empleos remotos es el potencial de aumentar los ingresos y la estabilidad financiera. Examinaremos las ventajas de diversificar sus fuentes de ingresos, incluida la capacidad de explorar diferentes intereses profesionales, ampliar su conjunto de habilidades y asegurar múltiples flujos de ingresos. Descubra las oportunidades de crecimiento financiero y profesional que pueden ofrecer los múltiples empleos remotos.",
  "blog-1-headingFive": "Lograr la integración trabajo-vida:",
  "blog-1-contentFive":
    "Mantener un equilibrio saludable entre el trabajo y la vida personal es esencial, incluso cuando se trabaja en múltiples empleos remotos. Exploraremos estrategias para evitar el agotamiento, priorizar el autocuidado y establecer límites claros entre el trabajo y la vida personal. Al adoptar un enfoque holístico para la integración trabajo-vida, puede aprovechar los beneficios de la flexibilidad y la libertad mientras mantiene su bienestar.",
  "blog-1-conclusionheading": "Conclusión:",
  "blog-1-concluisonContent":
    "Trabajar en múltiples empleos remotos puede ser una elección de carrera satisfactoria y gratificante, pero requiere una planificación cuidadosa, una gestión efectiva del tiempo y un enfoque proactivo para abordar desafíos. Al implementar las estrategias descritas en este blog, puede desbloquear el potencial de trabajar en múltiples empleos remotos, disfrutar de las ventajas de diversas fuentes de ingresos y crear un equilibrio armonioso entre el trabajo y la vida personal. Aproveche las oportunidades que ofrece el trabajo a distancia y emprenda un viaje satisfactorio y exitoso trabajando en múltiples empleos remotos. Recuerde que, con el conocimiento y la mentalidad adecuados, puede prosperar en esta configuración laboral única y construir una carrera satisfactoria que se alinee con sus metas y aspiraciones.",

  // blog-2 content

  "blog-2-summary":
    "El permiso remunerado es un beneficio importante que los empleados valoran, ya que les proporciona el tiempo necesario para recargar energías, atender asuntos personales y lograr un equilibrio entre el trabajo y la vida. Al considerar un posible empleador, es crucial determinar si ofrecen permiso remunerado y comprender los detalles de su política al respecto. En esta entrada de blog, te guiaremos a través del proceso para averiguar si un posible empleador ofrece permiso remunerado y te ayudaremos a tomar decisiones informadas sobre tu futura empleabilidad.",
  "blog-2-headingOne": "Investiga la Compañía:",
  "blog-2-contentOne":
    "Comienza investigando el sitio web de la compañía, el manual del empleado o el portal de empleo. Busca información sobre sus políticas de permisos, beneficios para empleados y asignaciones de tiempo libre. Algunas compañías pueden declarar abiertamente su política de permisos remunerados, incluyendo la cantidad de días ofrecidos y cualquier criterio de elegibilidad. Toma nota de esta información y evalúa si se ajusta a tus necesidades y expectativas.",
  "blog-2-headingTwo": "Revisa Anuncios de Trabajo y Cartas de Oferta:",
  "blog-2-contentTwo":
    "Presta mucha atención a los anuncios de trabajo y las cartas de oferta. Los empleadores a menudo mencionan sus beneficios de permiso, que incluyen vacaciones remuneradas, permisos por enfermedad u otras disposiciones de tiempo libre. Lee detenidamente los detalles proporcionados, ya que pueden brindarte información sobre el enfoque de la compañía en cuanto al permiso remunerado. Si no se menciona información específica, es esencial preguntar durante el proceso de entrevista o negociación.",
  "blog-2-headingThree": "Conéctate con Empleados Actuales o Anteriores:",
  "blog-2-contentThree":
    "Comunícate con empleados actuales o anteriores de la compañía a través de plataformas de redes profesionales o conexiones personales. Participa en conversaciones sobre las políticas de permisos de la empresa e indaga sobre sus experiencias con el permiso remunerado. Esta información de primera mano puede proporcionar información valiosa y ayudarte a evaluar el compromiso del empleador con el equilibrio entre el trabajo y la vida.",
  "blog-2-headingFour": "Busca Claridad Durante las Entrevistas:",
  "blog-2-contentFour":
    "Utiliza el proceso de entrevista como una oportunidad para hacer preguntas sobre la política de permisos remunerados de la empresa. Pregunta sobre la cantidad de días de vacaciones, permisos por enfermedad u otros tipos de tiempo libre remunerado proporcionados. Además, pregunta sobre cualquier requisito o restricción específica en cuanto a la utilización del permiso remunerado. Al buscar claridad, puedes asegurarte de tener una comprensión completa de los beneficios ofrecidos.",
  "blog-2-headingFive":
    "Consulta con el Departamento de Recursos Humanos o los Gerentes de Contratación:",
  "blog-2-contentFive":
    "Una vez que avances en el proceso de contratación, considera ponerte en contacto con el departamento de Recursos Humanos de la empresa o los gerentes de contratación. Busca su orientación y haz preguntas específicas sobre la política de permisos remunerados de la empresa. Pueden proporcionar información detallada, abordar cualquier inquietud y aclarar cualquier ambigüedad que puedas tener.",
  "blog-2-conclusionheading": "Conclusión:",
  "blog-2-concluisonContent":
    "Determinar si un posible empleador ofrece permiso remunerado es un aspecto crucial en la evaluación de oportunidades laborales. Al llevar a cabo una investigación exhaustiva, establecer conexiones, hacer preguntas relevantes durante las entrevistas y buscar orientación del departamento de Recursos Humanos o los gerentes de contratación, puedes obtener una comprensión completa de la política de permisos remunerados de la empresa. Recuerda que el permiso remunerado es un beneficio esencial que contribuye a tu satisfacción laboral general y tu equilibrio entre el trabajo y la vida.",

  // content-3:

  "blog-3-summary":
    "En el entorno laboral dinámico y competitivo de hoy en día, destacar y diferenciarse es crucial para el crecimiento y el avance profesionales. Adoptar una mentalidad emprendedora puede marcar la diferencia, empoderándote para aportar innovación, creatividad y un enfoque proactivo a tu trabajo. En esta entrada de blog, exploraremos cómo el emprendimiento puede ayudarte a destacar en el trabajo y llevar tu carrera a nuevas alturas. ¡Vamos allá! 🚀",
  "blog-3-headingOne": "Adopta una Mentalidad de Crecimiento:",
  "blog-3-contentOne":
    "El emprendimiento tiene sus raíces en una mentalidad de crecimiento, donde las personas buscan constantemente nuevas oportunidades, aprenden de los fracasos y enfrentan desafíos. Adopta esta mentalidad en tu vida profesional buscando nuevos proyectos, tomando riesgos calculados y ampliando constantemente tus habilidades y conocimientos. Al adoptar una mentalidad de crecimiento, te posicionas como una persona proactiva y con visión de futuro dentro de tu organización.",
  "blog-3-headingTwo": "Cultiva un Espíritu de Innovación:",
  "blog-3-contentTwo":
    "Los emprendedores son conocidos por su capacidad de innovar y pensar de manera creativa. Aplica este espíritu emprendedor a tu trabajo buscando activamente soluciones innovadoras, proponiendo nuevas ideas y desafiando el statu quo. Al aportar perspectivas frescas y soluciones creativas, demuestras tu valor y te destacas como un activo para tu equipo y tu organización.",
  "blog-3-headingThree": "Asume la Responsabilidad de tus Proyectos:",
  "blog-3-contentThree":
    "Los emprendedores asumen la plena responsabilidad de sus proyectos, desde su concepción hasta su ejecución. Aplica el mismo nivel de responsabilidad a tus proyectos en el trabajo. Sé proactivo, toma la iniciativa y esfuérzate al máximo para ofrecer resultados excepcionales. Demuestra tu compromiso, fiabilidad y capacidad para llevar los proyectos adelante, diferenciándote como un profesional autodirigido y responsable.",
  "blog-3-headingFour": "Fomenta una Mentalidad de Colaboración:",
  "blog-3-contentFour":
    "Los emprendedores exitosos comprenden el poder de la colaboración y la construcción de redes sólidas. Aplica este principio en el trabajo buscando activamente oportunidades para colaborar con colegas, equipos interfuncionales y partes interesadas externas. Al aprovechar la experiencia colectiva y las diversas perspectivas, mejoras tu capacidad para ofrecer resultados sobresalientes y te destacas como un jugador de equipo y colaborador influyente.",
  "blog-3-headingFive": "Aprende y Adapta Continuamente:",
  "blog-3-contentFive":
    "El emprendimiento es un viaje de aprendizaje y adaptación continua. Abraza esta mentalidad buscando oportunidades de aprendizaje, asistiendo a talleres, conferencias o adquiriendo nuevas habilidades. Mantente actualizado con las tendencias de la industria, los avances tecnológicos y las mejores prácticas emergentes. Al posicionarte como un aprendiz de por vida, demuestras tu compromiso con el crecimiento y el desarrollo, diferenciándote de tus compañeros.",
  "blog-3-conclusionheading": "Conclusión:",
  "blog-3-concluisonContent":
    "El emprendimiento no se limita a comenzar tu propio negocio. Es una mentalidad que se puede cultivar y aplicar en cualquier entorno profesional. Al adoptar un espíritu emprendedor, puedes destacar en el trabajo, aportar perspectivas frescas, fomentar la innovación, asumir la responsabilidad de tus proyectos, colaborar de manera efectiva y aprender y adaptarte continuamente. Desbloquea tu potencial emprendedor y observa cómo tu carrera se eleva a nuevas alturas mientras dejas una huella duradera en tu organización.",

  // blog-4 content

  "blog-4-summary":
    "Cuando se trata de conseguir el trabajo de tus sueños, comunicar de manera efectiva por qué eres la persona ideal es crucial. En esta entrada de blog, exploraremos estrategias y consejos sobre cómo explicar con confianza por qué eres adecuado para el trabajo. Desde resaltar tus habilidades y experiencia hasta mostrar tu pasión y alineación con los valores de la empresa, lo cubriremos todo. ¡Prepárate para dejar una impresión duradera y destacarte entre la competencia!",
  "blog-4-headingOne": "Conócete a ti Mismo y tus Fortalezas:",
  "blog-4-contentOne":
    "Tómate el tiempo para reflexionar sobre tus habilidades, experiencias y logros que se alinean con los requisitos del trabajo. Identifica tus fortalezas únicas y lo que te diferencia de otros candidatos. Esta autoconciencia te permitirá articular con confianza por qué eres la persona adecuada para el trabajo.",
  "blog-4-headingTwo": "Investiga la Empresa:",
  "blog-4-contentTwo":
    "Obtén un profundo conocimiento de la misión, los valores y la cultura de la empresa. Adapta tu explicación para demostrar cómo tus habilidades y valores se alinean con los suyos. Destaca tu conocimiento sobre la empresa para mostrar tu interés genuino y compromiso.",
  "blog-4-headingThree": "Adapta tu Presentación:",
  "blog-4-contentThree":
    "Crea una presentación convincente que resalte tus habilidades, experiencias y logros relevantes. Enfatiza cómo estos se alinean con las necesidades específicas del trabajo y de la empresa. Utiliza ejemplos concretos y métricas para cuantificar tus logros y mostrar tu impacto.",
  "blog-4-headingFour": "Muestra tu Pasión:",
  "blog-4-contentFour":
    "Expresa tu entusiasmo y pasión genuinos por la industria, la empresa y el rol. Explica cómo tu pasión impulsa tu motivación y disposición para ir más allá. Los gerentes de contratación aprecian a los candidatos que demuestran un interés genuino en el trabajo.",
  "blog-4-headingFive": "Conecta los Puntos:",
  "blog-4-contentFive":
    "Une el puente entre tus habilidades, experiencias y los requisitos del trabajo. Articula claramente cómo tu experiencia te convierte en el candidato ideal para abordar los desafíos y contribuir al éxito de la empresa. Destaca tus habilidades transferibles y demuestra tu capacidad para adaptarte y aprender rápidamente.",
  "blog-4-headingSix": "Practica, Practica, Practica:",
  "blog-4-contentSix":
    "Prepara y ensaya tu explicación para asegurarte de la claridad y la confianza. Practica con un amigo o mentor que pueda brindarte retroalimentación y ayudarte a pulir tu mensaje. Cuanto más practiques, más natural y convincente se volverá tu explicación.",
  "blog-4-conclusionheading": "Conclusión:",
  "blog-4-concluisonContent":
    "Explicar de manera efectiva por qué eres adecuado para el trabajo es un paso crucial en el proceso de solicitud de empleo. Conociéndote a ti mismo, investigando la empresa, adaptando tu presentación, mostrando tu pasión, conectando los puntos y practicando tu explicación, puedes articular con confianza tu idoneidad para el trabajo. Destácate entre la competencia y deja una impresión duradera con tu explicación convincente y bien preparada.",

  // blog-5 content

  "blog-5-summary":
    "Las entrevistas de trabajo pueden ser estresantes, pero con las estrategias adecuadas, puedes superar esos nervios y presentarte con confianza. En esta entrada de blog, compartiremos consejos valiosos para ayudarte a superar esos nervios en la entrevista y mostrar tu mejor versión. Desde técnicas de preparación hasta ejercicios para aumentar la confianza, te tenemos cubierto. ¡Prepárate para destacarte en tu próxima entrevista de trabajo!",
  "blog-5-headingOne": "Prepararse, Prepararse, Prepararse:",
  "blog-5-contentOne":
    "Investiga a fondo la empresa, revisa la descripción del trabajo y anticipa preguntas comunes en entrevistas. Practica tus respuestas y ten ejemplos listos para mostrar tus habilidades y experiencias. Cuanto más preparado estés, más confianza sentirás durante la entrevista.",
  "blog-5-headingTwo": "Practicar Entrevistas Simuladas:",
  "blog-5-contentTwo":
    "Simula escenarios reales de entrevistas practicando con un amigo, familiar o mentor. Concéntrate en responder preguntas con claridad, mantener un buen contacto visual y proyectar confianza. La práctica te ayudará a sentirte más cómodo con el proceso de entrevista.",
  "blog-5-headingThree": "Visualizar el Éxito:",
  "blog-5-contentThree":
    "Tómate unos momentos antes de la entrevista para visualizarte respondiendo preguntas con confianza y causando una impresión positiva. Imagina que la entrevista transcurre sin problemas y visualízate teniendo éxito. Visualizar el éxito puede ayudar a aumentar tu confianza y reducir la ansiedad.",
  "blog-5-headingFour": "Técnicas de Respiración Profunda y Relajación:",
  "blog-5-contentFour":
    "Practica ejercicios de respiración profunda para calmar tus nervios y relajar tu cuerpo. Antes de la entrevista, respira profundamente, mantén la respiración durante unos segundos y exhala lentamente. Esta técnica sencilla puede ayudar a reducir la ansiedad y promover una sensación de calma.",
  "blog-5-headingFive": "Enfoque en el Diálogo Positivo Contigo Mismo:",
  "blog-5-contentFive":
    "Reemplaza los pensamientos negativos y la autoduda con afirmaciones positivas. Recuérdate tus habilidades, calificaciones y logros pasados. Dite a ti mismo que estás preparado y capacitado. El diálogo positivo contigo mismo puede ayudar a construir confianza y cambiar tu mentalidad hacia una perspectiva más positiva.",
  "blog-5-headingSix": "Vístete con Confianza:",
  "blog-5-contentSix":
    "Viste con ropa profesional que te haga sentir seguro y cómodo. Cuando te sientes bien con tu apariencia, puede tener un impacto positivo en tu mentalidad y aumentar tu confianza durante la entrevista. Vestirse apropiadamente muestra respeto por la oportunidad y demuestra tu profesionalismo.",
  "blog-5-conclusionheading": "Conclusión:",
  "blog-5-concluisonContent":
    "Los nervios en las entrevistas de trabajo son comunes, pero no tienen por qué obstaculizar tu éxito. Siguiendo estos consejos, puedes superar el nerviosismo, aumentar tu confianza y presentarte como el mejor candidato para el trabajo. Recuerda que la preparación, la práctica, el diálogo positivo contigo mismo y las técnicas de relajación son tus aliados para superar los nervios en las entrevistas de trabajo. Aprovecha la oportunidad, muestra tus habilidades y deja que tu confianza brille!",

  // blog-6 content

  "blog-6-summary":
    "Tu currículum es una herramienta crucial en tu búsqueda de empleo, ya que representa tus habilidades, experiencias y calificaciones ante posibles empleadores. Pero, ¿cómo saber si tu currículum está realmente listo para ser enviado? En esta entrada de blog, te guiaremos a través de indicadores clave de que tu currículum está listo para la entrevista. Desde el formato y el contenido hasta la relevancia y el impacto, te ayudaremos a asegurarte de que tu currículum se destaque entre la competencia. ¡Empecemos!",
  "blog-6-headingOne": "Presentación Clara y Concisa:",
  "blog-6-contentOne":
    "Verifica que el formato sea claro y conciso, asegurándote de que tu currículum sea fácil de leer y visualmente atractivo. Utiliza fuentes consistentes, viñetas y un espaciado adecuado. Un currículum bien organizado y visualmente atractivo demuestra profesionalismo y atención al detalle.",
  "blog-6-headingTwo": "Contenido Relevante y Personalizado:",
  "blog-6-contentTwo":
    "Revisa tu currículum para asegurarte de que resalte habilidades, experiencias y logros relevantes que se ajusten al trabajo para el que estás aplicando. Adapta tu contenido para que coincida con los requisitos específicos del trabajo y enfatiza las calificaciones más relevantes. Personaliza tu currículum para cada solicitud para maximizar su impacto.",
  "blog-6-headingThree": "Cuantifica tus Logros:",
  "blog-6-contentThree":
    "Siempre que sea posible, incluye logros cuantificables para mostrar tu impacto. Utiliza números, porcentajes o métricas para demostrar los resultados de tu trabajo. Cuantificar tus logros añade credibilidad y ayuda a los empleadores a entender el valor que puedes aportar a su organización.",
  "blog-6-headingFour": "Sin Errores y Corregido:",
  "blog-6-contentFour":
    "Revisa minuciosamente tu currículum para eliminar cualquier error de ortografía, gramática o puntuación. Un solo error tipográfico puede causar una impresión negativa. Tómate el tiempo para revisar cuidadosamente cada sección, asegurando precisión y coherencia. Considera pedir a un amigo de confianza o mentor que aporte un nuevo punto de vista.",
  "blog-6-headingFive": "Palabras Clave Fuertes y Relevantes:",
  "blog-6-contentFive":
    "Incorpora palabras clave y frases específicas de la industria relevantes para el trabajo que estás buscando. Muchos empleadores utilizan sistemas de seguimiento de solicitantes (ATS) para escanear currículums en busca de palabras clave, por lo que optimizar tu currículum con las palabras clave adecuadas puede aumentar tus posibilidades de ser notado.",
  "blog-6-headingSix": "Resumen u Objetivo Impactante:",
  "blog-6-contentSix":
    "Crea un resumen u declaración de objetivo convincente al comienzo de tu currículum. Esta sección debe proporcionar una descripción concisa de tus habilidades, experiencias y objetivos profesionales. Un resumen bien elaborado captura la atención de los gerentes de contratación y los incentiva a seguir leyendo.",
  "blog-6-conclusionheading": "Conclusión:",
  "blog-6-concluisonContent":
    "Saber cuándo tu currículum está listo para la entrevista es esencial para una búsqueda de empleo exitosa. Al garantizar una presentación clara y concisa, contenido relevante y personalizado, logros cuantificados, corrección de errores, palabras clave sólidas y un resumen u objetivo impactante, puedes enviar tu currículum a posibles empleadores con confianza. Dedica tiempo a revisar y perfeccionar tu currículum, convirtiéndolo en una herramienta poderosa que muestre tus calificaciones y te destaque entre otros candidatos. ¡Tu currículum está listo para brillar ahora!",

  // blog-7 content

  "blog-7-summary":
    "Las conversaciones de networking pueden ser enriquecedoras y valiosas para construir relaciones profesionales. Sin embargo, saber cómo concluir una conversación de networking con elegancia es tan importante como causar una primera impresión memorable. En esta entrada de blog, exploraremos estrategias y consejos efectivos sobre cómo finalizar una conversación de networking con tacto. Desde expresar gratitud hasta establecer pasos a seguir claros, te proporcionaremos las herramientas para dejar una impresión positiva duradera. ¡Empecemos!",
  "blog-7-headingOne": "Expresar Gratitud:",
  "blog-7-contentOne":
    "Agradece a la persona por su tiempo, ideas y valiosa conversación. Expresa un agradecimiento sincero por la oportunidad de conectar y aprender de ellos. La gratitud deja una impresión positiva y muestra tu respeto por su tiempo y experiencia.",
  "blog-7-headingTwo": "Resumir los Puntos Clave:",
  "blog-7-contentTwo":
    "Resume los temas principales discutidos durante la conversación. Esto demuestra escucha activa y muestra que estuviste comprometido en la discusión. Resumir los puntos clave también ayuda a reforzar el valor de la conversación y deja una impresión duradera.",
  "blog-7-headingThree": "Intercambiar Información de Contacto:",
  "blog-7-contentThree":
    "Si aún no lo has hecho, intercambia información de contacto con la persona con la que estás haciendo networking. Esto puede incluir tarjetas de presentación, direcciones de correo electrónico o nombres de usuario en redes sociales. Tener su información de contacto te permite continuar la conversación y dar seguimiento en el futuro.",
  "blog-7-headingFour": "Establecer Pasos a Seguir Claros:",
  "blog-7-contentFour":
    "Habla de posibles pasos a seguir o acciones en las que ambos estuvieron de acuerdo durante la conversación. Esto podría incluir programar una reunión de seguimiento, compartir recursos adicionales o conectarse en un tema específico de interés. Establecer pasos a seguir claros asegura que la conversación no termine abruptamente y establece una base para futuras interacciones.",
  "blog-7-headingFive": "Terminar con un Cierre Cortés:",
  "blog-7-contentFive":
    'Concluye la conversación con un cierre cortés y profesional. Usa frases como "Fue un placer conectar contigo" o "Espero mantenernos en contacto." Un cierre positivo deja una impresión final de profesionalismo y deja la puerta abierta para futuras interacciones.',
  "blog-7-conclusionheading": "Conclusión:",
  "blog-7-concluisonContent":
    "Finalizar una conversación de networking con tacto es tan importante como la conexión inicial. Al expresar gratitud, resumir puntos clave, intercambiar información de contacto, establecer pasos a seguir claros, expresar interés en una futura conexión y concluir con un cierre cortés, puedes dejar una impresión positiva duradera y construir relaciones profesionales valiosas. Domina el arte de las despedidas elegantes y observa cómo prosperan tus esfuerzos de networking.",

  // blog-8 content

  "blog-8-summary":
    "Como freelancer, dar seguimiento a tus propuestas es crucial para asegurar nuevas oportunidades y construir relaciones con clientes. Sin embargo, encontrar el equilibrio entre la persistencia y evitar ser percibido como molesto puede ser desafiante. En esta entrada de blog, exploraremos estrategias efectivas y consejos sobre cómo dar seguimiento a tus propuestas como freelancer sin cruzar la línea. Desde el momento adecuado y la personalización hasta la creación de mensajes persuasivos, te empoderaremos para nutrir tus propuestas con profesionalismo. ¡Comencemos!",
  "blog-8-headingOne": "El Momento es Clave:",
  "blog-8-contentOne":
    "Elige un plazo apropiado para tu seguimiento. Evita ser demasiado insistente al dar tiempo suficiente al destinatario para revisar tu propuesta inicial. Dependiendo de la naturaleza del proyecto, realiza el seguimiento después de unos días o una semana. Encontrar el momento adecuado muestra respeto por la agenda del destinatario mientras mantienes tu entusiasmo por la oportunidad.",
  "blog-8-headingTwo": "Personaliza tu Mensaje:",
  "blog-8-contentTwo":
    "Adapta tu mensaje de seguimiento a cada cliente o posible cliente. Haz referencia a detalles específicos de tu propuesta inicial o conversaciones previas para demostrar tu atención y un interés personal en su proyecto. La personalización muestra que te importan genuinamente sus necesidades y estás comprometido con la colaboración potencial.",
  "blog-8-headingThree": "Mantén la Concisión y Claridad:",
  "blog-8-contentThree":
    "Crea un mensaje de seguimiento conciso y claro que vaya al grano. Indica el propósito de tu correo electrónico, recuerda al destinatario tu propuesta inicial y expresa tu interés continuo en el proyecto. Evita correos electrónicos largos que puedan abrumar o disuadir al lector. Mantén el enfoque, sé persuasivo y fácil de comprender.",
  "blog-8-headingFour": "Muestra Valor y Experiencia:",
  "blog-8-contentFour":
    "Destaca tu propuesta de valor única y tu experiencia en tu mensaje de seguimiento. Comparte ejemplos relevantes de tu trabajo, historias de éxito o testimonios que muestren tus capacidades y demuestren cómo puedes contribuir a su proyecto. Presentar tus habilidades y experiencia refuerza tu profesionalismo y te ayuda a diferenciarte de otros freelancers.",
  "blog-8-headingFive": "Ofrece Soluciones o Sugerencias:",
  "blog-8-contentFive":
    "Ve más allá de un simple seguimiento ofreciendo ideas, sugerencias o soluciones adicionales relacionadas con su proyecto. Muestra que has invertido tiempo y pensamiento en sus necesidades específicas y estás comprometido en ayudarlos a alcanzar sus objetivos. Brindar valor y esforzarte un poco más puede dejar una impresión duradera y aumentar tus posibilidades de asegurar la oportunidad.",
  "blog-8-headingSix": "Solicita Comentarios con Respeto:",
  "blog-8-contentSix":
    "Invita a recibir comentarios del cliente o posible cliente sobre tu propuesta inicial. Pregunta si hay algo que puedas aclarar o proporcionar información adicional. Buscar comentarios no solo demuestra tu disposición a mejorar, sino que también fomenta la participación y el diálogo, promoviendo una relación colaborativa.",
  "blog-8-conclusionheading": "Conclusión:",
  "blog-8-concluisonContent":
    "Dar seguimiento a tus propuestas como freelancer es una parte vital de tu éxito. Dominando el arte del seguimiento profesional, puedes nutrir tus propuestas sin ser percibido como molesto. Recuerda encontrar el momento adecuado, personalizar tus mensajes, mantenerlos concisos y claros, mostrar valor y experiencia, ofrecer soluciones o sugerencias y solicitar comentarios con respeto. Con estas estrategias en tu caja de herramientas, elevarás tu juego de seguimiento y aumentarás tus posibilidades de convertir propuestas en oportunidades gratificantes como freelancer. ¡Buena suerte!",

  // blog-9 content

  "blog-9-summary":
    "La rutina tradicional de trabajo de nueve a cinco en la oficina ya no es la única forma de trabajar. Con el auge del trabajo remoto, más personas descubren los numerosos beneficios que ofrece, incluida una reducción significativa en los niveles de estrés. En esta entrada de blog, exploraremos cómo trabajar desde casa puede ser un factor determinante para reducir el estrés y mejorar el bienestar general. Desde la flexibilidad y el equilibrio entre el trabajo y la vida personal hasta la mayor autonomía y la eliminación de los desplazamientos, profundizaremos en las formas en que el trabajo remoto puede ayudarte a lograr una integración laboral y personal más saludable y armoniosa. ¡Empecemos!",
  "blog-9-headingOne": "Horario Flexible para el Bienestar Personal:",
  "blog-9-contentOne":
    "Trabajar desde casa permite una mayor flexibilidad en la gestión de tu horario. Tienes la libertad de establecer tus propias horas de trabajo, lo que te permite priorizar actividades de bienestar personal como el ejercicio, el autocuidado y pasar tiempo de calidad con tus seres queridos. Esta flexibilidad puede aliviar el estrés al promover un equilibrio más saludable entre el trabajo y la vida personal.",
  "blog-9-headingTwo": "Eliminación del Estrés de los Desplazamientos:",
  "blog-9-contentTwo":
    "Uno de los principales generadores de estrés en entornos de oficina tradicionales es el desplazamiento diario. El trabajo remoto elimina la necesidad de apresurarse en el tráfico o apretujarse en el transporte público abarrotado, reduciendo el estrés asociado con los desplazamientos. En su lugar, puedes utilizar ese tiempo para relajarte, participar en una actividad calmante o comenzar tu jornada laboral sintiéndote renovado.",
  "blog-9-headingThree": "Entorno de Trabajo Personalizado y Cómodo:",
  "blog-9-contentThree":
    "El trabajo remoto te permite crear un entorno de trabajo personalizado y cómodo adaptado a tus preferencias. Puedes configurar un espacio de trabajo designado que promueva la concentración y la productividad, eliminando las distracciones y factores estresantes comunes en entornos de oficina tradicionales. Tener control sobre tu entorno de trabajo puede contribuir a una experiencia más relajada y libre de estrés.",
  "blog-9-headingFour": "Mayor Autonomía y Menos Micromanagement:",
  "blog-9-contentFour":
    "El trabajo remoto a menudo proporciona un mayor nivel de autonomía, lo que te permite asumir la responsabilidad de tu trabajo y gestionar tus tareas de manera independiente. Este menor nivel de micromanagement puede aliviar el estrés causado por una supervisión constante y promover una mayor sensación de confianza y empoderamiento en tu rol.",
  "blog-9-headingFive":
    "Mejora de la Integración entre el Trabajo y la Vida Personal:",
  "blog-9-contentFive":
    "Trabajar desde casa difumina los límites entre el trabajo y la vida personal, permitiendo una integración más fluida de ambos. Esta integración te permite gestionar mejor tus responsabilidades personales, atender las necesidades familiares y participar en actividades que te brinden alegría, todo mientras cumples con tus tareas laborales. Este enfoque holístico fomenta el bienestar general y reduce el estrés asociado con la compartimentación de diferentes aspectos de la vida.",
  "blog-9-headingSix": "Menos Distorsiones y Interrupciones en el Trabajo:",
  "blog-9-contentSix":
    "El trabajo remoto minimiza distracciones e interrupciones comunes en el lugar de trabajo, como el ruido excesivo, las interrupciones de los colegas y las reuniones improvisadas. Con menos distracciones, puedes mantener un enfoque mejorado, aumentar la productividad y experimentar una sensación de tranquilidad que contribuye a la reducción de los niveles de estrés.",
  "blog-9-conclusionheading": "Conclusión:",
  "blog-9-concluisonContent":
    "Trabajar desde casa ha revolucionado la forma en que trabajamos, ofreciendo un inmenso potencial para reducir el estrés y mejorar el bienestar. Al abrazar la flexibilidad, autonomía y comodidad que brinda el trabajo remoto, puedes experimentar un equilibrio más saludable entre el trabajo y la vida personal, reducir el estrés de los desplazamientos, aumentar el control sobre tu entorno de trabajo y mejorar la integración entre tu vida personal y profesional. Aprovecha el poder transformador del trabajo remoto y desbloquea una experiencia laboral más armoniosa y libre de estrés.",
  "Privacy and Policy": "Privacidad y Política",
  "privacy-description":
    "En FullSetters, priorizamos la privacidad y seguridad de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, usamos y protegemos su información personal cuando interactúa con nuestro sitio web y utiliza nuestros servicios. Al usar FullSetters, usted acepta las prácticas descritas en esta política.",
  "Information Collection": "Recolección de Información",
  "info-calculation":
    "Podemos recopilar información personal como su nombre, detalles de contacto, currículum y otros datos relevantes cuando registre una cuenta o utilice nuestros servicios. Esta información es necesaria para proporcionarle una experiencia personalizada y facilitar el proceso de solicitud.",
  "Use of Information": "Uso de la Información",
  "use-of-info-description":
    "Usamos la información recopilada para mejorar su experiencia de usuario, mejorar nuestros servicios y personalizar sus recomendaciones. También podemos usar su información para comunicarnos con usted, responder a consultas y proporcionar soporte al cliente.",
  "Data Security": "Seguridad de Datos",
  "data-security-description":
    "Implementamos medidas de seguridad estándar en la industria para proteger su información personal contra el acceso no autorizado, la pérdida o el mal uso. Sus datos se almacenan en servidores seguros y solo son accedidos por personal autorizado con estrictas obligaciones de confidencialidad.",
  "Third-Party Integration:": "Integración de Terceros:",
  "Third-Party-Integration-description":
    "FullSetters puede integrarse con servicios de terceros para mejorar su experiencia de usuario. Sin embargo, no somos responsables de las prácticas de privacidad o el contenido de estos sitios web o servicios externos. Le animamos a revisar sus políticas de privacidad antes de interactuar con ellos.",
  Cookies: "Cookies",
  "cookies-description":
    "Usamos cookies y tecnologías de seguimiento similares para mejorar su experiencia de navegación y recopilar información sobre cómo interactúa con nuestro sitio web. Estas tecnologías nos ayudan a analizar tendencias, administrar el sitio web y mejorar nuestros servicios.",
};
