import React from "react";
import Navbar from "../../../components/Layout/Navbar";
import Footer from "../../../components/Layout/Footer";
import Form from "./Form";

const ImmigrationApplicationPage = () => {
  return (
    <>
      <Navbar />
      <Form/>
      <Footer />
    </>
  );
};

export default ImmigrationApplicationPage;
