import React from "react";
import tapcart from "../../assets/Tapcart.svg";
import modx from "../../assets/MODX.svg";
import alert from "../../assets/AlertMedia.svg";
import nemo from "../../assets/Nemours Children's Health.svg";
import plan from "../../assets/Planless.svg";
import hub from "../../assets/HubSpot.svg";
import { withTranslation, useTranslation } from "react-i18next";

const Comapnies = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full py-10 bg-white">
      <div className="max-w-[1550px] mx-auto px-4">
        <h1 className="mb-8 text-2xl font-normal text-center text-secondary">
          {t("trusted-companies")}
        </h1>
        <div className="flex flex-wrap justify-center gap-10">
          <img src={tapcart} alt="" className="max-w-[150px]" />
          <img src={modx} alt="" className="max-w-[150px]" />
          <img src={alert} alt="" className="max-w-[150px]" />
          <img src={nemo} alt="" className="max-w-[150px]" />
          <img src={plan} alt="" className="max-w-[150px]" />
          <img src={hub} alt="" className="max-w-[150px]" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Comapnies);
