import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
export const ProtectedRoute = ({ component }) => {
  const { user } = useSelector((state) => state.auth);

  if (user) {
    return component;
  } else {
    return (
      <Navigate
        to={{
          pathname: "/login",
        }}
      />
    );
  }
};

export default ProtectedRoute;
