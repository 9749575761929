import React, { useState, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const API_URL = "https://fullsetters.com/api/application/";

const predefinedRequirements = {
  Job: ["Demographics", "Rental", "Experience", "Education", "References"],
  Employment: ["Employment"],
  Loan: ["Demographics", "Experience", "References"],
  School: ["Demographics", "Education", "References"],
  Immigration: [
    "Demographics",
    "Experience",
    "Education",
    "References",
    "Rental",
  ],
  Rental: ["Rental"],
};

const Form = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");
  const [fields, setFields] = useState({
    title: "",
    description: "",
    type: "",
    expiryTime: "",
    requirements: [],
  });

  const getUserId = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    setUserId(user._id);
    setToken(user.token);
  };

  useEffect(() => {
    getUserId();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({
      ...fields,
      [name]: value,
      requirements:
        name === "type"
          ? predefinedRequirements[value] || []
          : fields.requirements,
    });
  };

  const submitRecord = async (event) => {
    event.preventDefault();
    if (
      fields.type !== "" &&
      fields.expiryTime !== "" &&
      fields.title !== "" &&
      fields.description !== ""
    ) {
      const dataToSubmit = {
        title: fields.title,
        description: fields.description,
        type: fields.type,
        expiryTime: fields.expiryTime,
        requirements: fields?.requirements,
        createdBy: userId,
      };

      try {
        const response = await axios.post(API_URL, dataToSubmit, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 201) {
          toast.success("Application created successfully!");

          setFields({
            title: "",
            description: "",
            type: "",
            expiryTime: "",
            requirements: [],
          });
          setTimeout(() => {
            navigate("/product-and-services/applications");
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.alert("Please fill in all the fields!");
    }
  };

  return (
    <div className="w-full">
      <ToastContainer />
      <div className="max-w-[1550px] mx-auto px-4 my-12">
        <div className="bg-white rounded-t-lg">
          <h1 className="text-xl font-bold text-primary py-4 px-5 border-b border-b-[#D9D9D9]">
            Post a new Application
          </h1>
        </div>
        <div className="px-5 py-8 bg-white rounded-b-lg ">
          <form onSubmit={submitRecord}>
            <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
              <div>
                <FormControl fullWidth>
                  <InputLabel id="applicationType">Application Type</InputLabel>
                  <Select
                    labelId="applicationType"
                    id="applicationType-select"
                    label="Application Type"
                    name="type"
                    value={fields.type || ""}
                    onChange={handleChange}
                  >
                    <MenuItem value={"Job"}>Job Application</MenuItem>
                    <MenuItem value={"Employment"}>
                      Employement Application
                    </MenuItem>
                    <MenuItem value={"Loan"}>Loan Application</MenuItem>
                    <MenuItem value={"School"}>School Application</MenuItem>
                    <MenuItem value={"Immigration"}>
                      Immigration Application
                    </MenuItem>
                    <MenuItem value={"Rental"}>Rental Application</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <TextField
                  fullWidth
                  label="Last date to apply"
                  id="fullWidth"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  name="expiryTime"
                  value={fields?.expiryTime || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6 mb-6">
              <div>
                <TextField
                  fullWidth
                  label="Application Title"
                  id="fullWidth"
                  name="title"
                  value={fields.title || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6 mb-6">
              <div>
                <TextField
                  fullWidth
                  multiline
                  minRows={6}
                  label="Description"
                  id="fullWidth"
                  name="description"
                  value={fields.description || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex gap-3">
              <Button
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: "#3867D6",
                }}
                onClick={submitRecord}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
