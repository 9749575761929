import React from "react";
import Layout from "../../Layout";
import PaymentHistoryWrapper from "../PaymentHistoryWrapper";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
const PaidHistory = () => {
  return (
    <Layout>
      <h2 className="text-2xl font-bold text-[#142D3C] font-Roboto py-8 px-2">
        Payment History
      </h2>
      <PaymentHistoryWrapper>
        <div className="bg-white rounded-b-lg">
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left">Payment ID</th>
                  <th className="px-6 py-3 text-left">Invoice ID</th>
                  <th className="px-6 py-3 text-left">Service</th>
                  <th className="px-6 py-3 text-left">Paid at</th>
                  <th className="px-6 py-3 text-left">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-6 py-3">H_2717815</td>
                  <td className="px-6 py-3">HSG-447247</td>
                  <td className="px-6 py-3">
                    Business Web Hosting (billed every 2 years)
                  </td>
                  <td className="px-6 py-3">2023-07-27</td>
                  <td className="px-6 py-3">$96.98</td>
                  <td className="px-6 py-3">
                    <Link to="/billing/payment-history/paid/H_2717815">
                      <span className="transition-all text-blue-500 hover:text-white hover:bg-blue-300 block w-8 h-8 p-1.5">
                        <AiOutlineRight />
                      </span>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </PaymentHistoryWrapper>
    </Layout>
  );
};

export default PaidHistory;
