import React from "react";
import ApplicationCard from "../../components/common/ApplicationCard";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";

const MasterPage = () => {
  const { t, } = useTranslation();
  return (
    <div className="w-full">
      <Navbar />
      <div className="max-w-[1550px] mx-auto px-4 my-12">
        <div className="bg-white flex flex-wrap sm:items-center gap-4 justify-between rounded-t-lg py-4 px-5 border-b border-b-[#D9D9D9]">
          <h1 className="text-xl font-bold text-primary ">
            Master Applications
          </h1>
          <div className="block lg:hidden">
            <Link to="/product-and-services/applications">
              <Button
                variant="text"
                size="large"
                sx={{
                  color: "#102E3D",
                }}
              >
                {t("my-applications")}
              </Button>
            </Link>
          </div>
          <div className="block lg:hidden">
            <Link to="/product-and-services/new-application">
              <Button variant="outlined" size="large">
                {t("new-application")}
              </Button>
            </Link>
          </div>
        
        </div>
        <div className="px-5 py-8 bg-white rounded-b-lg">
          <div className="grid md:grid-cols-2 xl:grid-cols-3  grid-cols-1 gap-8">
            <ApplicationCard />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withTranslation()(MasterPage);
