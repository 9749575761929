import axios from "axios";
import Cookies from "universal-cookie";

const API_URL = "https://fullsetters.com/api/users/";
const cookies = new Cookies();

const register = async (userData) => {
  await axios.post(API_URL, userData);
};

const login = async (userData) => {
  const response = await axios.post(API_URL + "login", userData);
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
    cookies.set("user", JSON.stringify(response.data), {
      path: "/",
    });
  }

  return response.data;
};

const socialLogin = async (userData) => {
  const response = await axios.post(API_URL + "socialLogin", userData);
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
    cookies.set("user", JSON.stringify(response.data), {
      path: "/",
    });
  }

  return response.data;
};

const logout = () => {
  localStorage.removeItem("user");
  cookies.remove("user");
};

const authService = {
  register,
  logout,
  login,
  socialLogin
};

export default authService;
