import React from "react";
import Tick from "../../assets/allow.svg";
import { withTranslation, useTranslation } from "react-i18next";
import getStripe from "../../lib/getStripe";


const Plans = () => {
  const { t } = useTranslation();

  async function handleCheckout() {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: "price_1NxWWqD1of7ibGo04VmYRQ8M",
          quantity: 1,
        },
      ],
      mode: "subscription",
      successUrl: `http://localhost:8002/success`,
      cancelUrl: `http://localhost:8002/cancel`,
      customerEmail: "customer@email.com",
    });
    console.warn(error.message);
  }

  const studentPlan = [
    {
      title: t("student-plan-0"),
    },
    {
      title: t("student-plan-1"),
    },
    {
      title: t("student-plan-2"),
    },
    {
      title: t("student-plan-3"),
    },
    {
      title: t("student-plan-4"),
    },
    {
      title: t("student-plan-5"),
    },
  ];

  const individualPlan = [
    {
      title: t("individual-plan-0"),
    },
    {
      title: t("individual-plan-1"),
    },
    {
      title: t("individual-plan-2"),
    },
    {
      title: t("individual-plan-3"),
    },
    {
      title: t("individual-plan-4"),
    },
    {
      title: t("individual-plan-5"),
    },
  ];

  const enterprisePlan = [
    {
      title: t("enterprise-plan-0"),
    },
    {
      title: t("enterprise-plan-1"),
    },
    {
      title: t("enterprise-plan-2"),
    },
    {
      title: t("enterprise-plan-3"),
    },
    {
      title: t("enterprise-plan-4"),
    },
    {
      title: t("enterprise-plan-5"),
    },
  ];


  return (
    <div className="mb-24">
      <div className="main-container flex flex-col gap-5 py-20">
        <h1 className="text-center text-primary font-bold text-[40px]">
          {t("pricing-title")}
        </h1>
        <p className="text-lg font-normal text-center text-primary">
          {t("pricing-description")}
        </p>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:px-20 max-w-[1660px] mx-auto">
        <div className="">
          <div className="bg-[#FFFFFF] border-[1px] border-[#D2D6DB] rounded-2xl px-4 py-14 ">
            <div className="py-5 px-2">
              <h2 className="text-primary font-bold text-3xl mb-8">{t("future-achiever-title")}</h2>
              <span className="text-lg font-bold text-primary">
                {t("for-students")}
              </span>
              <p className="text-primary text-base font-normal mb-9">
                {t("future-achiever-description")}
              </p>

              <h2 className="text-6xl font-bold text-[#3867D6] mb-10">
                <sup>$</sup>5.99{" "}
                <sub className="text-base font-medium text-black">/month</sub>
              </h2>

              <button
                onClick={handleCheckout}
                className="text-xl w-full bg-[#FFFFFF] rounded-lg border-[1px] text-center py-3 border-[#142D3C] text-[#142D3C] font-medium"
              >
                {t("get-started-now")}
              </button>

              <div className="py-7">
                {studentPlan.map((item, index) => {
                  return (
                    <div key={index} className="py-4">
                      <div className="flex gap-2 items-center px-3">
                        <img src={Tick} alt="" />
                        <span className="text-base text-[#3F3F42] font-normal">
                          {item.title}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="bg-tertiary border-[1px] border-[#D2D6DB] rounded-2xl px-4 py-14 ">
            <div className="py-5 px-2">
              <h2 className="text-primary font-bold text-3xl mb-8">{t("career-catalyst-title")}</h2>
              <span className="text-lg font-bold text-primary">
                {t("for-individuals")}
              </span>
              <p className="text-primary text-base font-normal mb-9">
                {t("career-catalyst-description")}
              </p>

              <h2 className="text-6xl font-bold text-[#3867D6] mb-10">
                <sup>$</sup>9.99{" "}
                <sub className="text-base font-medium text-black">/month</sub>
              </h2>

              <button className="text-xl w-full text-[#FFFFFF] font-medium bg-[#3867D6] rounded-lg text-center py-3">
                {t("get-started-now")}
              </button>

              <div className="py-7">
                {individualPlan.map((item, index) => {
                  return (
                    <div key={index} className="py-4">
                      <div className="flex gap-2 items-center px-3">
                        <img src={Tick} alt="" />
                        <span className="text-base text-[#3F3F42] font-normal">
                          {item.title}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-[#FFFFFF] border-[1px] border-[#D2D6DB] rounded-2xl px-4 py-14 ">
            <div className="py-5 px-2">
              <h2 className="text-primary font-bold text-3xl mb-8">{t("enterprise-innovator-title")}</h2>
              <span className="text-lg font-bold text-primary">
                {t("for-organizations")}
              </span>
              <p className="text-primary text-base font-normal mb-9">
                {t("enterprise-innovator-description")}
              </p>

              <h2 className="text-6xl font-bold text-[#3867D6] mb-10">
                <sup>$</sup>14.99{" "}
                <sub className="text-base font-medium text-black">/month</sub>
              </h2>

              <button className="text-xl bg-[#FFFFFF] w-full rounded-lg border-[1px] text-center py-3 border-[#142D3C] text-[#142D3C] font-medium">
                {t("get-started-now")}
              </button>

              <div className="py-7">
                {enterprisePlan.map((item, index) => {
                  return (
                    <div key={index} className="py-4">
                      <div className="flex gap-2 items-center px-3">
                        <img src={Tick} alt="" />
                        <span className="text-base text-[#3F3F42] font-normal">
                          {item.title}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Plans);
