export const translations = {
  home: "Home",
  about: "About Us",
  solutions: "Solutions",
  help: "Help",
  faqs: "FAQs",
  contact: "Contact",
  blogs: "Blogs",
  pricing: "Pricing",
  'Get 4 Digit Code':"Get 4 Digit Code",
  'Forgot Password':'Forgot Password',
  "pricing-title": "Choose the Perfect Pricing Plan for Your Success",
  "my-applications": "My Applications",
  "switch-to-master-tab": "Product and Services",
  "new-application": "New Application",
  "sign-in": "sign-in",
  "password-sent-message":"We've sent a password reset code to your email. Can't find it? Check your spam box too!",
  notifications: "Notifications",
  fullsetter: "FullSetters:",
  resume: "resume",
  "ultimate-solution": "The Ultimate Solution for",
  jobs: "Jobs",
  "has-never-been": "has never been this easier.",
  "hassle-free": "Hassle-Free",
  applications: "Applications",
  "hero-description":
    "Unlock effortless applications with FullSetters! Save time and eliminate repetitive form filling. Our secure database stores your details, automatically populating application forms for jobs, rentals, immigration, schools, loans, and more. Simplify your journey with FullSetters and experience a seamless application process.",
  "get-started": "Get Started",
  "learn-more": "Learn More",
  "trusted-companies": "Trusted by the companies of all sizes",
  why: "Diverse Application Solutions by",
  fullSetters: "FullSetters",
  "why-fullSetters-title-1": "Rental Apartments",
  "why-fullSetters-title-2": "Schools",
  "why-fullSetters-title-3": "Jobs",
  "why-fullSetters-title-4": "Loans",
  "why-fullSetters-title-5": "Immigration",
  "why-fullSetters-title-6": "General ",
  "why-fullSetters-desc-1":
    "Discover and apply for your dream rental apartment with FullSetters. Our app simplifies the apartment application process, allowing you to submit applications and track your progress effortlessly",
  "why-fullSetters-desc-2":
    "FullSetters empowers you to navigate the application process smoothly. From submitting forms to managing required documents, FullSetters makes school applications a breeze.",
  "why-fullSetters-desc-3":
    "Unlock career opportunities with FullSetters. Our app revolutionizes the job application experience, enabling you to effortlessly apply to a wide range of positions. Save time with autofilled applications",
  "why-fullSetters-desc-4":
    "Simplify your loan application journey with FullSetters. From personal loans to mortgages, our app streamlines the process, helping you complete applications efficiently and securely.",
  "why-fullSetters-desc-5":
    "Navigate the immigration process seamlessly with FullSetters. Our app guides you through the complex steps, helping you submit accurate and complete applications",
  "why-fullSetters-desc-6":
    "Experience the convenience of FullSetters across various application types. Whether it's applying for licenses, permits, or other general applications, our app streamlines the process",
  videoBannerTitle: "FullSetters in Action: Simplify Your Application Process",
  videoBannerTitleDescription:
    "Watch our introductory video to see FullSetters in action. Discover how our platform simplifies the complexities of applications, saving you time and effort. Experience the convenience of autofilled forms, personalized recommendations, and secure document management. See firsthand how FullSetters empowers you to navigate the application landscape with ease and achieve your goals.",
  what: "What",
  "users-say": "Users say",
  "testimonial-1":
    "As a student applying to schools, FullSetters has been a lifesaver. The platform simplifies the application process, allowing me to focus on preparing for my future.",
  "testimonial-2":
    "FullSetters has made applying for loans a breeze. The platform's user-friendly interface and secure document management features have simplified the loan application process for me",
  "faq-title-1": "Is FullSetters free to use?",
  "faq-title-2": "Can FullSetters help me with multiple application types?",
  "faq-title-3": "How secure is FullSetters?",
  "faq-title-4":
    "Does FullSetters offer integration with other platforms or systems?",
  "faq-title-5":
    "What if I need assistance or have questions while using FullSetters?",
  "faq-title-6": "Does FullSetters offer a mobile app for on-the-go access?",
  "faq-title-7": "How can I get started with FullSetters?",
  "faq-desc-1":
    "Absolutely! FullSetters offers a Free Trial, providing users with a chance to experience the platform's capabilities before making a decision. After the trial, users can choose from a range of plans, including the Student, Individual, and Organization Plans, each offering tailored features to suit their needs. With FullSetters, you have the flexibility to select the best plan for your application journey.",
  "faq-desc-2":
    "Certainly! FullSetters is your all-in-one solution for multiple application types. Whether it's job applications, rental apartments, schools, loans, immigration, or other endeavors, our platform empowers you to seamlessly manage each category. With FullSetters, you can efficiently handle and submit various applications, eliminating repetitive tasks. Experience the convenience of one platform for all your application needs and simplify your journey to success.",
  "faq-desc-3":
    "FullSetters takes data security and privacy seriously. We employ robust measures to ensure the highest level of security for our users. Our platform adheres to industry-standard encryption protocols, protecting your personal information from unauthorized access. We also regularly update our security measures to stay ahead of potential threats.<0/><0/> Rest assured, your data is handled with utmost care and confidentiality. FullSetters is committed to providing a safe and secure environment for all users throughout their application journey. Your trust and peace of mind are paramount to us.",
  "faq-desc-4":
    "Yes, FullSetters offers seamless integration with other platforms and systems to enhance your experience. Our platform is designed with flexibility in mind, allowing for smooth API integration with various third-party applications and tools",
  "faq-desc-5":
    "At FullSetters, we value your experience and are committed to providing excellent customer support. If you ever need assistance or have questions while using our platform, our dedicated support team is here to help.<0/><0/> You can reach out to us through our contact page or email us at support@fullsetters.com. Our team of experts will promptly respond to your inquiries, provide guidance, and address any concerns you may have.<0/><0/> We understand that navigating the application process can sometimes be challenging, and we want to ensure that you have a smooth and successful experience with FullSetters. Rest assured, we are always here to support you every step of the way. Your satisfaction is our priority, and we look forward to assisting you with any assistance you may need.",
  "faq-desc-6":
    "Yes, FullSetters understands the importance of flexibility and convenience. That's why we offer a user-friendly mobile app, allowing you to access our platform on-the-go.<0/><0/> With the FullSetters mobile app, you can manage your applications, update your profile, and stay connected with your application journey from anywhere, anytime. Whether you're commuting, traveling, or simply prefer to use your mobile device, our app empowers you to stay productive and engaged with your application process.",
  "faq-desc-7":
    "Getting started with FullSetters is quick and easy! Follow these simple steps to begin your seamless application journey:<0/><1><2>Visit our website at www.fullsetters.com and click on the \"Get Started\" button.</2><2>Create your account by providing your email address and setting up a secure password.</2><2>Choose a plan that best suits your needs – Student, Individual, or Organization.</2><2>Enjoy a Free Trial to explore the platform and experience its powerful features.</2><2>Complete your profile by filling in essential information, such as demographics, education, skills, and more.</2><2>Start applying for your desired opportunities, whether it's jobs, rental apartments, schools, loans, immigration, or other endeavors.</2><2>Sit back and let FullSetters streamline your application process, saving you time and effort.</2></1><0/>Embark on your application journey with FullSetters today and unlock the possibilities of seamless applications. It's time to take control of your career and future opportunities with ease!",
  Revolutionize: "Revolutionize",
  "the Way You Apply with": "the Way You Apply with",
  "about-description":
    "At FullSetters, we are dedicated to empowering individuals in their professional journeys. Our platform revolutionizes the way you manage and apply for various applications, whether it's for jobs, rental apartments, schools, loans, immigration, or more. With FullSetters, you gain a powerful ally that streamlines the application process, saving you time and effort. We believe that applying for opportunities should be seamless and hassle-free, which is why we have developed a user-friendly platform that simplifies the entire process. Our advanced technology and intuitive interface allow you to effortlessly fill out application forms, store important documents, and track your progress all in one place. With FullSetters, you can unlock a world of possibilities. Join the FullSetters community today and experience a new level of efficiency, convenience, and success in your application endeavors. Together, we'll navigate the application process with ease, opening doors to endless opportunities and shaping your future.",
  "Team of 30 Professionals, working from":
    "Team of 30 Professionals, working from",
  "around the World": "around the World",
  "team-description":
    "Our team is dedicated to provide exceptional service from various corners of the world. With a global perspective and a wide range of expertise, our team works collaboratively to deliver innovative solutions that meet your needs. We leverage our collective knowledge and experience to ensure that you receive the highest level of support and expertise in managing applications.",

  "quick-links": "Quick Links",
  tos: "Terms of Service",
  "privacy-policy": "Privacy Policy",
  "contact-us": "Contact Us",
  more: "More",
  "blog-posts": "Blog Posts",
  "blog-detail": "Blog Detail",
  "account-settings": "Account Settings",
  "privacy-text":
    "This website uses cookies to ensure you get the best experience on our website.",
  close: "Close",
  "manage-your": "Managing your",
  Resume: "Resume",
  "and-applying-for": "and Applying for",
  Jobs: "Jobs",
  "has never been this easier.": "has never been this easier.",
  "about-page-description":
    "Lorem ipsum dolor sit amet,sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  "got-some-questions": "Got Some Questions? Please reach out to",
  "our-team": "our Team",
  Disclaimer: "Disclaimer",
  Privacy: "Privacy",
  "How we may help you out?": "How we may help you out?",
  "Search for any kind of help": "Search for any kind of help",
  Search: "Search",
  "Featured Topics": "Featured Topics",
  "FAQs?": "FAQs?",
  "We’d love to hear from you.": "We’d love to hear from you.",
  "Please get in touch by filling the form.":
    "Please get in touch by filling the form.",
  "Full Name": "Full Name",
  Name: "Name",
  Email: "Email",
  "Your message": "Your message",
  Submit: "Submit",
  "Fullsetters Blogs": "Fullsetters Blogs",
  "Explore the latest news from the job market.":
    "Explore the latest news from the job market.",
  "blog-description":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.",
  Pricing: "Pricing",
  "pricing-description":
    "Experience the power of effortiess application with Fullsetters streamlined pricing and enjoy a free trail. Disover customized plans for students,professionals and organizations, empowering you to unlock your potential and achieve your goals with ease. Choose your path to success and start your Free Trail today.",
  "Fullsetters Terms of Service": "Fullsetters Terms of Service",
  "Privacy Policy": "Privacy Policy",
  Copyright: "Copyright",
  "Module Data": "Module Data",
  Demographics: "Demographics",
  "Please enter your Demographics Details":
    "Please enter your Demographics Details",
  Prefix: "Prefix",
  Suffix: "Suffix",
  "First Name": "First Name",
  "Middle Name": "Middle Name",
  "Last Name": "Last Name",
  "Other Names": "Other Names",
  "Email Address": "Email Address",
  "Phone Number": "Phone Number",
  "Date of Birth": "Date of Birth",
  Gender: "Gender",
  Male: "Male",
  Female: "Female",
  Other: "Other",
  Ethnicity: "Ethnicity",
  Asian: "Asian",
  European: "European",
  American: "American",
  Affrican: "Affrican",
  Race: "Race",
  Brown: "Brown",
  Black: "Black",
  White: "White",
  "Active Military?": "Active Military?",
  Yes: "Yes",
  No: "No",
  "Are you a disabled Veteran?": "Are you a disabled Veteran?",
  "Contact info": "Contact info",
  "Work Phone": "Work Phone",
  "Home Phone": "Home Phone",
  "Cell Phone": "Cell Phone",
  "Residential Address": "Residential Address",
  "Street name & number": "Street name & number",
  "Address 2 (Optional)": "Address 2 (Optional)",
  City: "City",
  State: "State",
  "ZIP Code": "ZIP Code",
  Country: "Country",
  "Billing Address": "Billing Address",
  "Shipping Address": "Shipping Address",
  "Use Billing Address as Shipping Address":"Use Billing Address as Shipping Address",
  Update: "Update",
  Save: "Save",
  Cancel: "Cancel",
  Delete: "Delete",
  Education: "Education",
  "Please enter your Education Details": "Please enter your Education Details",
  "School Name": "School Name",
  Degree: "Degree",
  "School Address": "School Address",
  Province: "Province",
  "Study Details": "Study Details",
  Major: "Major",
  Minor: "Minor",
  "Finishing Date": "Finishing Date",
  "Contact Person": "Contact Person",
  Relationship: "Relationship",
  Phone: "Phone",
  Experiences: "Experiences",
  "Please enter your Experiences Details":
    "Please enter your Experiences Details",
  "Experience Title": "Experience Title",
  Industry: "Industry",
  "Starting Date": "Starting Date",
  Manager: "Manager",
  Employement: "Employement",
  "Please enter your Employement details":
    "Please enter your Employement details",
  Employeer: "Employeer",
  "Job Title": "Job Title",
  "Work Address": "Work Address",
  Skills: "Skills",
  "Please enter your Skills details": "Please enter your Skills details",
  Tools: "Tools",
  Standards: "Standards",
  References: "References",
  "Please enter your References Details":
    " Please enter your References Details",
  Certifications: "Certifications",
  "Please enter your Certifications Details":
    "Please enter your Certifications Details",
  "Certification Title": "Certification Title",
  "Certificate Provider": "Certificate Provider",
  Highlights: "Highlights",
  Others: "Others",
  "Please enter any other details that you want.":
    "Please enter any other details that you want.",
  Title: "Title",
  Description: "Description",
  "Residential History": "Residential History",
  "Please enter your residential history":
    "Please enter your residential history",
  "Go Back To Demographics": "Go Back To Demographics",
  Payment: "Payment",
  "Your Visa Card": "Your Visa Card",
  "Your Mastercard": "Your Mastercard",
  "Card Number": "Card Number",
  "Expiration Date": "Expiration Date",
  CVC: "CVC",
  "Rental Apartments": "Rental Apartments",

  "terms-title":
    'These Terms of Service ("Terms") govern your use of FullSetters\' services, website, and mobile applications (collectively referred to as the "Service"). By accessing or using the Service, you agree to be bound by these Terms.',
  "user-responsibilities": "User Responsibilities",
  "accurate-info":
    "You must provide accurate and up-to-date information during the registration process.",
  "account-confidentiality":
    "You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account.",
  "unlawful-activities":
    "You agree not to engage in any unlawful or unauthorized activities while using the Service.",
  "use-of-service-title": "Use of the Service",
  "non-exclusive-license":
    "FullSetters grants you a non-exclusive, non-transferable license to use the Service for personal or business purposes, as intended.",
  "no-modify-service":
    "You may not copy, modify, distribute, sell, or lease any part of the Service without prior written consent from FullSetters.",
  "third-party-services":
    "You acknowledge that FullSetters may use third-party services and resources to provide the Service.",
  "intellectual-property-title": "Intellectual Property",
  "fullsetters-rights":
    "FullSetters retains all rights, title, and interest in and to the Service, including any intellectual property rights.",
  "no-use-trademarks":
    "You may not use FullSetters' trademarks, logos, or other proprietary information without prior written permission.",
  "privacy-title": "Privacy and Data",
  "privacy-policy-text":
    "FullSetters respects your privacy and handles your personal information in accordance with its Privacy Policy.",
  "consent-to-collection":
    "By using the Service, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.",
  "liability-title": "Limitations of Liability",
  "no-guarantee":
    "FullSetters strives to provide accurate and reliable information but does not guarantee the completeness, accuracy, or reliability of the Service.",
  "not-liable":
    "FullSetters shall not be liable for any direct, indirect, incidental, or consequential damages resulting from your use of the Service.",
  "termination-title": "Termination",
  "right-to-terminate":
    "FullSetters reserves the right to suspend or terminate your access to the Service at any time, for any reason, without prior notice.",
  "data-deletion":
    "Upon termination, your access to the Service and any associated data may be permanently deleted.",
  "modifications-title": "Modifications to the Terms",
  "right-to-modify":
    "FullSetters reserves the right to modify or update these Terms at any time.",
  "review-terms":
    "It is your responsibility to review the Terms periodically for any changes.",
  "final-agreement":
    "By using the FullSetters Service, you agree to abide by these Terms of Service. If you do not agree with any part of these Terms, please discontinue using the Service.",
  "blog-title": "Fullsetters Blogs",
  "blog-subtitle": "Explore the latest news from the job market.",
  "blog-card1-title": "Working Multiple Remote Jobs?",
  "blog-card1-desc":
    "Discover the ins and outs of working multiple remote jobs, from managing your schedule to maximizing your productivity, and unlock the benefits...",
  "blog-card2-title":
    "How to Figure Out If a Potential Employer Offers Paid Leave",
  "blog-card2-desc":
    "Uncover the essential strategies to determine if a potential employer provides paid leave, ensuring you have the...",
  "blog-card3-title": "How Entrapreneurship can help you stand out at work",
  "blog-card3-desc":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "blog-card4-title": "How to explain why you’re right for the job",
  "blog-card4-desc":
    "Whether you’re speaking with the recruiter or you're in the final stages of the interview process, you know you have the chops for the role...",
  "blog-card5-title": "Tips for shaking your job interview jitters",
  "blog-card5-desc":
    "Overcome job interview jitters with these expert tips: Learn effective relaxation techniques, prepare thoroughly with mock interviews, focus on your qualifications...",
  "blog-card6-title": "How to Know Your Resume Is Ready to be Submitted",
  "blog-card6-desc":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "blog-card7-title": "How to End a Networking Conversation Tactfully",
  "blog-card7-desc":
    "Master the art of tactful networking conversation endings with these tips: Express gratitude for the insightful...",
  "blog-card8-title":
    "How to Follow up on Freelance Pitches Without Being Annoying",
  "blog-card8-desc":
    "Navigate the fine line of following up on freelance pitches without being annoying by employing these strategies...",
  "blog-card9-title": "How Working From Home Can Reduce Stress",
  "blog-card9-desc":
    "Discover the stress-reducing benefits of working from home: Enjoy a flexible schedule that promotes work-life balance, eliminate commuting-related stressors...",
  "blog-card-timestamp": "4 days ago",
  "future-achiever-title": "Future Achiever",
  "for-students": "For Students",
  "future-achiever-description":
    "Navigate Your Path to Success with the Fullsetters Student Plan and Enjoy a Free Trial",
  "get-started-now": "Get Started Now",
  "career-catalyst-title": "Career Catalyst",
  "for-individuals": "For Individuals",
  "career-catalyst-description":
    "Unlock unlimited access to all features with our affordable subscription. Sign up for a Free Trial",
  "enterprise-innovator-title": "Enterprise Innovator",
  "for-organizations": "For Organizations",
  "enterprise-innovator-description":
    "Tailored solutions for businesses and organizations with unique needs. Start with a Free Trial",

  "student-plan-0": "Access to all application categories",
  "student-plan-1": "100 application submissions per month",
  "student-plan-2": "Job Search Alerts",
  "student-plan-3": "Basic application tracking",
  "student-plan-4": "Standard customer support",
  "student-plan-5": "Resume management",
  "individual-plan-0": "Access to all application categories",
  "individual-plan-1": "Unlimited application submissions",
  "individual-plan-2": "Advanced application tracking",
  "individual-plan-3": "Priority customer support",
  "individual-plan-4": "Customizable resume templates",
  "individual-plan-5": "Resume Analytics and Insights",
  "enterprise-plan-0": "Team Collaboration Tools",
  "enterprise-plan-1": "Access to all application categories",
  "enterprise-plan-2": "Unlimited application submissions",
  "enterprise-plan-3": "Premium application tracking and analytics",
  "enterprise-plan-4": "API Integration for Seamless Workflow",
  "enterprise-plan-5": "Personalized support and Guidance",

  // blog 1 content is here

  "blog-1-summary":
    "In today's evolving job market, the concept of working multiple remote jobs has gained significant traction. With the rise of remote work opportunities and the desire for greater flexibility and diverse income streams, more individuals are exploring the option of juggling multiple remote jobs. However, navigating this unique employment setup requires careful consideration and planning. In this blog, we will delve into the essentials of working multiple remote jobs, covering important aspects such as time management, productivity, potential challenges, and the overall benefits of this arrangement.",
  "blog-1-headingOne": "Understanding Time Management:",
  "blog-1-contentOne":
    "One of the key factors in successfully managing multiple remote jobs is effective time management. Balancing multiple roles, deadlines, and responsibilities can be demanding, but with the right strategies in place, it becomes achievable. We will explore techniques such as prioritization, scheduling, and creating boundaries to optimize your time and ensure you stay on top of your commitments",
  "blog-1-headingTwo": "Maximizing Productivity:",
  "blog-1-contentTwo":
    "Maintaining productivity while juggling multiple remote jobs is crucial to meeting deadlines and achieving success. We will provide practical tips and insights on boosting productivity, including task organization, setting realistic goals, leveraging productivity tools, and adopting efficient work habits. Discover how to make the most of your time and energy to excel in all your remote job endeavors.",
  "blog-1-headingThree": "Overcoming Challenges:",
  "blog-1-contentThree":
    "Working multiple remote jobs can present certain challenges that need to be addressed proactively. We will discuss common obstacles such as potential conflicts of interest, overlapping schedules, and managing workload. By exploring strategies for effective communication, setting clear expectations, and finding a balance between different roles, you can navigate these challenges and mitigate their impact on your work.",
  "blog-1-headingFour": "Benefits of Diverse Income Streams:",
  "blog-1-contentFour":
    "One of the primary motivations for pursuing multiple remote jobs is the potential for increased income and financial stability. We will examine the advantages of diversifying your income streams, including the ability to explore different professional interests, expand your skill set, and secure multiple revenue streams. Discover the financial and professional growth opportunities that working multiple remote jobs can offer.",
  "blog-1-headingFive": "Achieving Work-Life Integration:",
  "blog-1-contentFive":
    "Maintaining a healthy work-life balance is essential, even when working multiple remote jobs. We will explore strategies to avoid burnout, prioritize self-care, and establish clear boundaries between work and personal life. By adopting a holistic approach to work-life integration, you can reap the benefits of flexibility and freedom while still maintaining your well-being.",
  "blog-1-conclusionheading": "Conclusion:",
  "blog-1-concluisonContent":
    "Working multiple remote jobs can be a fulfilling and rewarding career choice, but it requires careful planning, effective time management, and a proactive approach to address challenges. By implementing the strategies outlined in this blog, you can unlock the potential of working multiple remote jobs, enjoy the advantages of diverse income streams, and create a harmonious work-life balance. Embrace the opportunities that remote work offers, and embark on a fulfilling and successful journey of working multiple remote jobs.Remember, with the right knowledge and mindset, you can thrive in this unique work arrangement and build a fulfilling career that aligns with your goals and aspirations.",

  // blog 2 content is here
  "blog-2-summary":
    "Paid leave is an important benefit that employees value, providing them with the necessary time off to recharge, address personal matters, and achieve work-life balance. When considering a potential employer, it's crucial to determine if they offer paid leave and understand the specifics of their policy. In this blog post, we will guide you through the process of figuring out if a potential employer offers paid leave and help you make informed decisions about your future employment.",
  "blog-2-headingOne": "Research the Company:",
  "blog-2-contentOne":
    "Start by researching the company's website, employee handbook, or career portal. Look for information regarding their leave policies, employee benefits, and time-off allowances. Some companies may openly state their paid leave policy, including the number of days offered and any eligibility criteria. Take note of this information and evaluate if it aligns with your needs and expectations.",
  "blog-2-headingTwo": "Review Job Postings and Offer Letters:",
  "blog-2-contentTwo":
    "Pay close attention to job postings and offer letters. Employers often mention their leave benefits, including paid vacation, sick leave, or other time-off arrangements. Carefully read through the details provided, as they can give you insights into the company's approach to paid leave. If specific information is not mentioned, it's essential to inquire during the interview or negotiation process.",
  "blog-2-headingThree": " Network with Current or Former Employees:",
  "blog-2-contentThree":
    "Reach out to current or former employees of the company through professional networking platforms or personal connections. Engage in conversations about the company's leave policies and inquire about their experiences with paid leave. This firsthand information can provide valuable insights and help you gauge the employer's commitment to supporting work-life balance.",
  "blog-2-headingFour": "Seek Clarity During Interviews:",
  "blog-2-contentFour":
    "Use the interview process as an opportunity to ask questions about the company's paid leave policy. Inquire about the number of vacation days, sick leave, or other types of paid time off provided. Additionally, ask about any specific requirements or restrictions regarding the utilization of paid leave. By seeking clarity, you can ensure you have a comprehensive understanding of the benefits offered.",
  "blog-2-headingFive": "Consult with HR or Hiring Managers:",
  "blog-2-contentFive":
    "Once you progress further in the hiring process, consider reaching out to the company's HR department or hiring managers. Seek their guidance and ask specific questions about the company's paid leave policy. They can provide detailed information, address any concerns, and clarify any ambiguities you may have.",
  "blog-2-conclusionheading": "Conclusion:",
  "blog-2-concluisonContent":
    "Determining if a potential employer offers paid leave is a crucial aspect of evaluating job opportunities. By conducting thorough research, networking, asking relevant questions during interviews, and seeking guidance from HR or hiring managers, you can gain a comprehensive understanding of the company's paid leave policy. Remember, paid leave is an essential benefit that contributes to your overall job satisfaction and work-life balance.",

  // blog-3  content here

  "blog-3-summary":
    "In today's dynamic and competitive work environment, standing out and distinguishing yourself is crucial for professional growth and advancement. Embracing an entrepreneurial mindset can be a game-changer, empowering you to bring innovation, creativity, and a proactive approach to your work. In this blog post, we will explore how entrepreneurship can help you stand out at work and propel your career to new heights.Let's dive in! 🚀",
  "blog-3-headingOne": "Embrace a Growth Mindset:",
  "blog-3-contentOne":
    "Entrepreneurship is rooted in a growth mindset, where individuals are constantly seeking new opportunities, learning from failures, and embracing challenges. Adopt this mindset in your professional life by seeking out new projects, taking calculated risks, and continuously expanding your skills and knowledge. By embracing a growth mindset, you position yourself as a proactive and forward-thinking individual within your organization.",
  "blog-3-headingTwo": " Cultivate a Spirit of Innovation:",
  "blog-3-contentTwo":
    "Entrepreneurs are known for their ability to innovate and think outside the box. Apply this entrepreneurial spirit to your work by actively seeking innovative solutions, proposing new ideas, and challenging the status quo. By bringing fresh perspectives and creative solutions to the table, you demonstrate your value and stand out as an asset to your team and organization.",
  "blog-3-headingThree": "Take Ownership of Your Projects:",
  "blog-3-contentThree":
    "Entrepreneurs take full ownership of their ventures, from inception to execution. Apply the same level of ownership to your projects at work. Be proactive, take initiative, and go above and beyond to deliver exceptional results. Demonstrate your commitment, reliability, and ability to drive projects forward, distinguishing yourself as a self-driven and responsible professional.",
  "blog-3-headingFour": "Foster a Collaborative Mindset:",
  "blog-3-contentFour":
    "Successful entrepreneurs understand the power of collaboration and building strong networks. Apply this principle at work by actively seeking opportunities to collaborate with colleagues, cross-functional teams, and external stakeholders. By leveraging the collective expertise and diverse perspectives, you enhance your ability to deliver outstanding outcomes and establish yourself as a team player and influential collaborator.",
  "blog-3-headingFive": "Continuously Learn and Adapt:",
  "blog-3-contentFive":
    "Entrepreneurship is a journey of continuous learning and adaptation. Embrace this mindset by seeking out learning opportunities, attending workshops, conferences, or acquiring new skills. Stay updated with industry trends, technological advancements, and emerging best practices. By positioning yourself as a lifelong learner, you demonstrate your commitment to growth and development, setting yourself apart from your peers.",
  "blog-3-conclusionheading": "Conclusion:",
  "blog-3-concluisonContent":
    "Entrepreneurship is not limited to starting your own business. It is a mindset that can be cultivated and applied within any professional setting. By embracing an entrepreneurial spirit, you can stand out at work, bring fresh perspectives, foster innovation, take ownership of your projects, collaborate effectively, and continuously learn and adapt. Unlock your entrepreneurial potential and watch your career soar to new heights as you make a lasting impact within your organization.",


    // blog-4 content

    "blog-4-summary": "When it comes to landing your dream job, effectively communicating why you're the perfect fit is crucial. In this blog post, we'll explore strategies and tips on how to confidently explain why you're right for the job. From highlighting your skills and experience to showcasing your passion and alignment with the company's values, we'll cover it all. Get ready to leave a lasting impression and stand out from the competition!",
    "blog-4-headingOne": "Know Yourself and Your Strengths:",
    "blog-4-contentOne": "Take time to reflect on your skills, experiences, and achievements that align with the job requirements. Identify your unique strengths and what sets you apart from other candidates. This self-awareness will enable you to confidently articulate why you're the right fit for the job.",
    "blog-4-headingTwo": "Research the Company:",
    "blog-4-contentTwo": "Gain a deep understanding of the company's mission, values, and culture. Tailor your explanation to demonstrate how your skills and values align with theirs. Showcase your knowledge about the company to show your genuine interest and commitment.",
    "blog-4-headingThree": "Customize Your Pitch:",
    "blog-4-contentThree": "Craft a compelling pitch that highlights your relevant skills, experiences, and achievements. Emphasize how these align with the specific needs of the job and the company. Use concrete examples and metrics to quantify your accomplishments and showcase your impact.",
    "blog-4-headingFour": "Showcase Your Passion:",
    "blog-4-contentFour": "Express your genuine enthusiasm and passion for the industry, the company, and the role. Explain how your passion drives your motivation and willingness to go above and beyond. Hiring managers appreciate candidates who demonstrate a genuine interest in the job.",
    "blog-4-headingFive": "Connect the Dots:",
    "blog-4-contentFive": "Bridge the gap between your skills, experiences, and the job requirements. Clearly articulate how your background makes you the ideal candidate to tackle the challenges and contribute to the company's success. Highlight your transferable skills and demonstrate your ability to adapt and learn quickly.",
    "blog-4-headingSix": "Practice, Practice, Practice:",
    "blog-4-contentSix": "Prepare and rehearse your explanation to ensure clarity and confidence. Practice with a friend or mentor who can provide feedback and help you refine your message. The more you practice, the more natural and compelling your explanation will become",
    "blog-4-conclusionheading": "Conclusion:",
    "blog-4-concluisonContent": "Effectively explaining why you're right for the job is a crucial step in the job application process. By knowing yourself, researching the company, customizing your pitch, showcasing your passion, connecting the dots, and practicing your explanation, you can confidently articulate your fit for the job. Stand out from the competition and leave a lasting impression with your compelling and well-prepared explanation.",


    // blog-5 content

    "blog-5-summary": "Job interviews can be nerve-wracking, but with the right strategies, you can overcome those jitters and present yourself with confidence. In this blog post, we'll share valuable tips to help you shake off those interview nerves and showcase your best self. From preparation techniques to confidence-boosting exercises, we've got you covered. Get ready to ace your next job interview!",
    "blog-5-headingOne": "Prepare, Prepare, Prepare:",
    "blog-5-contentOne": "Thoroughly research the company, review the job description, and anticipate common interview questions. Practice your responses and have examples ready to showcase your skills and experiences. The more prepared you are, the more confident you'll feel during the interview.",
    "blog-5-headingTwo": "Practice Mock Interviews:",
    "blog-5-contentTwo": "Simulate real interview scenarios by practicing with a friend, family member, or mentor. Focus on answering questions with clarity, maintaining good eye contact, and projecting confidence. Practice will help you become more comfortable with the interview process.",
    "blog-5-headingThree": "Visualize Success:",
    "blog-5-contentThree": "Take a few moments before the interview to visualize yourself confidently answering questions and making a positive impression. Imagine the interview going smoothly and envision yourself succeeding. Visualizing success can help boost your confidence and reduce anxiety.",
    "blog-5-headingFour": "Deep Breathing and Relaxation Techniques:",
    "blog-5-contentFour": "Practice deep breathing exercises to calm your nerves and relax your body. Before the interview, take a few deep breaths, hold for a few seconds, and exhale slowly. This simple technique can help reduce anxiety and promote a sense of calmness.",
    "blog-5-headingFive": "Focus on Positive Self-Talk:",
    "blog-5-contentFive": "Replace negative thoughts and self-doubt with positive affirmations. Remind yourself of your skills, qualifications, and past accomplishments. Tell yourself that you are prepared and capable. Positive self-talk can help build confidence and shift your mindset to a more positive outlook.",
    "blog-5-headingSix": "Dress Confidently",
    "blog-5-contentSix": "Wear professional attire that makes you feel confident and comfortable. When you feel good about your appearance, it can positively impact your mindset and boost your confidence during the interview. Dressing appropriately shows respect for the opportunity and demonstrates your professionalism.",
    "blog-5-conclusionheading": "Conclusion:",
    "blog-5-concluisonContent": "Job interview jitters are common, but they don't have to hinder your success. By following these tips, you can overcome nervousness, boost your confidence, and present yourself as the best candidate for the job. Remember, preparation, practice, positive self-talk, and relaxation techniques are your allies in conquering job interview jitters. Embrace the opportunity, showcase your skills, and let your confidence shine through!",

    // blog 6 content

    "blog-6-summary": "Your resume is a crucial tool in your job search journey, representing your skills, experiences, and qualifications to potential employers. But how do you know if your resume is truly ready to be submitted? In this blog post, we'll guide you through key indicators that your resume is interview-ready. From formatting and content to relevance and impact, we'll help you ensure your resume stands out from the competition. Let's get started!",
    "blog-6-headingOne": "Clear and Concise Presentation:",
    "blog-6-contentOne": "Check for clear and concise formatting, ensuring your resume is easy to read and visually appealing. Use consistent fonts, bullet points, and proper spacing. A well-organized and visually appealing resume demonstrates professionalism and attention to detail.",
    "blog-6-headingTwo": "Relevant and Tailored Content:",
    "blog-6-contentTwo": "Review your resume to ensure it highlights relevant skills, experiences, and achievements that align with the job you're applying for. Tailor your content to match the specific job requirements and emphasize the most relevant qualifications. Customize your resume for each application to maximize its impact.",
    "blog-6-headingThree": "Quantify Your Achievements:",
    "blog-6-contentThree": "Wherever possible, include quantifiable achievements to showcase your impact. Use numbers, percentages, or metrics to demonstrate the results of your work. Quantifying your achievements adds credibility and helps employers understand the value you can bring to their organization.",
    "blog-6-headingFour": "Error-Free and Proofread:",
    "blog-6-contentFour": "Thoroughly proofread your resume to eliminate any spelling, grammar, or punctuation errors. A single typo can create a negative impression. Take the time to carefully review each section, ensuring accuracy and consistency. Consider asking a trusted friend or mentor to provide a fresh pair of eyes.",
    "blog-6-headingFive": "Strong and Relevant Keywords: ",
    "blog-6-contentFive": "Incorporate industry-specific keywords and phrases relevant to the job you're targeting. Many employers use applicant tracking systems (ATS) to scan resumes for keywords, so optimizing your resume with the right keywords can increase your chances of being noticed.",
    "blog-6-headingSix": "Impactful Summary and Objective: ",
    "blog-6-contentSix": "Craft a compelling summary or objective statement at the beginning of your resume. This section should provide a concise overview of your skills, experiences, and career goals. A well-crafted summary captures the attention of hiring managers and entices them to read further.",
    "blog-6-conclusionheading": "Conclusion:",
    "blog-6-concluisonContent": "Knowing when your resume is interview-ready is essential for a successful job search. By ensuring clear and concise presentation, relevant and tailored content, quantified achievements, error-free proofreading, strong keywords, and an impactful summary or objective, you can confidently submit your resume to potential employers. Take the time to review and refine your resume, making it a powerful tool that showcases your qualifications and sets you apart from other candidates. Your resume is now ready to shine!",

    //   blog-7 content

    "blog-7-summary": "Networking conversations can be enriching and valuable for building professional relationships. However, knowing how to gracefully conclude a networking conversation is just as important as making a memorable first impression. In this blog post, we'll explore effective strategies and tips on how to end a networking conversation tactfully. From expressing gratitude to setting clear next steps, we'll equip you with the tools to leave a positive lasting impression. Let's dive in!",
    "blog-7-headingOne": "Express Gratitude:",
    "blog-7-contentOne": "Thank the person for their time, insights, and valuable conversation. Express genuine appreciation for the opportunity to connect and learn from them. Gratitude leaves a positive impression and shows your respect for their time and expertise.",
    "blog-7-headingTwo": "Summarize Key Points:",
    "blog-7-contentTwo": "Recap the main topics discussed during the conversation. This demonstrates active listening and shows that you were engaged in the discussion. Summarizing key points also helps reinforce the value of the conversation and leaves a lasting impression.",
    "blog-7-headingThree": "Exchange Contact Information:",
    "blog-7-contentThree": "If you haven't already done so, exchange contact information with the person you're networking with. This can include business cards, email addresses, or social media handles. Having their contact information allows you to continue the conversation and follow up in the future.",
    "blog-7-headingFour": "Set Clear Next Steps:",
    "blog-7-contentFour": "Discuss potential next steps or actions you both agreed upon during the conversation. This could include scheduling a follow-up meeting, sharing additional resources, or connecting on a specific topic of interest. Setting clear next steps ensures that the conversation doesn't end abruptly and establishes a foundation for future interactions.",
    "blog-7-headingFive": "End with a Polite Closing:",
    "blog-7-contentFive": "Wrap up the conversation with a polite and professional closing. Use phrases such as \"It was a pleasure connecting with you,\" or \"I look forward to staying in touch.\" A positive closing leaves a final impression of professionalism and leaves the door open for future interactions.",
    "blog-7-conclusionheading": "Conclusion:",
    "blog-7-concluisonContent": "Ending a networking conversation tactfully is just as important as the initial connection. By expressing gratitude, summarizing key points, exchanging contact information, setting clear next steps, expressing interest in further connection, and ending with a polite closing, you can leave a positive lasting impression and build valuable professional relationships. Master the art of graceful farewells, and watch your networking efforts flourish!",
  

  
// blog-8-content
"blog-8-summary": "As a freelancer, following up on your pitches is crucial for securing new opportunities and building client relationships. However, finding the balance between persistence and avoiding being perceived as annoying can be challenging. In this blog post, we'll explore effective strategies and tips on how to follow up on freelance pitches without crossing the line. From timing and personalization to crafting compelling messages, we'll empower you to nurture your freelance pitches with professionalism. Let's dive in!",
"blog-8-headingOne": "Timing is Key:",
"blog-8-contentOne": "Choose an appropriate timeframe for your follow-up. Avoid being too pushy by allowing enough time for the recipient to review your initial pitch. Depending on the nature of the project, follow up after a few days or a week. Finding the right timing shows respect for the recipient's schedule while maintaining your enthusiasm for the opportunity.",
"blog-8-headingTwo": "Personalize Your Message:",
"blog-8-contentTwo": "Tailor your follow-up message to each client or prospect. Reference specific details from your initial pitch or previous conversations to demonstrate your attentiveness and personal interest in their project. Personalization shows that you genuinely care about their needs and are invested in the potential collaboration.",
"blog-8-headingThree": "Keep it Concise and Clear:",
"blog-8-contentThree": "Craft a concise and clear follow-up message that gets straight to the point. State the purpose of your email, remind the recipient of your initial pitch, and express your continued interest in the project. Avoid lengthy emails that may overwhelm or deter the reader. Keep it focused, compelling, and easy to digest.",
"blog-8-headingFour": "Showcase Value and Expertise:",
"blog-8-contentFour": "Highlight your unique value proposition and expertise in your follow-up. Share relevant samples of your work, success stories, or testimonials that showcase your capabilities and demonstrate how you can contribute to their project. Presenting your skills and expertise reinforces your professionalism and helps differentiate you from other freelancers.",
"blog-8-headingFive": "Offer Solutions or Suggestions:",
"blog-8-contentFive": "Go beyond a simple follow-up by offering additional insights, suggestions, or solutions related to their project. Show that you've invested time and thought into their specific needs and are committed to helping them achieve their goals. Providing value and going the extra mile can leave a lasting impression and increase your chances of securing the opportunity.",
"blog-8-headingSix": "Respectfully Seek Feedback:",
"blog-8-contentSix": "Invite feedback from the client or prospect on your initial pitch or proposal. Ask if there's anything you can clarify or provide further information on. Seeking feedback not only demonstrates your openness to improvement but also encourages engagement and dialogue, fostering a collaborative relationship.",
"blog-8-conclusionheading": "Conclusion:",
"blog-8-concluisonContent": "Following up on freelance pitches is a vital part of your success as a freelancer. By mastering the art of professional follow-up, you can nurture your pitches without being perceived as annoying. Remember to find the right timing, personalize your messages, keep them concise and clear, showcase value and expertise, offer solutions or suggestions, and respectfully seek feedback. With these strategies in your toolkit, you'll elevate your follow-up game and increase your chances of converting pitches into rewarding freelance opportunities. Good luck",

// blog-9 content

"blog-9-summary": "The traditional nine-to-five office grind is no longer the only way to work. With the rise of remote work, more people are discovering the numerous benefits it offers, including a significant reduction in stress levels. In this blog post, we'll explore how working from home can be a game-changer for stress reduction and overall well-being. From flexibility and work-life balance to increased autonomy and reduced commuting, we'll delve into the ways remote work can help you achieve a healthier and more harmonious work-life integration. Let's dive in!",
"blog-9-headingOne": "Flexible Schedule for Personal Wellness:",
"blog-9-contentOne": "Working from home allows for greater flexibility in managing your schedule. You have the freedom to set your own work hours, enabling you to prioritize personal wellness activities such as exercise, self-care, and spending quality time with loved ones. This flexibility can alleviate stress by promoting a healthier work-life balance.",
"blog-9-headingTwo": "Elimination of Commuting Stress:",
"blog-9-contentTwo": "One of the major stressors in traditional office settings is the daily commute. Remote work eliminates the need to rush through traffic or cram into crowded public transportation, reducing stress associated with commuting. Instead, you can use that time to relax, engage in a calming activity, or start your workday feeling refreshed.",
"blog-9-headingThree": "Personalized and Comfortable Work Environment:",
"blog-9-contentThree": "Remote work allows you to create a personalized and comfortable work environment tailored to your preferences. You can set up a designated workspace that promotes focus and productivity, eliminating the distractions and stressors commonly found in traditional office settings. Having control over your work environment can contribute to a more relaxed and stress-free experience.",
"blog-9-headingFour": "Increased Autonomy and Reduced Micromanagement:",
"blog-9-contentFour": "Remote work often provides a higher level of autonomy, allowing you to take ownership of your work and manage your tasks independently. This reduced level of micromanagement can alleviate stress caused by constant oversight and promote a greater sense of trust and empowerment in your role.",
"blog-9-headingFive": "Enhanced Work-Life Integration:",
"blog-9-contentFive": "Working from home blurs the boundaries between work and personal life, enabling a more seamless integration of both. This integration allows you to better manage your personal responsibilities, attend to family needs, and engage in activities that bring you joy, all while accomplishing your work tasks. This holistic approach fosters overall well-being and reduces stress associated with compartmentalizing different aspects of life.",
"blog-9-headingSix": "Enhanced Work-Life Integration:",
"blog-9-contentSix":"Remote work minimizes common workplace distractions and interruptions, such as excessive noise, interruptions from colleagues, and impromptu meetings. With fewer distractions, you can maintain better focus, enhance productivity, and experience a sense of calm that contributes to reduced stress levels.",
"blog-9-conclusionheading": "Conclusion:",
"blog-9-concluisonContent":"Working from home has revolutionized the way we work, offering immense potential for stress reduction and improved well-being. By embracing the flexibility, autonomy, and comfort that remote work provides, you can experience a healthier work-life balance, reduced commuting stress, increased control over your work environment, and enhanced integration of personal and professional life. Embrace the transformative power of remote work and unlock a more harmonious and stress-free work experience.",
"Privacy and Policy":"Privacy and Policy",
"privacy-description":"At FullSetters, we prioritize the privacy and security of our users. This Privacy Policy outlines how we collect, use, and protect your personal information when you interact with our website and use our services. By using FullSetters, you consent to the practices described in this policy.",
"Information Collection":"Information Collection",
"info-caclculation":"We may collect personal information such as your name, contact details, resume, and other relevant data when you register an account or use our services. This information is necessary to provide you with a personalized experience and facilitate the application process.",
"Use of Information":"Use of Information",
"use-of-info-description":"We use the collected information to enhance your user experience, improve our services, and personalize your recommendations. We may also use your information to communicate with you, respond to inquiries, and provide customer support.",
"Data Security":"Data Security",
"data-security-description":"We implement industry-standard security measures to protect your personal information from unauthorized access, loss, or misuse. Your data is stored in secure servers and only accessed by authorized personnel with strict confidentiality obligations.",
"Third-Party Integration:":"Third-Party Integration:",
"Third-Party-Integration-description":"FullSetters may integrate with third-party services to enhance your user experience. However, we are not responsible for the privacy practices or content of these external websites or services. We encourage you to review their privacy policies before engaging with them.",
"Cookies":"Cookies",
"cookies-description":"We use cookies and similar tracking technologies to enhance your browsing experience and gather information about how you interact with our website. These technologies help us analyze trends, administer the website, and improve our services"



  
};
