import React, { useState, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation, useTranslation } from "react-i18next";
const API_URL = "https://fullsetters.com/api/rental/";

const Form = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeIndex, setActiveIndex] = useState(null);

  const [data, setData] = useState([]);
  const [userId, setUserId] = useState("");
  const [fields, setFields] = useState({
    streetName: "",
    city: "",
    state: "",
    zipCode: "",
    province: "",
  });

  const getUserId = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    setUserId(user._id);
  };
  useEffect(() => {
    getUserId();
  }, []);

  // Input Field Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if any input field is empty
    const isEmptyField = Object.values(fields).some((value) => value === "");

    if (isEmptyField) {
      toast.error("Please fill in all the fields!");
      return;
    }
    const dataToSubmit = {
      applicationId: id,
      userId: userId,
      selectedRecords: {
        rental: fields?._id,
      },
    };

    try {
      const response = await axios.post(
        "https://fullsetters.com/api/application/apply",
        dataToSubmit
      );
      if (response.status === 201) {
        toast.success("Application submitted successfully!");
        setFields({
          streetName: "",
          city: "",
          zipCode: "",
          province: "",
        });
        setTimeout(() => {
          navigate("/success");
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const editHandler = (index) => {
    setActiveIndex(index);
    setFields(data[index]);
  };

  const getData = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(API_URL + user._id, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });

    const result = response.data;
    setData(result.data);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="">
      <ToastContainer />
      <div className="max-w-[1550px] mx-auto my-10">
        <div className=" bg-white rounded">
          <h2 className="mb-2 px-4 py-4 text-2xl font-bold border-b pb-4 text-primary">
            {t("Rental Apartments")}
          </h2>

          <div className="p-4">
            <h3 className="mb-4 text-2xl font-bold pt-3 text-primary">
              {t("Residential History")}
            </h3>
            <div className="flex flex-wrap gap-1 mb-4">
              {data?.map((item, index) => {
                return (
                  <div key={index}>
                    <button
                      onClick={() => editHandler(index)}
                      className={`${
                        activeIndex !== index
                          ? "border-secondary text-secondary bg-transparent"
                          : "border-[#3867D6] text-white bg-[#3867D6]"
                      } px-6 py-2.5 border rounded-sm `}
                    >
                      {item?.city}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="px-5 py-8 bg-white rounded-b-lg ">
            <form>
              <div className="mb-6">
                <TextField
                  fullWidth
                  label={t("Street name & number")}
                  id="fullWidth"
                  name="streetName"
                  value={fields.streetName || ""}
                />
              </div>
              <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                <div>
                  <TextField
                    fullWidth
                    label={t("State")}
                    id="fullWidth"
                    name="state"
                    value={fields.state || ""}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label={t("City")}
                    id="fullWidth"
                    name="city"
                    value={fields.city || ""}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                <div>
                  <TextField
                    fullWidth
                    label={t("ZIP Code")}
                    id="fullWidth"
                    name="zipCode"
                    value={fields.zipCode || ""}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label={t("Province")}
                    id="fullWidth"
                    name="province"
                    value={fields.province || ""}
                  />
                </div>
                <div className="max-w-[]">
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      backgroundColor: "#3867D6",
                    }}
                    onClick={handleSubmit}
                  >
                    {t("Submit")}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Form);
