import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Layout from "./Layout";

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData("This will be the item name", "$55.00", "01/07/2023"),
  createData("This will be the item name", "$55.00", "01/07/2023"),
  createData("This will be the item name", "$55.00", "01/07/2023"),
];

const Subscriptions = () => {
  return (
    <Layout>
      <h2 className="text-2xl font-bold text-[#142D3C] font-Roboto py-8 px-2">
        Subscriptions
      </h2>

      <div>
        <div className="bg-white rounded-b-lg">
          <TableContainer>
            <Table
              className=""
              sx={{ minWidth: 750 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Purchase Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      marginBottom: "8px",
                    }}
                    className="mb-8"
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.calories}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>{row.fat}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </Layout>
  );
};

export default Subscriptions;
