import React from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineHistory } from "react-icons/ai";
import { MdPayments } from "react-icons/md";
import { RiFileListLine } from "react-icons/ri";


const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="flex flex-col py-10 h-full mr-5">
      {tabsData.map((item, index) => (
        <Link to={item.route} key={index}>
          <span
            className={`text-lg font-normal items-center py-4 px-8 flex gap-4 ${
              currentPath === item.route
                ? "bg-[#EBF0FB] rounded-r-full text-[#3867D6]"
                : ""
            }`}
          >
            {item.icon}
            {item.title}
          </span>
        </Link>
      ))}
    </div>
  );
};

export default Sidebar;

const tabsData = [
  {
    id: 1,
    title: "Subscriptions",
    route: "/billing/subscriptions",
    icon: <AiOutlineHistory />,
  },
  {
    id: 2,
    title: "Payment History",
    route: "/billing/payment-history/paid",
    icon: <RiFileListLine />,
  },
  {
    id: 3,
    title: "Payment Methods",
    route: "/billing/payment-methods",
    icon: <MdPayments />,
  },
];
