import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import UserDetails from "./UserDetails";
import Layout from "./Layout";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { ToastContainer } from "react-toastify";
const API_URL = "https://fullsetters.com/api/admin/";

const API_URL_GET_USERS = "https://fullsetters.com/api/users/getusers/";

const UsersTable = () => {
  const [fields, setFields] = useState({
    userId: "",
    name: "",
    lastName: "",
    email: "",
    status: "",
    userType: "",
    phone: "",
    address: "",
  });
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getData();
    getAllUsers();
  }, []);

  const getData = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("admin"));
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      const result = response.data;
      setData(result.data);
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const getAllUsers = async () => {
    try {
      const response = await axios.get(API_URL_GET_USERS);
      const result = response.data;
      setData(result);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };

  const deleteHandler = async (index, userId) => {
    try {
      let deletedRecord = data.filter((_, i) => i !== index);
      const user = JSON.parse(localStorage.getItem("admin"));
      await axios.delete(API_URL + userId, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      setData(deletedRecord);
    } catch (error) {
      console.error("Error while deleting user:", error);
    }
  };

  const handleEdit = (index) => {
    const dataToUpdate = data[index];
    setOpen(true);
    setFields({
      userId: dataToUpdate._id,
      name: dataToUpdate.name,
      email: dataToUpdate.email,
      userType: dataToUpdate?.role,
      lastName: "",
      status: "",
      phone: "",
      address: "",
    });
  };

  const saveUserDetails = async () => {
    const user = JSON.parse(localStorage.getItem("admin"));
    try {
      const response = await axios.put(API_URL, fields, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      const updatedUser = response.data.result;

      setFields({
        userId: updatedUser._id,
        name: updatedUser.name,
        email: updatedUser.email,
        userType: updatedUser?.role,
        lastName: updatedUser?.lastName,
        status: updatedUser?.status,
        phone: updatedUser?.phone,
        address: updatedUser?.address,
      });
      getData();
      toast.success("User details saved successfully!");
      setOpen(false);
    } catch (error) {
      console.error("Error while saving user details:", error);
      // Display an error message
      toast.error("Error while saving user details. Please try again.");
    }
  };

  return (
    <Layout>
      <ToastContainer position="top-right" />
      <div className="max-w-6xl mx-auto">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Usertype</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="right">Operation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length > 0 &&
                data?.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{row.userType}</TableCell>
                    <TableCell align="center">{row?.status}</TableCell>

                    <TableCell align="right">
                      <div className="flex justify-end gap-x-2">
                        <span>
                          <FiEdit
                            className="text-base cursor-pointer text-primary"
                            onClick={() => handleEdit(index, row._id)}
                          />
                        </span>
                        <span onClick={() => deleteHandler(index, row._id)}>
                          <FiTrash2 className="text-base cursor-pointer text-primary" />
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit User</DialogTitle>
          <DialogContent>
            <UserDetails
              fields={fields}
              setFields={setFields}
              saveUserDetails={saveUserDetails}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              variant="contained"
              size="large"
              sx={{
                backgroundColor: "#3867D6",
                padding: "5px 20px",
              }}
              onClick={saveUserDetails}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Layout>
  );
};

export default UsersTable;
